import { createElement } from '../../../assets/helpers/common';
import { Panels } from '../Panels';

export class Switcher extends Panels {
  constructor (prefix, leftText, rightText, callback) {
    super();
    this.prefix = prefix;
    this.container = createElement('label', `${prefix}SwitcherContainerId`, 'switcherContainer');
    this.container.innerHTML = `<input type="checkbox" id="${prefix}SwitchBtn">`;

    const switchElement = createElement('div', `${prefix}Switch`, 'switch');
    const leftTextElement = createElement('div', `${prefix}LeftText`, 'switchText');
    leftTextElement.classList.add('switchTextLeft');
    leftTextElement.innerHTML = leftText;
    const rightTextElement = createElement('div', `${prefix}RightText`, 'switchText');
    rightTextElement.classList.add('switchTextRight');
    rightTextElement.innerHTML = rightText;

    switchElement.appendChild(leftTextElement);
    switchElement.appendChild(rightTextElement);
    this.container.appendChild(switchElement);
    document.body.appendChild(this.container);
    this.setSwitcherCallback(callback);
  }

  setSwitcherCallback = (callback) => {
    document.getElementById(`${this.prefix}SwitchBtn`).onclick = callback;
  }

  getSwitcherValue = () => {
    return document.getElementById(`${this.prefix}SwitchBtn`).checked;
  }
}
