import "regenerator-runtime/runtime";
import {
  MathUtils,
  Box3,
  Matrix4,
  Object3D,
  WebGL1Renderer,
  PerspectiveCamera,
  Scene,
  PointLight,
  AmbientLight,
  DirectionalLight,
  Raycaster,
  Vector2,
  Vector3,
} from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { Floor } from "./models/floor/Floor";
import { Walls } from "./models/wall/Walls";
import { ModelLoader } from "./models/ModelLoader";
import GLBench from "gl-bench/dist/gl-bench";
import Stats from "three/examples/jsm/libs/stats.module.js";
import { config } from "./config/config";
import { sleep, getNextIndex, getPreviousIndex } from "./assets/helpers/common";
import IsometricControlPanel from "./models/uiControls/panels/IsometricControlPanel";
// import DeviceDetector from './assets/helpers/DeviceDetector';
import NavControlPanel from "./models/uiControls/panels/NavControlPanel";
import TileControlPanel from "./models/uiControls/panels/TileControlPanel";
import SingleTileControlPanel from "./models/uiControls/panels/SingleTileControlPanel";
import ArrowsControls from "./models/uiControls/panels/ArowsControl";
import { ShapeSelect } from "./models/uiControls/panels/ShapeSelect";
import { EdgeStagePanel } from "./models/uiControls/panels/EdgeStagePanel";
import { Switcher } from "./models/uiControls/panels/Switcher";
import { AddObjects } from "./models/uiControls/panels/AddObjects";
import {
  addObjectsModels,
  wallObjectsModels,
} from "./assets/groupExport3DModels/groupExport3DModels";
import TWEEN from "@tweenjs/tween.js";
import { Tile } from "./models/floor/Tile";
import { EdgePiece } from "./models/floor/EdgePiece";
import { Edge } from "./models/floor/Edge";
import { Corner } from "./models/floor/Corner";
import { InnerCorner } from "./models/floor/InnerCorner";
import { CheckoutPanel } from "./models/uiControls/panels/CheckoutPanel";
import { fetchGet, fetchPost } from "./api/api";
import { Loader } from "./models/Loader";
import NoticePopup from "./models/uiPopups/notice";
import Localization from "./assets/localization";
import { getScenes } from "./api/getScenes";

export class World {
  static preloader = new Loader();

  constructor() {
    this.localization = new Localization();
    this.headerHeight = 0;
    this.navBarHeight = 50;
    this.canvasHeight =
      window.innerHeight - this.headerHeight - this.navBarHeight + 1;
    this.aspectRatio = window.innerWidth / this.canvasHeight;
    this.camera = new PerspectiveCamera(50, this.aspectRatio, 0.1, 100);
    this.scene = new Scene();
    this.raycaster = new Raycaster();
    this.addedOtherObjects = new Object3D();
    this.mouse = new Vector2(1, 1);
    this.movingCorner = false;
    this.movingWall = false;
    this.movingObj = false;
    this.movingWallObj = false;
    this.tileColorEnabled = false;
    this.edgeColorEnabled = false;
    this.tileColor = "";
    this.tileTexture = "";
    this.edgeColor = "";
    this.patternAdded = false;
    this.moveWallElements = {};
    this.moveCornerElements = {};
    this.moveObjElement = {};
    this.objectOffset = {};
    this.moveWallObjElement = {};
    this.wallObjectOffset = {};
    this.wallHandlesEnabled = false;
    this.wallCornerHandlesEnabled = false;
    this.moveWallObjectsEnabled = false;
    this.moveFurnitureEnabled = false;
    this.placeEdgesOnWalls = false;
    this.wallObjectSelected = {};
    this.otherObjectSelected = {};
    this.enableDragColor = false;
    this.singleEdgeRemove = false;
    this.dragColorPreviousColors = [];
    this.startDragColorIndex = 0;
    this.endDragColorIndex = 0;

    this.renderer = new WebGL1Renderer({
      antialias: true,
      preserveDrawingBuffer: true,
    });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setClearColor(config.worldColor);
    this.renderer.setSize(window.innerWidth, this.canvasHeight);
    document.body.appendChild(this.renderer.domElement);

    this.orbit = new OrbitControls(this.camera, this.renderer.domElement);
    this.orbit.screenSpacePanning = false;
    this.orbit.minDistance = 1;
    this.orbit.maxDistance = 50;
    this.orbit.enableDamping = true;
    this.orbit.dampingFactor = 0.07;

    let light = new AmbientLight(0xffffff, 1.5);
    this.scene.add(light);

    light = new DirectionalLight(0xffffff, 0.5);
    this.scene.add(light);

    light = new PointLight(0xffffff, 0.05);
    light.position.set(-1.5, 2.5, -1.5);
    this.scene.add(light);

    light = new PointLight(0xffffff, 0.05);
    light.position.set(1.5, 2.5, -1.5);
    this.scene.add(light);

    light = new PointLight(0xffffff, 0.05);
    light.position.set(-1.5, 2.5, 1.5);
    this.scene.add(light);

    light = new PointLight(0xffffff, 0.05);
    light.position.set(1.5, 2.5, 1.5);
    this.scene.add(light);

    // render performance monitor
    if (config.performanceMonitor) {
      this.bench = new GLBench(this.renderer.getContext());
    }
    if (config.performanceMonitorThree) {
      this.stats = new Stats();
      document.body.appendChild(this.stats.dom);
    }

    this.floor = new Floor();
    this.scene.add(this.floor.tiles);

    this.walls = new Walls(
      config.startingShapes[0],
      this.floor,
      this.camera,
      this.renderer,
      this.addedOtherObjects
    );

    this.wallModelLoader = new ModelLoader(
      this.walls.wallObjects,
      this.renderer
    );
    this.otherModelLoader = new ModelLoader(
      this.addedOtherObjects,
      this.renderer
    );

    this.scene.add(this.walls);
    this.scene.add(this.addedOtherObjects);

    // render animation loop
    this.renderAnimationLoop();

    this.addMouseHandler(this.renderer.domElement);
    window.addEventListener("resize", () => {
      this.canvasHeight =
        window.innerHeight - this.headerHeight - this.navBarHeight + 1;
      this.aspectRatio = window.innerWidth / this.canvasHeight;
      this.camera.aspect = this.aspectRatio;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(window.innerWidth, this.canvasHeight);
    });

    // add control panels to the scene
    this.isoPanel = new IsometricControlPanel(this.isometricChanged);
    this.isoPanel.create("isometric");
    this.isoPanel.setValue(true);

    // add nav panel
    this.navPanel = new NavControlPanel(this.onNavChange, "floor");
    this.navPanel.create("nav");

    this.shapeSelect = new ShapeSelect(
      this.navPanel.setValue,
      this.addWalls,
      this.onWallPadsChange
    );
    this.metricSwitcher = new Switcher(
      "metric",
      this.localization.strings.feet,
      this.localization.strings.meter,
      this.walls.setMetricCallBack
    );

    this.addOtherObjects = new AddObjects(
      "other",
      addObjectsModels,
      (
        model,
        objectIndex,
        selectObjectIndex,
        position,
        bottomColision,
        topColision
      ) => {
        this.otherModelLoader.loadObj(
          model,
          objectIndex,
          selectObjectIndex,
          position,
          bottomColision,
          topColision
        );
        this.isoPanel.getValue() && this.isoPanel.setValue(true);
      },
      this.walls.getWallElementPosition,
      true
    );

    this.addWallObjects = new AddObjects(
      "wall",
      wallObjectsModels,
      (
        model,
        objectIndex,
        selectObjectIndex,
        position,
        bottomColision,
        topColision
      ) => {
        this.wallModelLoader.loadObj(
          model,
          objectIndex,
          selectObjectIndex,
          position,
          bottomColision,
          topColision
        );
        this.isoPanel.getValue() && this.isoPanel.setValue(true);
      },
      this.walls.getWallElementPosition,
      false,
      this.walls.setInnerWallColor
    );

    this.edgeRemoveSwitcher = new Switcher(
      "edgeRemoving",
      this.localization.strings.wall,
      this.localization.strings.single,
      (e) => {
        this.singleEdgeRemove = e.target.checked;
      }
    );

    this.EdgeStagePanel = new EdgeStagePanel(
      this.walls.setEdgeHandlesState,
      this.onEdgeColorChange,
      this.onAllEdgeColorChange,
      this.walls.edgesPresent,
      this.edgeRemoveSwitcher
    );

    // add tile control panel
    this.tileControl = new TileControlPanel(
      this.onTileTextureChange,
      this.onTilePatternChange
    );
    this.tileControl.create("tile");

    // add single tile control panel
    this.singleTileControl = new SingleTileControlPanel(
      this.onSingleTileChange
    );
    this.singleTileControl.create("singleTile");
    this.singleTileControl.setColorValue(config.defaultTileChangeColor);

    this.tileColorSwitcher = new Switcher(
      "tileColoring",
      this.localization.strings.move,
      this.localization.strings.color,
      (e) => {
        this.enableDragColor = e.target.checked;
      }
    );

    this.arrowsControls = new ArrowsControls(this.onArrowsControlsChange);
    this.arrowsControls.create("arrows");

    this.checkoutPanel = new CheckoutPanel(
      this.checkoutSave,
      this.checkoutLoad,
      this.checkoutPriceRequest,
      this.checkoutPrint,
      this.getCheckoutUrl
    );

    // set page to 1st, using indexes CALL LAST!
    this.navPanel.setValue(0);

    const urlString = window.location.href;
    const url = new URL(urlString);
    if (urlString.indexOf("scene_id=") > -1) {
      const sceneId = url.searchParams.get("scene_id");
      this.checkoutLoad(`${sceneId}`, "/pdf/scene");
      World.preloader.container.style.backgroundColor = "#ffffff";
    }
  }

  renderAnimationLoop = () => {
    this.renderer.setAnimationLoop((now) => this.draw(now));
  };

  draw = (now) => {
    TWEEN.update(now);
    this.bench && this.bench.begin();
    this.stats && this.stats.begin();

    this.orbit.update();
    this.walls.pulsateEdgeHandles(now);
    this.render();

    this.bench && this.bench.end();
    this.bench && this.bench.nextFrame(now);
    this.stats && this.stats.end();
  };

  render = () => {
    this.renderer.render(this.scene, this.camera);
  };

  addWalls = (points, forcePositions = false) => {
    this.addedOtherObjects.remove(...this.addedOtherObjects.children);
    const edgeHandlesState = this.walls.edgeHandlesState;
    document.getElementById("pattern-id-0").firstChild.click();
    document.getElementById("pattern-pop-up").click();
    this.floor.removeTiles();
    this.scene.remove(this.walls);
    this.walls = new Walls(
      points,
      this.floor,
      this.camera,
      this.renderer,
      this.addedOtherObjects,
      forcePositions
    );
    this.walls.setEdgeHandlesState(edgeHandlesState);
    this.EdgeStagePanel.setEdgeHandlesStateCallback(
      this.walls.setEdgeHandlesState
    );
    this.EdgeStagePanel.setEdgesPresent(this.walls.edgesPresent);
    this.walls.setMetric(this.metricSwitcher.getSwitcherValue());
    this.scene.add(this.walls);
    this.metricSwitcher.setSwitcherCallback(this.walls.setMetricCallBack);
    this.addWallObjects.setPositionOnWallCallback(
      this.walls.getWallElementPosition
    );
    this.wallModelLoader.setAddTo(this.walls.wallObjects);
    this.floor.validateTiles(this.walls.wallGroup, this.walls.edgeHandles);
  };

  isometricChanged = (iso) => {
    if (iso) {
      this.set3d();
    } else {
      this.set2d();
    }
  };

  set3d = () => {
    this.orbit.maxPolarAngle = Math.PI / 2;
    const coords = {
      x: this.camera.position.x,
      y: this.camera.position.y,
      z: this.camera.position.z,
      x1: this.orbit.target.x,
      y1: this.orbit.target.y,
      z1: this.orbit.target.z,
    };
    const [newCameraPosition, newOrbitTarget] = this.centerCamera(true, false);
    new TWEEN.Tween(coords)
      .to(
        {
          x: newCameraPosition.x,
          y: newCameraPosition.y + 0.5,
          z: newCameraPosition.z + 1,
          x1: newOrbitTarget.x,
          y1: 1,
          z1: newOrbitTarget.z,
        },
        500
      )
      .onUpdate(() => {
        this.camera.position.set(coords.x, coords.y, coords.z);
        this.orbit.target.set(coords.x1, coords.y1, coords.z1);
      })
      .start();
  };

  set2d = () => {
    const coords = {
      x: this.camera.position.x,
      y: this.camera.position.y,
      z: this.camera.position.z,
      x1: this.orbit.target.x,
      y1: this.orbit.target.y,
      z1: this.orbit.target.z,
    };
    const [newCameraPosition, newOrbitTarget] = this.centerCamera(false, false);
    new TWEEN.Tween(coords)
      .to(
        {
          x: newCameraPosition.x,
          y: newCameraPosition.y + 0.5,
          z: newCameraPosition.z,
          x1: newOrbitTarget.x,
          y1: newOrbitTarget.y,
          z1: newOrbitTarget.z,
        },
        500
      )
      .onUpdate(() => {
        this.camera.position.set(coords.x, coords.y, coords.z);
        this.orbit.target.set(coords.x1, coords.y1, coords.z1);
      })
      .start()
      .onComplete(() => {
        this.orbit.maxPolarAngle = 0;
      });
  };

  onNavChange = (page) => {
    this.walls.setWallHandleVisible(false);
    this.walls.setWall3DElementsVisible(true);
    this.isoPanel.show();
    this.tileControl.hide();
    this.singleTileControl.hide();
    this.arrowsControls.hide();
    this.walls.setWallElementsVisible(true);
    this.addedOtherObjects.visible = false;
    this.placeEdgesOnWalls = false;
    this.wallHandlesEnabled = false;
    this.wallCornerHandlesEnabled = false;
    this.moveWallObjectsEnabled = false;
    this.tileColorEnabled = false;
    this.edgeColorEnabled = false;
    this.moveFurnitureEnabled = false;
    this.walls.edgeHandles.visible = false;
    this.shapeSelect.hide();
    this.metricSwitcher.hide();
    this.tileColorSwitcher.hide();
    this.edgeRemoveSwitcher.hide();
    this.addOtherObjects.hide();
    this.addWallObjects.hide();
    this.EdgeStagePanel.hide();
    this.addWallObjects.setEditObject(null);
    this.addOtherObjects.setEditObject(null);
    this.addWallObjects.setEditMode(false);
    this.addOtherObjects.setEditMode(false);
    this.walls.wallGroup.visible = !this.walls.pads;
    this.walls.worldOutline.visible = false;
    this.checkoutPanel.hide();
    switch (page) {
      case -1:
      case 0:
        this.isoPanel.setValue(true);
        this.shapeSelect.show();
        this.isoPanel.hide();
        break;
      case 1:
        this.walls.worldOutline.visible = true;
        this.isoPanel.setValue(false);
        this.walls.setWall3DElementsVisible(false);
        this.walls.setWallHandleVisible(true);
        this.wallHandlesEnabled = true;
        !this.walls.pads && (this.wallCornerHandlesEnabled = true);
        this.walls.setWallElementsVisible(false);
        this.metricSwitcher.show();
        this.walls.wallGroup.visible = true;
        this.walls.pads && this.floor.addTiles(this.walls);
        this.walls.pads && this.walls.addEdgesToAllWalls();
        break;
      case 2:
        this.moveWallObjectsEnabled = true;
        this.addWallObjects.show();
        this.addedOtherObjects.visible = true;
        break;
      case 3:
        this.floor.addTiles(this.walls);
        this.walls.pads && this.walls.addEdgesToAllWalls();
        this.floor.validateTiles(this.walls.wallGroup, this.walls.edgeHandles);
        this.tileControl.show();
        this.singleTileControl.show();
        this.patternAdded && this.arrowsControls.show();
        this.tileColorEnabled = true;
        this.tileColorSwitcher.show();
        break;
      case 4:
        this.floor.addTiles(this.walls);
        this.walls.pads && this.walls.addEdgesToAllWalls();
        this.floor.validateTiles(this.walls.wallGroup, this.walls.edgeHandles);
        this.placeEdgesOnWalls = true;
        this.walls.edgeHandles.visible = true;
        this.edgeColorEnabled = true;
        this.EdgeStagePanel.setEdgeRemoverSwitcherVisible();
        this.EdgeStagePanel.show();
        break;
      case 5:
        this.floor.addTiles(this.walls);
        this.walls.pads && this.walls.addEdgesToAllWalls();
        this.floor.validateTiles(this.walls.wallGroup, this.walls.edgeHandles);
        this.addedOtherObjects.visible = true;
        this.moveFurnitureEnabled = true;
        this.addOtherObjects.show();
        break;
      case 6:
      case 100:
        this.isoPanel.setValue(true);
        this.isoPanel.hide();
        this.checkoutPanel.show();
        break;
      default:
        break;
    }
  };

  onTileTextureChange = (id) => {
    const tileType = config.tiles[id].tileType;
    let changeTyleType = false;
    if (this.walls.pads && Floor.isTileTypePro !== (tileType === "pro")) {
      changeTyleType = true;
    }
    Floor.isTileTypePro = tileType === "pro";
    this.walls.wallGroup.children.forEach((wall) => {
      this.walls.removeEdgesfromWall(wall);
    });
    this.floor.removeTiles();
    Tile.changeBaseTexture(id);
    this.floor.addTiles(
      this.walls,
      config.tiles[id].size,
      config.tiles[id].edgeSize
    );
    this.floor.validateTiles(this.walls.wallGroup, this.walls.edgeHandles);
    if (this.walls.pads) {
      this.walls.addEdgesToAllWalls();
    } else {
      Array.from(this.walls.addedEdges).forEach((wall) => {
        this.walls.addEdgesToWall(wall);
      });
    }
    this.singleTileControl.setTileTypeShown(tileType, id);

    Edge.changeBaseTexture(tileType);
    Corner.changeBaseTexture(tileType);
    InnerCorner.changeBaseTexture(tileType);
    this.EdgeStagePanel.setEdgeColorPalette(tileType);
    this.walls.pads || this.EdgeStagePanel.resetRadioBtnValue();

    const configTileObj = config.tiles[id];
    this.changeColorPalette(configTileObj);
    if (changeTyleType) {
      this.navPanel.setValue(1);
      const noticePopup = new NoticePopup(
        this.localization.strings.changeTilyTypeNotice
      ); // eslint-disable-line
      for (let i = 2; i < this.walls.wallMiddlePoints.children.length; i++) {
        const middlePoint = this.walls.wallMiddlePoints.children[i];
        this.movingWall = true;
        this.orbit.enabled = false;
        this.moveWallElements.selectedWallMidle = middlePoint;

        const currentWallIndex = this.moveWallElements.selectedWallMidle.index;
        const nextWallIndex = getNextIndex(
          currentWallIndex,
          this.walls.wallGroup.children.length
        );
        const previousWallIndex = getPreviousIndex(
          currentWallIndex,
          this.walls.wallGroup.children.length
        );

        this.moveWallElements.selectedWall =
          this.walls.wallGroup.children[currentWallIndex];
        this.moveWallElements.prevWall =
          this.walls.wallGroup.children[previousWallIndex];
        this.moveWallElements.nextWall =
          this.walls.wallGroup.children[nextWallIndex];

        this.moveWallElements.selectedWallStartCorner =
          this.walls.wallCornerPoints.children[currentWallIndex];
        this.moveWallElements.selectedWallEndCorner =
          this.walls.wallCornerPoints.children[nextWallIndex];

        let movementX = this.moveWallElements.selectedWall.orientationRow
          ? 0
          : 0.1;
        let movementZ = !this.moveWallElements.selectedWall.orientationRow
          ? 0
          : 0.1;

        if (this.moveWallElements.selectedWall.positiveDirection) {
          movementX *= -1;
          movementZ *= -1;
        }

        if (
          this.moveWallElements.prevWall.orientationRow ===
          this.moveWallElements.nextWall.orientationRow
        ) {
          let edgeWidthForCalculation = 0;

          const prewPrewWall =
            this.walls.wallGroup.children[
              getPreviousIndex(
                this.moveWallElements.prevWall.index,
                this.walls.wallGroup.children.length
              )
            ];
          if (
            this.moveWallElements.selectedWall.positiveDirection !==
            prewPrewWall.positiveDirection
          ) {
            if (
              this.moveWallElements.selectedWall.positiveDirection ===
              this.moveWallElements.prevWall.positiveDirection
            ) {
              if (this.moveWallElements.selectedWall.orientationRow) {
                edgeWidthForCalculation = this.floor.edgeSize * 2;
              } else {
                edgeWidthForCalculation =
                  this.floor.tileSize - this.floor.edgeSize * 2;
              }
            } else {
              if (this.moveWallElements.selectedWall.orientationRow) {
                edgeWidthForCalculation =
                  this.floor.tileSize - this.floor.edgeSize * 2;
              } else {
                edgeWidthForCalculation = this.floor.edgeSize * 2;
              }
            }
          }

          const prevWallStartPoint =
            this.walls.wallCornerPoints.children[
              this.moveWallElements.prevWall.index
            ].position;
          if (this.moveWallElements.selectedWall.orientationRow) {
            let newLengthZ =
              this.moveWallElements.selectedWallStartCorner.position.z +
              movementZ -
              prevWallStartPoint.z;
            newLengthZ -=
              newLengthZ >= 0
                ? edgeWidthForCalculation
                : -edgeWidthForCalculation;
            newLengthZ =
              Math.trunc(newLengthZ / this.floor.tileSize) *
              this.floor.tileSize;
            newLengthZ +=
              newLengthZ >= 0
                ? edgeWidthForCalculation
                : -edgeWidthForCalculation;
            movementZ =
              newLengthZ -
              (this.moveWallElements.selectedWallStartCorner.position.z -
                prevWallStartPoint.z);
          } else {
            let newLengthX =
              this.moveWallElements.selectedWallStartCorner.position.x +
              movementX -
              prevWallStartPoint.x;
            newLengthX -=
              newLengthX >= 0
                ? edgeWidthForCalculation
                : -edgeWidthForCalculation;
            newLengthX =
              Math.trunc(newLengthX / this.floor.tileSize) *
              this.floor.tileSize;
            newLengthX +=
              newLengthX >= 0
                ? edgeWidthForCalculation
                : -edgeWidthForCalculation;
            movementX =
              newLengthX -
              (this.moveWallElements.selectedWallStartCorner.position.x -
                prevWallStartPoint.x);
          }
        }

        const selectedWallStart = {
          position: {
            x:
              this.moveWallElements.selectedWallStartCorner.position.x +
              movementX,
            y: this.moveWallElements.selectedWallStartCorner.position.y,
            z:
              this.moveWallElements.selectedWallStartCorner.position.z +
              movementZ,
          },
        };

        const selectedWallEnd = {
          position: {
            x:
              this.moveWallElements.selectedWallEndCorner.position.x +
              movementX,
            y: this.moveWallElements.selectedWallEndCorner.position.y,
            z:
              this.moveWallElements.selectedWallEndCorner.position.z +
              movementZ,
          },
        };

        selectedWallStart.position.x = parseFloat(
          parseFloat(selectedWallStart.position.x).toFixed(4)
        );
        selectedWallStart.position.z = parseFloat(
          parseFloat(selectedWallStart.position.z).toFixed(4)
        );
        selectedWallEnd.position.x = parseFloat(
          parseFloat(selectedWallEnd.position.x).toFixed(4)
        );
        selectedWallEnd.position.z = parseFloat(
          parseFloat(selectedWallEnd.position.z).toFixed(4)
        );
        if (
          this.checkInsideBounds(selectedWallStart) &&
          this.checkInsideBounds(selectedWallEnd)
        ) {
          this.walls.moveWall(
            this.moveWallElements.selectedWall,
            selectedWallStart,
            selectedWallEnd
          );
        }

        this.releaseWall();
      }
    }
  };

  onTilePatternChange = (tile) => {
    this.floor.makePattern(
      config.patterns[tile.pattern],
      tile.colors.patternColor1,
      tile.colors.patternColor2,
      tile.colors.patternColor3,
      tile.colors.patternColor4,
      tile.isTexture
    );
    this.patternAdded = tile.pattern !== 0;
    this.patternAdded ? this.arrowsControls.show() : this.arrowsControls.hide();
    // this.isoPanel.setValue(true);
  };

  onArrowsControlsChange = (res) => {
    this.floor.movePattern(res);
  };

  onSingleTileChange = (tile) => {
    if (tile.tile !== this.tileTexture) {
      this.tileTexture = tile.tile;
      this.tileColor = tile.color;
      this.changeColorPalette(config.tiles[tile.tile]);
    } else {
      this.tileTexture = tile.tile;
      this.tileColor = tile.color;
    }
  };

  changeColorPalette = (configTileObj) => {
    this.singleTileControl.changeColorPalette(
      configTileObj.colorPalette,
      configTileObj.textureColorPalette,
      configTileObj.mapColorsToPattern
    );
  };

  onEdgeColorChange = (color) => {
    this.edgeColor = color;
  };

  onAllEdgeColorChange = (color) => {
    Edge.changeAllEdgeColors(color);
    Corner.changeAllCornerColors(color);
    InnerCorner.changeAllInnerCornerColors(color);
  };

  onWallPadsChange = (value) => {
    this.navPanel.setNavElVisible(!value);
    this.walls.setPads(value);
    this.EdgeStagePanel.disableEdgeManipulation(value);
  };

  getSceneJson = () => {
    const saveParams = {
      wall: {},
      floor: {},
      tile: {},
      objects: {
        wallObjects: [],
        otherObjects: [],
      },
    };
    const wallPoints = [];
    this.walls.wallCornerPoints.children.forEach((wallPoint) => {
      wallPoints.push([
        wallPoint.position.x,
        wallPoint.position.y,
        wallPoint.position.z,
      ]);
    });

    const worldPos = new Vector3();
    const changedTiles = [];
    Tile.changedMaterials.forEach((tile) => {
      tile.getWorldPosition(worldPos);
      changedTiles.push({
        tileIndex: tile.tileIndex,
        color: tile.newColor,
        textureId: tile.textureId,
      });
    });
    const changedEdges = [];
    Edge.changedEdgeColors.forEach((edge) => {
      edge.getWorldPosition(worldPos);
      changedEdges.push({ tileIndex: edge.tileIndex, color: edge.newColor });
    });
    const changedCorners = [];
    Corner.changedCornerColors.forEach((corner) => {
      corner.getWorldPosition(worldPos);
      changedCorners.push({
        tileIndex: corner.tileIndex,
        color: corner.newColor,
      });
    });
    const changedInnerCorner = [];
    InnerCorner.changedInnerCornerColors.forEach((innerCorner) => {
      innerCorner.getWorldPosition(worldPos);
      changedInnerCorner.push({
        tileIndex: innerCorner.tileIndex,
        color: innerCorner.newColor,
      });
    });
    const wallEdges = [];
    let edge = null;
    this.walls.wallGroup.children.forEach((wall) => {
      const edges = [];
      wall.tileEdgeIndexes.forEach((tileIndex) => {
        edge = this.floor.tiles.children[tileIndex];
        edge.getWorldPosition(worldPos);
        edges.push({
          type: edge.name,
          index: tileIndex,
          worldPos: [worldPos.x, worldPos.y, worldPos.z],
          orientationRow: edge.orientationRow,
          positiveDirection: edge.positiveDirection,
        });
      });
      wallEdges.push({
        id: wall.index,
        hasEdges: wall.hasEdges,
        edges: edges,
        tilesHiddenBecauseOfEdge: wall.tilesHiddenBecauseOfEdge,
      });
    });

    saveParams.wall.wallPoints = wallPoints;
    saveParams.wall.wallColor =
      "#" + Walls.materialWallInner.color.getHexString();
    saveParams.wall.wallEdges = wallEdges;
    saveParams.wall.pads = this.walls.pads;
    saveParams.floor.isTileTypePro = Floor.isTileTypePro;
    saveParams.tile.baseTextureId = Tile.selectedTextureId;
    saveParams.tile.baseVinylTextureId = Tile.selectedVynilTexture;
    saveParams.tile.selectedbaseColor = Tile.selectedbaseColor;
    saveParams.tile.selectedbaseEdgeColor = Edge.selectedbaseColor;
    saveParams.tile.selectedbaseCornerColor = Corner.selectedbaseColor;
    saveParams.tile.selectedbaseInnerCornerColor =
      InnerCorner.selectedbaseColor;
    saveParams.floor.tileSize = this.floor.tileSize;
    saveParams.floor.edgeSize = this.floor.edgeSize;
    saveParams.floor.shiftedX = this.floor.shiftedX;
    saveParams.floor.shiftedZ = this.floor.shiftedZ;
    saveParams.tile.changedTiles = changedTiles;
    saveParams.tile.changedEdges = changedEdges;
    saveParams.tile.changedCorners = changedCorners;
    saveParams.tile.changedInnerCorner = changedInnerCorner;

    let meshColor = "#ffffff";
    this.addedOtherObjects.children.forEach((object) => {
      if (!object.wallElement) return;
      object.traverse((child) => {
        if (child.material && child.material.name === "custom_color") {
          meshColor = `#${child.material.color.getHexString()}`;
        }
      });
      saveParams.objects.otherObjects.push({
        objectIndex: object.objectIndex,
        selectObjectIndex: object.selectObjectIndex,
        positionParam: object.positionParam,
        bottomColision: object.bottomColision,
        topColision: object.topColision,
        meshColor: meshColor,
      });
    });
    this.walls.wallObjects.children.forEach((object) => {
      object.traverse((child) => {
        if (child.material && child.material.name === "custom_color") {
          meshColor = `#${child.material.color.getHexString()}`;
        }
      });
      saveParams.objects.wallObjects.push({
        objectIndex: object.objectIndex,
        selectObjectIndex: object.selectObjectIndex,
        positionParam: object.positionParam,
        bottomColision: object.bottomColision,
        topColision: object.topColision,
        meshColor: meshColor,
      });
    });

    return saveParams;
  };

  checkoutSave = async (name) => {
    World.preloader.show();
    const saveParams = this.getSceneJson();
    try {
      const res = await fetchPost({ name, scene: saveParams }, "/scene");
      if (res.status === "success") {
        await new NoticePopup(this.localization.strings.saveNotice);
        getScenes();
      } else {
        await new NoticePopup(res.error.message);
      }
    } catch (error) {
      await new NoticePopup(this.localization.strings.errorNotice);
    } finally {
      World.preloader.hide();
    }
  };

  checkoutLoad = async (id, route = "/scene") => {
    try {
      World.preloader.show();
      const res = await fetchGet(route, `/${id}`);

      if (res.status === "success") {
        const saveParams = res.data.scene;
        this.movingCorner = false;
        this.movingWall = false;
        this.movingObj = false;
        this.movingWallObj = false;
        this.patternAdded = false;
        this.moveWallElements = {};
        this.moveCornerElements = {};
        this.moveObjElement = {};
        this.objectOffset = {};
        this.moveWallObjElement = {};
        this.wallObjectOffset = {};
        this.wallObjectSelected = {};
        this.otherObjectSelected = {};
        this.dragColorPreviousColors = [];
        this.startDragColorIndex = 0;
        this.endDragColorIndex = 0;
        this.floor.tileSize = saveParams.floor.tileSize;
        this.floor.edgeSize = saveParams.floor.edgeSize;
        Floor.isTileTypePro = saveParams.floor.isTileTypePro;
        this.tileControl.setTileValue(saveParams.tile.baseTextureId);
        this.addWalls(saveParams.wall.wallPoints, true);
        Tile.changeBaseTexture(saveParams.tile.baseTextureId);
        Tile.changeBaseColor(saveParams.tile.selectedbaseColor);
        saveParams.tile.baseVinylTextureId &&
          Tile.changeBaseColor(saveParams.tile.baseVinylTextureId);
        Edge.changeAllEdgeColors(saveParams.tile.selectedbaseEdgeColor);
        Corner.changeAllCornerColors(saveParams.tile.selectedbaseCornerColor);
        InnerCorner.changeAllInnerCornerColors(
          saveParams.tile.selectedbaseInnerCornerColor
        );
        this.walls.setInnerWallColor(saveParams.wall.wallColor);
        this.addWallObjects.wallColorPicker.setValue(saveParams.wall.wallColor);
        this.EdgeStagePanel.allColorPicker.setValue(
          saveParams.tile.selectedbaseEdgeColor
        );
        this.floor.addTiles();
        this.floor.translateFloor(
          saveParams.floor.shiftedZ,
          saveParams.floor.shiftedX
        );
        this.floor.validateTiles(this.walls.wallGroup, this.walls.edgeHandles);

        let newWall, newWallHandles;
        saveParams.wall.wallEdges.forEach((wall) => {
          newWall = this.walls.wallGroup.children[wall.id];
          newWallHandles = this.walls.edgeHandles.children[wall.id];

          newWall.hasEdges = wall.hasEdges;
          newWall.userPlacedEdges = wall.hasEdges;
          newWallHandles.hasEdges = wall.hasEdges;
          newWallHandles.children[0].hasEdges = wall.hasEdges;
          wall.hasEdges &&
            (newWallHandles.children[0].material =
              Walls.materialEdgeHandleRemove);

          wall.edges.forEach((edge) => {
            let newEdgePiece;
            newWall.tileEdgeIndexes.add(edge.index);
            switch (edge.type) {
              case "Edge":
                newEdgePiece = new Edge(
                  edge.index,
                  saveParams.floor.tileSize,
                  saveParams.floor.edgeSize,
                  edge.orientationRow,
                  edge.positiveDirection
                );
                newEdgePiece.position.set(...edge.worldPos);
                this.floor.tiles.children[edge.index] = newEdgePiece;
                break;
              case "Corner":
                newEdgePiece = new Corner(
                  edge.index,
                  saveParams.floor.edgeSize,
                  saveParams.floor.tileSize,
                  edge.orientationRow,
                  edge.positiveDirection
                );
                newEdgePiece.position.set(...edge.worldPos);
                this.floor.tiles.children[edge.index] = newEdgePiece;
                break;
              case "InnerCorner":
                newEdgePiece = new InnerCorner(
                  edge.index,
                  saveParams.floor.tileSize,
                  saveParams.floor.edgeSize,
                  edge.orientationRow,
                  edge.positiveDirection
                );
                newEdgePiece.position.set(...edge.worldPos);
                this.floor.tiles.children[edge.index] = newEdgePiece;
                break;
              default:
                break;
            }
          });
        });

        saveParams.tile.changedTiles.forEach((tileOptions) => {
          this.floor.tiles.children[tileOptions.tileIndex].changeColor(
            tileOptions.color,
            tileOptions.textureId
          );
        });
        saveParams.tile.changedEdges.forEach((tileOptions) => {
          this.floor.tiles.children[tileOptions.tileIndex].changeColor(
            tileOptions.color
          );
        });
        saveParams.tile.changedCorners.forEach((tileOptions) => {
          this.floor.tiles.children[tileOptions.tileIndex].changeColor(
            tileOptions.color
          );
        });
        saveParams.tile.changedInnerCorner.forEach((tileOptions) => {
          this.floor.tiles.children[tileOptions.tileIndex].changeColor(
            tileOptions.color
          );
        });

        saveParams.wall.wallEdges.forEach((wall) => {
          wall.tilesHiddenBecauseOfEdge.forEach((tileIndex) => {
            this.floor.tiles.children[tileIndex].visible = false;
          });
          this.walls.wallGroup.children[wall.id].tilesHiddenBecauseOfEdge =
            wall.tilesHiddenBecauseOfEdge;
        });

        saveParams.objects?.wallObjects?.forEach((wallObject) => {
          this.wallModelLoader.loadObj(
            wallObjectsModels[wallObject.objectIndex].selection[
              wallObject.selectObjectIndex
            ].model,
            wallObject.objectIndex,
            wallObject.selectObjectIndex,
            wallObject.positionParam,
            wallObject.bottomColision,
            wallObject.topColision,
            wallObject.meshColor
          );
        });

        saveParams.objects?.otherObjects?.forEach((wallObject) => {
          this.otherModelLoader.loadObj(
            addObjectsModels[wallObject.objectIndex].selection[
              wallObject.selectObjectIndex
            ].model,
            wallObject.objectIndex,
            wallObject.selectObjectIndex,
            wallObject.positionParam,
            wallObject.bottomColision,
            wallObject.topColision,
            wallObject.meshColor
          );
        });

        this.walls.setEdgeHandlesState(this.walls.edgeHandlesState);

        this.onWallPadsChange(saveParams.wall.pads);
        // document.getElementById('wallPadBtn').checked = saveParams.wall.pads;
        // causes desync with checkbox and shapes
        this.navPanel.setValue(1);
      } else {
        await new NoticePopup(res.error.message);
      }
    } catch (error) {
      console.log("load error", error);
      await new NoticePopup(this.localization.strings.errorNotice);
    } finally {
      World.preloader.hide();
    }
  };

  checkoutPriceRequest = async (user, discount = null) => {
    try {
      World.preloader.show();
      const scene = this.getSceneJson();
      const screenShots = await this.getScreenshots();
      const { topView, frontView, backView } = screenShots;
      const productList = this.floor.validateTiles(
        this.walls.wallGroup,
        this.walls.edgeHandles
      );
      const checkoutUrl = this.getCheckoutUrl(discount);
      const discountValue = this.getDiscountValue(discount);
      const pads = this.walls.pads;
      const res = await fetchPost(
        {
          scene,
          topView,
          frontView,
          backView,
          user,
          productList,
          checkoutUrl,
          discountValue,
          pads,
        },
        "/pdf/priceRequest"
      );

      await new NoticePopup(
        res.status === "success"
          ? this.localization.strings.succesNoticeSendMail
          : res.error.message
      );
    } catch (error) {
      console.log("ERROR checkoutPriceRequest", error);
      await new NoticePopup(this.localization.strings.errorNotice);
    } finally {
      World.preloader.hide();
    }
  };

  checkoutPrintOpenPdf = (url) => {
    window.open(url, "_blank");
  };

  checkoutPrint = async (user, discount = null) => {
    try {
      World.preloader.show();
      const scene = this.getSceneJson();
      const screenShots = await this.getScreenshots();
      const { topView, frontView, backView } = screenShots;
      const productList = this.floor.validateTiles(
        this.walls.wallGroup,
        this.walls.edgeHandles
      );
      const checkoutUrl = this.getCheckoutUrl(discount);
      const discountValue = this.getDiscountValue(discount);
      const pads = this.walls.pads;
      const res = await fetchPost(
        {
          scene,
          topView,
          frontView,
          backView,
          user,
          productList,
          checkoutUrl,
          discountValue,
          pads,
        },
        "/pdf/print"
      );

      if (res.status === "success") {
        const newTab = window.open(res.data.pdfUrl, "_blank");
        newTab?.focus();
        if (!newTab)
          await new NoticePopup(
            this.localization.strings.successNoticePdf,
            this.checkoutPrintOpenPdf,
            null,
            window.document.body,
            "Open PDF",
            res.data.pdfUrl
          );
      } else {
        await new NoticePopup(res.error.message);
      }
    } catch (error) {
      console.log("ERROR checkoutPrint", error);
      await new NoticePopup(this.localization.strings.errorNotice);
    } finally {
      World.preloader.hide();
    }
  };

  getDiscountValue = (discount) => {
    let discountValue;
    switch (
      discount
      // case config.discountCodes[1].id:
      //   discountValue = 0.95;
      //   break;
      // case config.discountCodes[2].id:
      //   discountValue = 0.9;
      //   break;
      // case config.discountCodes[3].id:
      //   discountValue = 0.85;
      //   break;
      // case config.discountCodes[4].id:
      //   discountValue = 0.8;
      //   break;
      // case config.discountCodes[5].id:
      //   discountValue = 0.75;
      //   break;
      // case config.discountCodes[6].id:
      //   discountValue = 0.7;
      //   break;
      // case config.discountCodes[7].id:
      //   discountValue = 0.65;
      //   break;
      // case config.discountCodes[8].id:
      //   discountValue = 0.6;
      //   break;
      // default:
      //   discountValue = 1;
      //   break;
    ) {
    }
    return discountValue;
  };

  getCheckoutUrl = (discount = null) => {
    let checkoutUrl =
      discount === null
        ? config.storeCartUrl
        : `${config.storeUrl}discount/${discount}?redirect=/cart/`;
    const productList = this.floor.validateTiles(
      this.walls.wallGroup,
      this.walls.edgeHandles
    );
    productList.forEach((product) => {
      checkoutUrl += `${product.id}:${product.qty},`;
    });
    return checkoutUrl;
  };

  getScreenshots = async () => {
    try {
      this.walls.setWall3DElementsVisible(false);
      this.walls.setWallElementsVisible(false);
      this.walls.setDimensionComponentVisible(true);
      const topView = await this.moveCameraForScreenshot(false);
      this.walls.setDimensionComponentVisible(false);
      this.walls.setWall3DElementsVisible(true);
      this.walls.setWallElementsVisible(true);
      const frontView = await this.moveCameraForScreenshot(true);
      const backView = await this.moveCameraForScreenshot(true, true);

      return { topView, frontView, backView };
    } catch (error) {
      throw new Error(error);
    }

    // this.checkoutPanel.hide();
  };

  getObjectRoot = (object) => {
    let parentFound = false;
    object.traverseAncestors((parent) => {
      if (
        parent &&
        parent instanceof Object3D &&
        parent.name === "root-object"
      ) {
        if (!parentFound) {
          parentFound = parent;
        }
      }
    });
    return parentFound;
  };

  onClick = (event) => {
    this.raycaster.setFromCamera(this.mouse, this.camera);
    // this.raycaster.precision = 0.001;
    let intersections;

    if (this.placeEdgesOnWalls) {
      if (this.singleEdgeRemove && this.walls.edgeHandlesState === 2) {
        intersections = this.raycaster.intersectObject(this.floor.tiles, true);
        if (intersections.length > 0) {
          let isEdge = false;
          this.walls.wallGroup.children.forEach((wall) => {
            wall.tileEdgeIndexes.has(intersections[0].object.tileIndex) &&
              (isEdge = true);
            wall.tileEdgeIndexes.delete(intersections[0].object.tileIndex);
          });
          if (isEdge) {
            const newColor =
              this.floor.patternIndexAndColor[
                intersections[0].object.tileIndex
              ];
            const replaceWith = new Tile(
              intersections[0].object.tileIndex,
              this.floor.tileSize
            );
            newColor && replaceWith.changeColor(newColor);
            replaceWith.position.setFromMatrixPosition(
              this.floor.tiles.children[intersections[0].object.tileIndex]
                .matrixWorld
            );
            this.floor.tiles.children[intersections[0].object.tileIndex] =
              replaceWith;
          }
        }
      } else {
        intersections = this.raycaster.intersectObject(
          this.walls.edgeHandles,
          true
        );
        if (intersections.length > 0) {
          this.scene.updateMatrixWorld();
          const wallIndex = intersections[0].object.index;
          if (this.walls.edgeHandles.children[wallIndex].visible) {
            if (intersections[0].object.hasEdges) {
              this.walls.removeEdgesfromWall(
                this.walls.wallGroup.children[wallIndex],
                true
              );
              this.walls.wallGroup.children[wallIndex].userPlacedEdges = false;
              this.walls.addedEdges.delete(
                this.walls.wallGroup.children[wallIndex]
              );
            } else {
              this.walls.addEdgesToWall(
                this.walls.wallGroup.children[wallIndex]
              );
              this.walls.wallGroup.children[wallIndex].userPlacedEdges = true;
              this.walls.addedEdges.add(
                this.walls.wallGroup.children[wallIndex]
              );
            }
            this.walls.edgeHandles.children[wallIndex].visible = false;
          }
        }
      }
    }

    if (this.tileColorEnabled) {
      intersections = this.raycaster.intersectObject(this.floor.tiles, true);
      if (intersections.length > 0) {
        if (
          this.tileColor !== "" &&
          this.tileTexture !== "" &&
          intersections[0].object instanceof Tile
        ) {
          intersections[0].object.changeColor(this.tileColor, this.tileTexture);
        }
      }
    }

    if (this.edgeColorEnabled && this.walls.edgeHandlesState === 1) {
      intersections = this.raycaster.intersectObject(this.floor.tiles, true);
      if (intersections.length > 0) {
        if (
          this.edgeColor !== "" &&
          intersections[0].object.parent instanceof EdgePiece
        ) {
          intersections[0].object.parent.changeColor(this.edgeColor);
        }
      }
    }
  };

  onMouseDown = (event) => {
    event.preventDefault();
    this.raycaster.setFromCamera(this.mouse, this.camera);
    let intersections;

    if (this.enableDragColor && this.tileColorEnabled) {
      intersections = this.raycaster.intersectObject(this.floor.tiles, true);
      if (intersections.length > 0) {
        this.orbit.enabled = false;
        this.startDragColorIndex = intersections[0].object.tileIndex;
      }
    }

    if (this.moveWallObjectsEnabled) {
      intersections = this.raycaster.intersectObject(
        this.walls.wallObjects,
        true
      );
      if (intersections.length > 0) {
        const clickedObject =
          this.getFirstVisibleObjectClickedOn(intersections);
        if (clickedObject) {
          this.walls.updateDistanceToWall(clickedObject);
          this.movingWallObj = true;
          this.orbit.enabled = false;
          this.moveWallObjElement = clickedObject;
          this.wallObjectSelected = clickedObject;
          this.addWallObjects.setEditObject(clickedObject);
          this.addWallObjects.setEditMode(true);
          intersections = this.raycaster.intersectObject(
            this.walls.innerWalls,
            true
          );
          this.wallObjectOffset = {
            x: intersections[0].point.x - clickedObject.position.x,
            z: intersections[0].point.z - clickedObject.position.z,
          };
        }
      } else {
        this.wallObjectSelected = {};
        this.addWallObjects.setEditObject(null);
        this.addWallObjects.setEditMode(false);
      }
    }

    if (this.moveFurnitureEnabled) {
      intersections = this.raycaster.intersectObject(
        this.addedOtherObjects,
        true
      );
      if (intersections.length > 0) {
        const clickedObject =
          this.getFirstVisibleObjectClickedOn(intersections);
        if (clickedObject) {
          this.movingObj = true;
          this.orbit.enabled = false;
          this.moveObjElement = clickedObject;
          this.otherObjectSelected = clickedObject;
          this.addOtherObjects.setEditObject(clickedObject);
          this.addOtherObjects.setEditMode(true);
          intersections = this.raycaster.intersectObject(
            clickedObject.wallElement
              ? this.walls.innerWalls
              : this.floor.tiles,
            true
          );
          this.objectOffset = {
            x: intersections[0].point.x - clickedObject.position.x,
            z: intersections[0].point.z - clickedObject.position.z,
          };
        }
      } else {
        this.otherObjectSelected = {};
        this.addOtherObjects.setEditObject(null);
        this.addOtherObjects.setEditMode(false);
      }
    }

    if (this.wallCornerHandlesEnabled) {
      intersections = this.raycaster.intersectObject(
        this.walls.wallCornerPoints,
        true
      );
      if (intersections.length > 0) {
        this.movingCorner = true;
        this.orbit.enabled = false;
        const currentWallIndex = intersections[0].object.index;
        const previousWallIndex = getPreviousIndex(
          currentWallIndex,
          this.walls.wallCornerPoints.children.length
        );

        this.moveCornerElements.wallCornerSelected = intersections[0].object;
        this.moveCornerElements.previousCorner =
          this.walls.wallCornerPoints.children[previousWallIndex];

        this.moveCornerElements.nextWall =
          this.walls.wallGroup.children[
            this.moveCornerElements.wallCornerSelected.index
          ];
        this.moveCornerElements.prevWall =
          this.walls.wallGroup.children[previousWallIndex];
        return;
      }
    }

    if (this.wallHandlesEnabled) {
      intersections = this.raycaster.intersectObject(
        this.walls.wallMiddlePoints,
        true
      );
      if (intersections.length > 0) {
        this.movingWall = true;
        this.orbit.enabled = false;
        this.moveWallElements.selectedWallMidle = intersections[0].object;

        const currentWallIndex = this.moveWallElements.selectedWallMidle.index;
        const nextWallIndex = getNextIndex(
          currentWallIndex,
          this.walls.wallGroup.children.length
        );
        const previousWallIndex = getPreviousIndex(
          currentWallIndex,
          this.walls.wallGroup.children.length
        );

        this.moveWallElements.selectedWall =
          this.walls.wallGroup.children[currentWallIndex];
        this.moveWallElements.prevWall =
          this.walls.wallGroup.children[previousWallIndex];
        this.moveWallElements.nextWall =
          this.walls.wallGroup.children[nextWallIndex];

        this.moveWallElements.selectedWallStartCorner =
          this.walls.wallCornerPoints.children[currentWallIndex];
        this.moveWallElements.selectedWallEndCorner =
          this.walls.wallCornerPoints.children[nextWallIndex];
      }
    }
  };

  getFirstVisibleObjectClickedOn = (intersections) => {
    if (intersections.length > 0) {
      for (let i = 0; i < intersections.length; i++) {
        const parent = this.getObjectRoot(intersections[i].object);
        const ojbPosition = new Vector3();
        parent.getWorldPosition(ojbPosition);
        const clickedOnInvisibleObject =
          parent.wallElement &&
          ojbPosition
            .subVectors(this.camera.position, ojbPosition)
            .dot(parent.normal) > 0;
        if (parent && !clickedOnInvisibleObject) {
          return parent;
        }
      }
    }
    return false;
  };

  onMouseMove = (event) => {
    const objectsToCheck = [];
    this.wallHandlesEnabled && objectsToCheck.push(this.walls.wallMiddlePoints);
    this.wallCornerHandlesEnabled &&
      objectsToCheck.push(this.walls.wallCornerPoints);
    this.moveWallObjectsEnabled && objectsToCheck.push(this.walls.wallObjects);
    this.moveFurnitureEnabled && objectsToCheck.push(this.addedOtherObjects);
    this.raycaster.setFromCamera(this.mouse, this.camera);
    const intersections = this.raycaster.intersectObjects(objectsToCheck, true);
    document.body.style.cursor =
      intersections.length > 0 ? "pointer" : "default";

    if (this.orbit.enabled) return;
    event.preventDefault();

    this.enableDragColor && this.tileColorEnabled && this.moveDragColor();
    this.movingCorner && this.moveWallCorner();
    this.movingWall && this.moveWall();
    this.movingObj && this.moveObj();
    this.movingWallObj && this.moveWallObj();
  };

  onMouseUp = (event) => {
    if (this.orbit.enabled) return;
    event.preventDefault();

    this.enableDragColor && this.tileColorEnabled && this.releaseDragColor();
    this.movingCorner && this.releaseWallCorner();
    this.movingWall && this.releaseWall();
    this.movingObj && this.releaseObj();
    this.movingWallObj && this.releaseWallObj();
  };

  moveDragColor = () => {
    this.removeDragColors();
    this.raycaster.setFromCamera(this.mouse, this.camera);
    const intersections = this.raycaster.intersectObject(
      this.floor.tiles,
      true
    );
    if (intersections.length > 0) {
      if (intersections[0].object instanceof Tile) {
        this.endDragColorIndex = intersections[0].object.tileIndex;
      }

      const width =
        (this.endDragColorIndex % config.numOfTilesPerSide) -
        (this.startDragColorIndex % config.numOfTilesPerSide);
      const height =
        Math.trunc(this.endDragColorIndex / config.numOfTilesPerSide) -
        Math.trunc(this.startDragColorIndex / config.numOfTilesPerSide);
      const widthStep = width / Math.abs(width) || 1;
      const heightStep = height / Math.abs(height) || 1;
      let currentTileIndex;
      let currentTile;
      let color;
      for (let i = 0; i !== height + heightStep; i += heightStep) {
        for (let j = 0; j !== width + widthStep; j += widthStep) {
          currentTileIndex =
            this.startDragColorIndex + j + i * config.numOfTilesPerSide;
          currentTile = this.floor.tiles.children[currentTileIndex];
          if (currentTile instanceof EdgePiece) break;
          color =
            currentTile.material.map.vinylTextureId !== undefined
              ? currentTile.material.map.vinylTextureId
              : `#${currentTile.material.color.getHexString()}`;

          this.dragColorPreviousColors[currentTileIndex] = {
            color: color,
            textureIndex: currentTile.material.map.textureId,
          };
          currentTile.changeColor(this.tileColor, this.tileTexture);
        }
      }
    }
  };

  removeDragColors = () => {
    this.dragColorPreviousColors.forEach((color, index) => {
      this.floor.tiles.children[index].changeColor(
        color.color,
        color.textureIndex
      );
    });
    this.dragColorPreviousColors = [];
  };

  moveWallCorner = () => {
    const position = new Vector3();
    this.raycaster.setFromCamera(this.mouse, this.camera);
    const intersections = this.raycaster.intersectObject(
      this.walls.concrete,
      true
    );

    if (intersections.length > 0) {
      this.moveCornerElements.wallCornerSelected.getWorldPosition(position);
      let movementX = intersections[0].point.x;
      let movementZ = intersections[0].point.z;

      this.walls.wallCornerPoints.children.forEach((wallCornerPoint) => {
        if (
          wallCornerPoint.index !==
          this.moveCornerElements.wallCornerSelected.index
        ) {
          if (
            Math.abs(wallCornerPoint.position.x - movementX) <=
            config.snapPrecision
          ) {
            movementX = wallCornerPoint.position.x;
          }
          if (
            Math.abs(wallCornerPoint.position.z - movementZ) <=
            config.snapPrecision
          ) {
            movementZ = wallCornerPoint.position.z;
          }
        }
      });

      const movedCorner = {
        position: {
          x: movementX,
          y: position.y,
          z: movementZ,
        },
      };

      if (this.checkInsideBounds(movedCorner)) {
        this.walls.moveWall(
          this.moveCornerElements.prevWall,
          this.moveCornerElements.previousCorner,
          movedCorner
        );
      }
    }
  };

  moveWall = () => {
    const position = new Vector3();
    this.raycaster.setFromCamera(this.mouse, this.camera);

    const intersections = this.raycaster.intersectObject(
      this.walls.concrete,
      true
    );
    if (intersections.length > 0) {
      this.moveWallElements.selectedWallMidle.getWorldPosition(position);
      const pointX = intersections[0].point.x;
      const pointZ = intersections[0].point.z;
      let movementX =
        this.moveWallElements.selectedWall.orientationRow && this.walls.pads
          ? 0
          : pointX - position.x;
      let movementZ =
        !this.moveWallElements.selectedWall.orientationRow && this.walls.pads
          ? 0
          : pointZ - position.z;

      if (
        this.walls.pads &&
        this.moveWallElements.prevWall.orientationRow ===
          this.moveWallElements.nextWall.orientationRow
      ) {
        let edgeWidthForCalculation = 0;

        const prewPrewWall =
          this.walls.wallGroup.children[
            getPreviousIndex(
              this.moveWallElements.prevWall.index,
              this.walls.wallGroup.children.length
            )
          ];
        if (
          this.moveWallElements.selectedWall.positiveDirection !==
          prewPrewWall.positiveDirection
        ) {
          if (
            this.moveWallElements.selectedWall.positiveDirection ===
            this.moveWallElements.prevWall.positiveDirection
          ) {
            if (this.moveWallElements.selectedWall.orientationRow) {
              edgeWidthForCalculation = this.floor.edgeSize * 2;
            } else {
              edgeWidthForCalculation =
                this.floor.tileSize - this.floor.edgeSize * 2;
            }
          } else {
            if (this.moveWallElements.selectedWall.orientationRow) {
              edgeWidthForCalculation =
                this.floor.tileSize - this.floor.edgeSize * 2;
            } else {
              edgeWidthForCalculation = this.floor.edgeSize * 2;
            }
          }
        }

        const prevWallStartPoint =
          this.walls.wallCornerPoints.children[
            this.moveWallElements.prevWall.index
          ].position;
        if (this.moveWallElements.selectedWall.orientationRow) {
          let newLengthZ =
            this.moveWallElements.selectedWallStartCorner.position.z +
            movementZ -
            prevWallStartPoint.z;
          newLengthZ -=
            newLengthZ >= 0
              ? edgeWidthForCalculation
              : -edgeWidthForCalculation;
          newLengthZ =
            Math.trunc(newLengthZ / this.floor.tileSize) * this.floor.tileSize;
          newLengthZ +=
            newLengthZ >= 0
              ? edgeWidthForCalculation
              : -edgeWidthForCalculation;
          movementZ =
            newLengthZ -
            (this.moveWallElements.selectedWallStartCorner.position.z -
              prevWallStartPoint.z);
        } else {
          let newLengthX =
            this.moveWallElements.selectedWallStartCorner.position.x +
            movementX -
            prevWallStartPoint.x;
          newLengthX -=
            newLengthX >= 0
              ? edgeWidthForCalculation
              : -edgeWidthForCalculation;
          newLengthX =
            Math.trunc(newLengthX / this.floor.tileSize) * this.floor.tileSize;
          newLengthX +=
            newLengthX >= 0
              ? edgeWidthForCalculation
              : -edgeWidthForCalculation;
          movementX =
            newLengthX -
            (this.moveWallElements.selectedWallStartCorner.position.x -
              prevWallStartPoint.x);
        }
      }

      const selectedWallStart = {
        position: {
          x:
            this.moveWallElements.selectedWallStartCorner.position.x +
            movementX,
          y: this.moveWallElements.selectedWallStartCorner.position.y,
          z:
            this.moveWallElements.selectedWallStartCorner.position.z +
            movementZ,
        },
      };

      const selectedWallEnd = {
        position: {
          x: this.moveWallElements.selectedWallEndCorner.position.x + movementX,
          y: this.moveWallElements.selectedWallEndCorner.position.y,
          z: this.moveWallElements.selectedWallEndCorner.position.z + movementZ,
        },
      };

      let testStartX;
      let testStartZ;
      let testEndX;
      let testEndZ;

      let wallCornerPoint;
      if (!this.walls.pads) {
        for (let i = 0; i < this.walls.wallCornerPoints.children.length; i++) {
          wallCornerPoint = this.walls.wallCornerPoints.children[i];
          if (
            wallCornerPoint === this.moveWallElements.selectedWallStartCorner ||
            wallCornerPoint === this.moveWallElements.selectedWallEndCorner
          )
            continue;

          testStartX =
            wallCornerPoint.position.x - selectedWallStart.position.x;
          testStartZ =
            wallCornerPoint.position.z - selectedWallStart.position.z;
          testEndX = wallCornerPoint.position.x - selectedWallEnd.position.x;
          testEndZ = wallCornerPoint.position.z - selectedWallEnd.position.z;

          if (
            testStartX < config.snapPrecision &&
            testStartX > -config.snapPrecision
          ) {
            selectedWallStart.position.x += testStartX;
            selectedWallEnd.position.x += testStartX;
          } else if (
            testEndX < config.snapPrecision &&
            testEndX > -config.snapPrecision
          ) {
            selectedWallStart.position.x += testEndX;
            selectedWallEnd.position.x += testEndX;
          }

          if (
            testStartZ < config.snapPrecision &&
            testStartZ > -config.snapPrecision
          ) {
            selectedWallStart.position.z += testStartZ;
            selectedWallEnd.position.z += testStartZ;
          } else if (
            testEndZ < config.snapPrecision &&
            testEndZ > -config.snapPrecision
          ) {
            selectedWallStart.position.z += testEndZ;
            selectedWallEnd.position.z += testEndZ;
          }
        }
      }

      selectedWallStart.position.x = parseFloat(
        parseFloat(selectedWallStart.position.x).toFixed(4)
      );
      selectedWallStart.position.z = parseFloat(
        parseFloat(selectedWallStart.position.z).toFixed(4)
      );
      selectedWallEnd.position.x = parseFloat(
        parseFloat(selectedWallEnd.position.x).toFixed(4)
      );
      selectedWallEnd.position.z = parseFloat(
        parseFloat(selectedWallEnd.position.z).toFixed(4)
      );
      if (
        this.checkInsideBounds(selectedWallStart) &&
        this.checkInsideBounds(selectedWallEnd)
      ) {
        this.walls.moveWall(
          this.moveWallElements.selectedWall,
          selectedWallStart,
          selectedWallEnd
        );
      }
    }
  };

  moveObj = () => {
    this.raycaster.setFromCamera(this.mouse, this.camera);
    const placeOn = this.moveObjElement.wallElement
      ? this.walls.innerWalls
      : this.floor.tiles;
    const intersections = this.raycaster.intersectObject(placeOn, true);

    if (intersections.length > 0) {
      const point = intersections[0].point;
      // point.x -= this.objectOffset.x;
      // point.z -= this.objectOffset.z;
      // if (this.checkModelInWalls(point, this.moveObjElement.size)) {
      if (this.moveObjElement.wallElement) {
        this.walls.moveWallObject(
          this.moveObjElement,
          intersections[0].object,
          point,
          this.addedOtherObjects
        );
      } else {
        this.moveObjElement.position.setX(point.x - this.objectOffset.x);
        this.moveObjElement.position.setZ(point.z - this.objectOffset.z);
      }
      // }
    }
  };

  moveWallObj = () => {
    this.raycaster.setFromCamera(this.mouse, this.camera);
    const intersections = this.raycaster.intersectObject(
      this.walls.innerWalls,
      true
    );
    if (intersections.length > 0) {
      // intersections[0].point.x -= this.wallObjectOffset.x;
      // intersections[0].point.z -= this.wallObjectOffset.z;
      this.walls.moveWallObject(
        this.moveWallObjElement,
        intersections[0].object,
        intersections[0].point,
        this.walls.wallObjects
      );
    }
  };

  checkModelInWalls = (middlePoint, size) => {
    const point = new Vector3().copy(middlePoint);
    const relativePoints = [
      { x: -size.x / 2, z: -size.z / 2 },
      { x: size.x, z: 0 },
      { x: 0, z: size.z },
      { x: -size.x, z: 0 },
    ];
    let inWalls = true;
    relativePoints.forEach((relativePoint) => {
      point.x += relativePoint.x;
      point.z += relativePoint.z;
      !this.floor.pointInWalls(point, this.walls.edgeHandles) &&
        (inWalls = false);
    });
    return inWalls;
  };

  checkInsideBounds = (point) => {
    const dimensionLimit =
      ((config.numOfTilesPerSide - 4) * config.footToMeter) / 2;
    let insideBounds = false;
    if (
      point.position.x <= dimensionLimit &&
      point.position.x >= -dimensionLimit &&
      point.position.z <= dimensionLimit &&
      point.position.z >= -dimensionLimit
    ) {
      insideBounds = true;
    }
    return insideBounds;
  };

  releaseDragColor = () => {
    this.orbit.enabled = true;
    this.dragColorPreviousColors = [];
  };

  releaseWallCorner = () => {
    this.moveCornerElements.prevWall.hasEdges &&
      this.walls.removeEdgesfromWall(
        this.moveCornerElements.prevWall,
        false,
        false
      );
    this.scene.updateMatrixWorld();
    this.moveCornerElements.nextWall.hasEdges &&
      this.walls.removeEdgesfromWall(
        this.moveCornerElements.nextWall,
        false,
        false
      );
    this.scene.updateMatrixWorld();

    this.walls.updateWall(this.moveCornerElements.prevWall);
    this.walls.updateWall(this.moveCornerElements.nextWall);

    if (this.floor.tilesAdded && !this.walls.edgesPresent()) {
      this.walls.moveFloorToWall(this.walls.wallGroup.children[0]);
      this.walls.moveFloorToWall(this.walls.wallGroup.children[1]);
      this.floor.checkMaxFloorMovement();
    }
    this.floor.validateTiles(this.walls.wallGroup, this.walls.edgeHandles);

    this.moveCornerElements.prevWall.userPlacedEdges &&
      this.walls.addEdgesToWall(this.moveCornerElements.prevWall);
    this.scene.updateMatrixWorld();
    this.moveCornerElements.nextWall.userPlacedEdges &&
      this.walls.addEdgesToWall(this.moveCornerElements.nextWall);
    this.scene.updateMatrixWorld();

    this.movingCorner = false;
    this.orbit.enabled = true;
    this.moveCornerElements = {};
  };

  releaseWall = () => {
    const edgeHandlesState = this.walls.edgeHandlesState;
    this.moveWallElements.prevWall.hasEdges &&
      this.walls.removeEdgesfromWall(
        this.moveWallElements.prevWall,
        false,
        false
      );
    this.scene.updateMatrixWorld();
    this.moveWallElements.selectedWall.hasEdges &&
      this.walls.removeEdgesfromWall(
        this.moveWallElements.selectedWall,
        false,
        false
      );
    this.scene.updateMatrixWorld();
    this.moveWallElements.nextWall.hasEdges &&
      this.walls.removeEdgesfromWall(
        this.moveWallElements.nextWall,
        false,
        false
      );
    this.scene.updateMatrixWorld();

    this.walls.updateWall(this.moveWallElements.prevWall);
    this.walls.updateWall(this.moveWallElements.selectedWall);
    this.walls.updateWall(this.moveWallElements.nextWall);

    if (this.floor.tilesAdded && !this.walls.edgesPresent()) {
      this.walls.moveFloorToWall(this.walls.wallGroup.children[0]);
      this.walls.moveFloorToWall(this.walls.wallGroup.children[1]);
      this.floor.checkMaxFloorMovement();
    }
    this.floor.validateTiles(this.walls.wallGroup, this.walls.edgeHandles);

    this.moveWallElements.prevWall.userPlacedEdges &&
      this.walls.addEdgesToWall(this.moveWallElements.prevWall);
    this.scene.updateMatrixWorld();
    this.moveWallElements.selectedWall.userPlacedEdges &&
      this.walls.addEdgesToWall(this.moveWallElements.selectedWall);
    this.scene.updateMatrixWorld();
    this.moveWallElements.nextWall.userPlacedEdges &&
      this.walls.addEdgesToWall(this.moveWallElements.nextWall);
    this.scene.updateMatrixWorld();
    this.walls.setEdgeHandlesState(edgeHandlesState);

    this.movingWall = false;
    this.orbit.enabled = true;
    this.moveWallElements = {};
  };

  releaseObj = () => {
    this.movingObj = false;
    this.orbit.enabled = true;
    this.moveObjElement = {};
    this.objectOffset = {};
  };

  releaseWallObj = () => {
    this.movingWallObj = false;
    this.orbit.enabled = true;
    this.moveWallObjElement = {};
    this.wallObjectOffset = {};
  };

  addMouseHandler = (canvas) => {
    canvas.addEventListener("pointerdown", (e) => {
      this.mouse.x = (e.clientX / window.innerWidth) * 2 - 1;
      this.mouse.y =
        -((e.clientY - this.headerHeight) / this.canvasHeight) * 2 + 1;
      this.touchStart = {
        started: true,
        x: e.clientX,
        y: e.clientY,
      };
      this.onMouseDown(e);
    });

    canvas.addEventListener("pointermove", (e) => {
      e.preventDefault();
      this.mouse.x = (e.clientX / window.innerWidth) * 2 - 1;
      this.mouse.y =
        -((e.clientY - this.headerHeight) / this.canvasHeight) * 2 + 1;
      this.onMouseMove(e);
    });

    canvas.addEventListener("pointerup", (e) => {
      this.onMouseUp(e);
      if (this.touchStart.started) {
        this.touchStart.started = false;
        if (
          // simulate click when pointer is not moved far, leave it like this so it is easy to tweak
          Math.abs(this.touchStart.x - e.clientX) < 2 &&
          Math.abs(this.touchStart.y - e.clientY) < 2
        ) {
          this.mouse.x = (e.clientX / window.innerWidth) * 2 - 1;
          this.mouse.y =
            -((e.clientY - this.headerHeight) / this.canvasHeight) * 2 + 1;
          this.onClick(e);
        }
      }
    });
  };

  centerCamera = (thirdPerson, backShot) => {
    const wallHeight = 2.51;
    let maxX = this.walls.wallCornerPoints.children[0].position.x;
    let maxZ = this.walls.wallCornerPoints.children[0].position.z;
    let minX = this.walls.wallCornerPoints.children[0].position.x;
    let minZ = this.walls.wallCornerPoints.children[0].position.z;
    this.walls.wallCornerPoints.children.forEach((wallPoint) => {
      wallPoint.position.x > maxX && (maxX = wallPoint.position.x);
      wallPoint.position.z > maxZ && (maxZ = wallPoint.position.z);
      wallPoint.position.x < minX && (minX = wallPoint.position.x);
      wallPoint.position.z < minZ && (minZ = wallPoint.position.z);
    });
    const tangenseHorizontal = Math.tan(
      MathUtils.degToRad(this.camera.fov) / 2
    );
    const tangenseVertical =
      tangenseHorizontal *
      (this.renderer.domElement.width / this.renderer.domElement.height);
    const height = thirdPerson
      ? wallHeight
      : Math.abs(maxZ - minZ) + 2 * config.wallThickness;
    const width = Math.abs(maxX - minX) + 2 * config.wallThickness;
    let distance = Math.max(
      height / 2 / tangenseHorizontal,
      width / 2 / tangenseVertical
    );
    let sizePixel = this.getSizeInPixel(
      distance,
      { x: width, y: wallHeight, z: height },
      thirdPerson
    );
    if (sizePixel.h > 1080) {
      distance = (sizePixel.h / 1080) * distance;
    }
    sizePixel = this.getSizeInPixel(
      distance,
      { x: width, y: wallHeight, z: height },
      thirdPerson
    );

    if (sizePixel.w > 1920) {
      distance = (sizePixel.w / 1920) * distance;
    }

    const averageX = (maxX + minX) / 2;
    const averageZ = (maxZ + minZ) / 2;

    const newCameraPosition = {
      x: averageX,
      y: thirdPerson ? wallHeight / 2 : wallHeight + distance,
      z: thirdPerson
        ? (backShot ? -1 : 1) *
            (distance + config.wallThickness + Math.abs(maxZ - minZ) / 2) +
          averageZ
        : averageZ,
    };
    const newOrbitTarget = {
      x: averageX,
      y: thirdPerson ? wallHeight / 2 : 0,
      z: averageZ,
    };

    return [newCameraPosition, newOrbitTarget];
  };

  moveCameraForScreenshot = async (sideShot, backShot = false) => {
    try {
      const [newCameraPosition, newOrbitTarget] = this.centerCamera(
        sideShot,
        backShot
      );
      this.camera.position.set(
        newCameraPosition.x,
        newCameraPosition.y,
        newCameraPosition.z
      );
      this.orbit.target.set(
        newOrbitTarget.x,
        newOrbitTarget.y,
        newOrbitTarget.z
      );
      await sleep(300);
      return this.screenshotObject(this.walls.wallGroup, sideShot, backShot);
    } catch (error) {
      throw new Error(error);
    }
  };

  screenshotObject = (obj, sideShot, backShot) => {
    this.camera.updateProjectionMatrix();
    const objSize = new Vector3();
    const box = new Box3().setFromObject(obj);
    box.getSize(objSize);
    objSize.y = 2.51;
    const distance = this.distance(obj, objSize, sideShot, backShot);
    const size = this.getSizeInPixel(distance, objSize, sideShot);
    const pos = this.getPositionInPixel(obj, size, sideShot);
    return this.getImage(size.w, size.h, pos.x, pos.y);
  };

  distance = (obj, objSize, sideShot, backShot) => {
    let maxX = this.walls.wallCornerPoints.children[0].position.x;
    let maxZ = this.walls.wallCornerPoints.children[0].position.z;
    let minX = this.walls.wallCornerPoints.children[0].position.x;
    let minZ = this.walls.wallCornerPoints.children[0].position.z;

    this.walls.wallCornerPoints.children.forEach((wallPoint) => {
      wallPoint.position.x > maxX && (maxX = wallPoint.position.x);
      wallPoint.position.z > maxZ && (maxZ = wallPoint.position.z);
      wallPoint.position.x < minX && (minX = wallPoint.position.x);
      wallPoint.position.z < minZ && (minZ = wallPoint.position.z);
    });

    const z = this.camera.position.z;
    const y = this.camera.position.y - obj.position.y;
    // or use this.camera.position.distanceTo( obj.position );
    const distance = sideShot
      ? z - this.orbit.target.z + (backShot ? 1 : -1) * (objSize.z / 2)
      : y - objSize.y;

    return distance;
  };

  getSizeInPixel = (distance, objSize, sideShot) => {
    const vFOV = MathUtils.degToRad(this.camera.fov);
    let height = 2 * Math.tan(vFOV / 2) * Math.abs(distance); // visible height
    let width =
      height *
      (this.renderer.domElement.width / this.renderer.domElement.height); // visible width
    const ratio = this.renderer.domElement.height / height;

    width = objSize.x * ratio;
    height = (sideShot ? objSize.y : objSize.z) * ratio;
    return { w: width, h: height };
  };

  getPositionInPixel = (obj, size, sideShot) => {
    const viewProjectionMatrix = new Matrix4();
    const viewMatrix = new Matrix4();
    viewMatrix.copy(this.camera.matrixWorldInverse);
    viewProjectionMatrix.multiplyMatrices(
      this.camera.projectionMatrix,
      viewMatrix
    );
    const widthHalf = 0.5 * this.renderer.domElement.width;
    const heightHalf = 0.5 * this.renderer.domElement.height;
    obj.updateMatrixWorld();

    let maxX = -50;
    let maxZ = -50;
    let minX = 50;
    let minZ = 50;
    this.walls.wallCornerPoints.children.forEach((wallPoint) => {
      wallPoint.position.x > maxX && (maxX = wallPoint.position.x);
      wallPoint.position.z > maxZ && (maxZ = wallPoint.position.z);
      wallPoint.position.x < minX && (minX = wallPoint.position.x);
      wallPoint.position.z < minZ && (minZ = wallPoint.position.z);
    });
    const averageX = (maxX + minX) / 2;
    const averageZ = (maxZ + minZ) / 2;
    const vector = new Vector3(averageX, sideShot ? 1.255 : 0, averageZ);
    vector.applyMatrix4(viewProjectionMatrix);

    vector.x = vector.x * widthHalf + widthHalf;
    vector.y = -(vector.y * heightHalf) + heightHalf;

    const x = (vector.x - size.w / 2).toFixed(1);
    const y = (vector.y - size.h / 2).toFixed(1);
    const positionInCanvas = x >= 0 && y >= 0 && (y < 10 || x < 10);
    return { x: x, y: y, positionInCanvas: positionInCanvas };
  };

  getImage = (w, h, x, y) => {
    const imgHeight = 1080;
    const imgWidth = 1920;
    const startX = (imgWidth - w) / 2;
    const startY = (imgHeight - h) / 2;
    const oldCanvas = this.renderer.domElement;
    const newCanvas = document.createElement("canvas");
    newCanvas.width = imgWidth;
    newCanvas.height = imgHeight;
    const newContext = newCanvas.getContext("2d");
    newContext.fillStyle = "#e9e9e9";
    newContext.fillRect(0, 0, imgWidth, imgHeight);
    newContext.drawImage(oldCanvas, x, y, w, h, startX, startY, w, h);

    const strMime = "image/png";
    const strDownloadMime = "image/octet-stream";
    const imgData = newCanvas.toDataURL(strMime);
    const base64str = imgData.replace(strMime, strDownloadMime);

    return base64str;

    // const link = document.createElement('a');
    // if (typeof link.download === 'string') {
    //   document.body.appendChild(link); // Firefox requires the link to be in the body
    //   link.download = 'test.png';
    //   link.href = base64str;
    //   link.click();
    //   document.body.removeChild(link); // remove the link when done
    // } else {
    //   // window.location.replace(uri);
    //   alert('nesto');
    // }
  };
}
