import Localization from "./../assets/localization/";
import sweestype1 from "../assets/images/icons/tiles/ribtraxPro.png";
import sweestype2 from "../assets/images/icons/tiles/ribtraxProSmooth.png";
import sweestype3 from "../assets/images/icons/tiles/vinyltraxPro.png";
import sweestype4 from "../assets/images/icons/tiles/diamondtraxHome.png";
import sweestype5 from "../assets/images/icons/tiles/Truftrax-HighRes.png";
import tileBg1 from "./../assets/images/icons/tiles/Ribtrax-Pro-bg.jpg";
import tileBg2 from "./../assets/images/icons/tiles/Ribtrax-Smooth-Pro-bg.jpg";
import tileBg3 from "./../assets/images/icons/tiles/Vinyltrax-pro-bg.jpg";
import tileBg4 from "./../assets/images/icons/tiles/Diamondtrax-Home-bg.jpg";
import tileBg5 from "./../assets/images/icons/tiles/faux-sol-gazon.png";
import ribtraxTexture from "./../assets/textures/tiles/ribtrax.jpg";
import ribtraxSmoothTexture from "./../assets/textures/tiles/ribtraxSmooth.jpg";
import diamondtraxTexture from "./../assets/textures/tiles/diamondtrax.jpg";
import vinyltraxPolishedConcreteTexture from "./../assets/textures/tiles/PolishedConcrete.jpg";
import vinyltraxAshPineTexture from "./../assets/textures/tiles/vinyltraxAshPine.jpg";
import vinyltraxblackMarbleTexture from "./../assets/textures/tiles/blackMarble.jpg";
import vinyltraxBlackOakTexture from "./../assets/textures/tiles/vinyltraxBlackOak.jpg";
import vinyltraxGreyCarpetTexture from "./../assets/textures/tiles/greyCarpet.jpg";
import TruftraxHighResTexture from "./../assets/textures/tiles/TruftraxHighRes.jpg";
import vinyltraxCarbonFiberTexture from "./../assets/textures/tiles/vinyltraxCarbonFiber.jpg";
import vinyltraxLightMapleTexture from "./../assets/textures/tiles/vinyltraxLightMaple.jpg";
import vinyltraxReclaimedPineTexture from "./../assets/textures/tiles/vinyltraxReclaimedPine.jpg";
import { tilePatterns } from "./../assets/svgPatternFiles/tilePatterns";

const storeUrl = "https://www.flexico.com.au/";
const apiEndpointUrl =
  process.env.ENV === "dev"
    ? "http://localhost:3000"
    : "https://api.flexicofloordesigner.com.au";
const localization = new Localization();
const numOfTilesPerSide = 54;
const footToMeter = 0.3048;
const relativeRowIndex2 = numOfTilesPerSide;
const relativeRowIndex3 = numOfTilesPerSide * 2;
const relativeRowIndex4 = numOfTilesPerSide * 3;
const relativeRowIndex5 = numOfTilesPerSide * 4;
const relativeRowIndex6 = numOfTilesPerSide * 5;
const relativeRowIndex7 = numOfTilesPerSide * 6;
const relativeRowIndex8 = numOfTilesPerSide * 7;
const relativeRowIndex9 = numOfTilesPerSide * 8;
const relativeRowIndex10 = numOfTilesPerSide * 9;
const relativeRowIndex11 = numOfTilesPerSide * 10;
const relativeRowIndex12 = numOfTilesPerSide * 11;
const relativeRowIndex13 = numOfTilesPerSide * 12;
const relativeRowIndex14 = numOfTilesPerSide * 13;
const relativeRowIndex15 = numOfTilesPerSide * 14;

export const config = {
  storeUrl: storeUrl,
  storeCartUrl: `${storeUrl}cart/`,
  apiEndpointUrl: apiEndpointUrl,
  performanceMonitor: false,
  performanceMonitorThree: false,
  placeEdgesOnWalls: true,
  pointInWallsTolerance: 0,
  snapPrecision: 0.25,
  numOfTilesPerSide: numOfTilesPerSide,
  footToMeter: footToMeter,
  wallThickness: footToMeter * Math.sqrt(2) * 0.25,
  concreteColor: "#e9e9e9",
  worldColor: "#e9e9e9",
  defaultTileSize: 0.4,
  defaultEdgeSize: 0.07,
  defaultTileColor: "#9f9f9f",
  defaultTileChangeColor: "#cf102d",
  defaultEdgeColor: "#cf102d",
  defaultEdgeChangeColor: "#cf102d",
  distanceLineColor: "#e5171a",
  uiControls: {
    enabled: true,
  },
  specColorsPrice:
    'Specialty Colors:&nbsp<div style="display: flex"><div style="color: #ff8a40 ">$6.75</div><div style="font-size: 15px; font-weight: 300; margin-top: 5px;">/sqft</div></div>',
  standardColorsPrice: "Standard Colors",
  discountCodes: [
    { name: "0%", id: null },
    // { name: "5%", id: "DIRECT-235-325" },
    // { name: "10%", id: "DIRECT-210-425" },
    // { name: "15%", id: "DIRECT-410-515" },
    // { name: "20%", id: "DIRECT-320-425" },
    // { name: "25%", id: "DIRECT-520-425" },
    // { name: "30%", id: "DIRECT-265-305" },
    // { name: "35%", id: "DIRECT-635-359" },
    // { name: "40%", id: "DIRECT-402-729" },
  ],
  allColorPalettePro: [
    { color: "#ffffff", name: "ARCTIC WHITE" },
    { color: "#b0b9bd", name: "PEARL SILVER" },
    { color: "#9f9f9f", name: "PEARL GREY" },
    { color: "#7c7c7c", name: "SLATE GREY" },
    { color: "#212121", name: "JET BLACK" },
    // { color: "#65402f", name: "CHOCOLATE BROWN" },
    // { color: "#aa5935", name: "TERRA COTTA" },
    { color: "#ead7b0", name: "IVORY" },
    { color: "#fcd900", name: "CITRUS YELLOW" },
    { color: "#72a031", name: "TURF GREEN" },
    { color: "#008175", name: "TEAL" },
    { color: "#225e8f", name: "ISLAND BLUE" },
    { color: "#002e6b", name: "ROYAL BLUE" },
    { color: "#6f2e89", name: "COSMIC PURPLE" },
    { color: "#e84b93", name: "CARNIVAL PINK" },
    { color: "#cf102d", name: "RACING RED" },
    { color: "#dc4405", name: "TROPICAL ORANGE" },
    { color: "#96d700", name: "TECHNO GREEN" },

    // viniltrax pattern simulated colors for svg
    { color: "#515151", name: "BLACK OAK" },
    { color: "#cfc4ad", name: "LIGHT MAPLE" },
    { color: "#757367", name: "RECLAIMED PINE" },
    { color: "#414141", name: "CARBON FIBER" },
    { color: "#a39787", name: "ASH PINE" },
    { color: "#b6b3b2", name: "POLISHED CONCRETE" },
    { color: "#e0c595", name: "PARQUET" },
    { color: "#393939", name: "BLACK MARBLE" },
    { color: "#bdbdc2", name: "GREY CARPET" },

    // some colors
    { color: "#f5f7f2", name: "Chantilly Lace" },
    { color: "#efede2", name: "Swiss Coffe" },
    { color: "#e4e1d8", name: "Classic Gray" },
    { color: "#e7e5e0", name: "Calm" },
    { color: "#dad4c5", name: "Edgecomb Gray" },
    { color: "#b9bbb7", name: "Coventry Gray" },
    { color: "#dcd3bd", name: "Manchester Tan" },
    { color: "#c2d2ca", name: "Palladian Blue" },
    { color: "#f6e2a5", name: "Hawthorne Yellow" },
    { color: "#b2b8a3", name: "Saybrook Sage" },
    { color: "#d2c3a8", name: "Shaker Beige" },
    { color: "#e9dad5", name: "Proposal" },
    { color: "#deb774", name: "Dorset Gold" },
    { color: "#434b56", name: "Hale Navy" },
    { color: "#a4937d", name: "Alexandria Beige" },
    { color: "#686763", name: "Kendall Charcoal" },
    { color: "#8b2829", name: "Caliente" },
    { color: "#524b59", name: "Shadow" },
    { color: "#4a4b4c", name: "Wrought Iron" },
    { color: "#000000", name: "Black" },
  ],
  edgePiecesColorPalette: {
    pro: [
      "#cf102d",
      "#b0b9bd",
      "#002e6b",
      "#6f2e89",
      "#e84b93",
      "#9f9f9f",
      "#dc4405",
      "#515151",
      "#7c7c7c",
      "#225e8f",
      "#008175",
      "#96d700",
      "#72a031",
      "#ffffff",
      "#fcd900",
    ],
    home: [
      "#cf102d",
      "#b0b9bd",
      "#002e6b",
      "#6f2e89",
      "#e84b93",
      "#9f9f9f",
      "#dc4405",
      "#515151",
      "#7c7c7c",
      "#225e8f",
      "#008175",
      "#96d700",
      "#72a031",
      "#ffffff",
      "#fcd900",
    ],
  },

  startingShapes: [
    [
      [0, 0, 0],
      [4.54, 0, 0],
      [4.54, 0, 5.34],
      [0, 0, 5.34],
    ],
    [
      [0, 0, 0],
      [4.94, 0, 0],
      [4.94, 0, 2.54],
      [2.54, 0, 2.54],
      [2.54, 0, 5.34],
      [0, 0, 5.34],
    ],
    [
      [0, 0, 0],
      [5.34, 0, 0],
      [5.34, 0, 2.14],
      [4.14, 0, 2.14],
      [4.14, 0, 5.34],
      [1.2, 0, 5.34],
      [1.2, 0, 2.14],
      [0, 0, 2.14],
    ],
    [
      [0, 0, 0],
      [4.54, 0, 0],
      [4.54, 0, 5.34],
      [3.2, 0, 5.34],
      [3.2, 0, 2.94],
      [1.34, 0, 2.94],
      [1.34, 0, 5.34],
      [0, 0, 5.34],
    ],
    [
      [1, 0, 0],
      [3.54, 0, 0],
      [3.54, 0, 0.8],
      [4.34, 0, 0.8],
      [4.34, 0, 3.74],
      [3.54, 0, 3.74],
      [3.54, 0, 4.54],
      [1, 0, 4.54],
      [1, 0, 3.74],
      [0.2, 0, 3.74],
      [0.2, 0, 0.8],
      [1, 0, 0.8],
    ],
    [
      [2, 0, 0],
      [3.74, 0, 0],
      [3.74, 0, 0.8],
      [4.54, 0, 0.8],
      [4.54, 0, 1.6],
      [5.34, 0, 1.6],
      [5.34, 0, 3.34],
      [4.54, 0, 3.34],
      [4.54, 0, 4.14],
      [3.74, 0, 4.14],
      [3.74, 0, 4.94],
      [2, 0, 4.94],
      [2, 0, 4.14],
      [1.2, 0, 4.14],
      [1.2, 0, 3.34],
      [0.4, 0, 3.34],
      [0.4, 0, 1.6],
      [1.2, 0, 1.6],
      [1.2, 0, 0.8],
      [2, 0, 0.8],
    ],
  ],
  tiles: [
    {
      id: 0,
      price: "$5.87",
      priceUnit: "/sqft",
      title: localization.strings.tile_1_title,
      desc: localization.strings.tile_1_desc,
      img_src: sweestype1,
      bg_img: tileBg1,
      dimensions: (units) =>
        units === "metric"
          ? localization.strings.tile_dimensions_m
          : localization.strings.tile_dimensions_ft,
      tileType: "pro", // large
      texture: ribtraxTexture,
      size: 0.4,
      edgeSize: 0.07,
      textureColorPalette: 0,
      defaultPatternColors: ["#9f9f9f", "#fcd900", "#002e6b", "#cf102d"],
      colorPalette: [
        "#ffffff", //ARCTIC WHITE
        "#b0b9bd", //PEARL SILVER
        "#9f9f9f", //PEARL GREY
        "#7c7c7c", //SLATE GREY
        "#212121", //JET BLACK
        "#ead7b0", //MOCHA JAVA----------------
        "#fcd900", //CITRUS YELLOW
        "#72a031", //TURF GREEN
        "#008175", //TEAL
        "#225e8f", //ISLAND BLUE
        "#002e6b", //ROYAL BLUE
        "#6f2e89", //COSMIC PURPLE
        "#e84b93", //CARNIVAL PINK
        "#cf102d", //RACING RED
        "#dc4405", //TROPICAL ORANGE
        "#96d700", //TECHNO GREEN
      ],

      //Swisstrax Ribtrax Ivory Garage Floor Tile 6080623083678

      variantIds: {
        /* eslint-disable */
        "#ffffff": 37604358881438,
        "#b0b9bd": 37581589610654,
        "#9f9f9f": 37581590102174,
        "#7c7c7c": 37581589217438,
        "#212121": 37581590724766,
        "#ead7b0": 37604447715486, //MOCHA JAVA
        "#fcd900": 37581590823070,
        "#72a031": 37604486938782, //TURF GREEN
        "#008175": 37604473340062,
        "#225e8f": 37604438966430,
        "#002e6b": 37581589282974,
        "#6f2e89": 37581590790302,
        "#e84b93": 37671472758942,
        "#cf102d": 37581589348510,
        "#dc4405": 37581588824222,
        "#96d700": 37581589184670,
        /* eslint-enable */
      },
    },
    {
      id: 1,
      price: "5.87",
      priceUnit: "/sqft",
      title: localization.strings.tile_2_title,
      desc: localization.strings.tile_2_desc,
      img_src: sweestype2,
      bg_img: tileBg2,
      dimensions: (units) =>
        units === "metric"
          ? localization.strings.tile_dimensions_m
          : localization.strings.tile_dimensions_ft,
      tileType: "pro",
      texture: ribtraxSmoothTexture,
      size: 0.4,
      edgeSize: 0.07,
      textureColorPalette: 0,
      defaultPatternColors: ["#b0b9bd", "#212121", "#002e6b", "#cf102d"],
      colorPalette: ["#7c7c7c", "#002e6b", "#cf102d", "#b0b9bd", "#212121"],
      variantIds: {
        /* eslint-disable */
        "#7c7c7c": 29508441473047,
        "#002e6b": 39424665518238,
        "#cf102d": 39424559317150,
        "#b0b9bd": 37582656471198,
        "#212121": 37582648672414,
        /* eslint-enable */
      },
    },
    {
      id: 2,
      price: "9.12",
      priceUnit: "/sqft",
      title: localization.strings.tile_3_title,
      desc: localization.strings.tile_3_desc,
      img_src: sweestype3,
      bg_img: tileBg3,
      dimensions: (units) =>
        units === "metric"
          ? localization.strings.tile_dimensions_m
          : localization.strings.tile_dimensions_ft,
      tileType: "pro",
      texture: vinyltraxBlackOakTexture,
      size: 0.4,
      edgeSize: 0.07,
      textureColorPalette: 7,
      defaultPatternColors: ["#515151", "#cfc4ad", "#757367", "#414141"],
      colorPalette: [
        vinyltraxBlackOakTexture,
        vinyltraxLightMapleTexture,
        vinyltraxReclaimedPineTexture,
        vinyltraxCarbonFiberTexture,
        vinyltraxAshPineTexture,
        vinyltraxPolishedConcreteTexture,
        vinyltraxblackMarbleTexture,
        vinyltraxGreyCarpetTexture,
      ],
      variantIds: {
        0: 37589321547934,
        1: 37589182316702,
        2: 37589183987870,
        3: 37589318369438,
        4: 37589332230302,
        5: 42314658971806,
        6: 37589332885662,
        7: 37589327216798,
      },
      mapColorsToPattern: [
        "#515151",
        "#cfc4ad",
        "#757367",
        "#414141",
        "#a39787",
        "#b6b3b2",
        "#393939",
        "#bdbdc2",
      ], // , '#b6b3b2'
    },

    {
      id: 3,
      price: "5.28",
      priceUnit: "/tile",
      title: localization.strings.tile_4_title,
      desc: localization.strings.tile_4_desc,
      img_src: sweestype4,
      bg_img: tileBg4,
      dimensions: (units) =>
        units === "metric"
          ? localization.strings.tile_dimensions_small_m
          : localization.strings.tile_dimensions_small_ft,
      tileType: "home", // small
      texture: diamondtraxTexture,
      size: 0.305,
      edgeSize: 0.07,
      textureColorPalette: 0,
      defaultPatternColors: ["#b0b9bd", "#7c7c7c", "#002e6b", "#cf102d"],
      colorPalette: ["#7c7c7c", "#002e6b", "#cf102d", "#b0b9bd", "#212121"],
      variantIds: {
        /* eslint-disable */
        "#7c7c7c": 37604562862238,
        "#002e6b": 37604563746974,
        "#cf102d": 37604563845278,
        "#b0b9bd": 37604564435102,
        "#212121": 37604564500638,
        /* eslint-enable */
      },
    },
    {
      id: 4,
      price: "9.12",
      priceUnit: "/sqft",
      title: localization.strings.tile_8_title,
      desc: localization.strings.tile_8_desc,
      img_src: sweestype5,
      bg_img: tileBg5,
      dimensions: (units) =>
        units === "metric"
          ? localization.strings.tile_dimensions_m
          : localization.strings.tile_dimensions_ft,
      tileType: "pro",
      texture: TruftraxHighResTexture,
      size: 0.4,
      edgeSize: 0.07,
      textureColorPalette: 5,
      defaultPatternColors: ["#515151"],
      colorPalette: [TruftraxHighResTexture],
      variantIds: {
        0: 38473408658,
      },
      mapColorsToPattern: ["#515151"], // , '#b6b3b2'
    },
  ],
  edgeVariantIds: {
    /* eslint-disable */

    pro: {
      corners: {
        "#515151": 40295401357470, //JET BLACK --+
        "#7c7c7c": 40295401390238, //SLATE GREY
        "#9f9f9f": 40295401423006, //PEARL GREY
        "#b0b9bd": 40295401455774, //PEARL SILVER
        "#cf102d": 40295401488542, //RACING RED
        "#ffffff": 40295401521310, //ARTIC WHITE
        "#e84b93": 40295401554078, //CARNIVAL PINK
        "#002e6b": 40295401586846, //ROYAL BLUE
        "#225e8f": 40295401619614, //ISLAND BLUE
        "#008175": 40295401652382, //TEAL
        "#72a031": 40295401685150, //TURF GREEN
        "#fcd900": 40295401717918, //CITRUS YELLOW
        "#6f2e89": 40295401750686, //COSMIC PURPLE
        "#dc4405": 40295401783454, //TROPICAL ORANGE
        "#96d700": 40295401816222, //TECHNO GREEN
        // "#96d700": 40295401848990, //IVORY
      },
      edgesLooped: {
        "#515151": 39341454557342, //BLACK OAK
        "#9f9f9f": 39346546966686, //PEARL GREY
        "#cf102d": 39341345013918, //RACING RED
        "#b0b9bd": 39346544509086, //PEARL SILVER
        "#002e6b": 39346586845342, //ROYAL BLUE
        "#e84b93": 39346462294174, //CARNIVAL PINK
        "#6f2e89": 39345965334686, //COSMIC PURPLE
        "#7c7c7c": 39346559418526, //SLATE GREY
        "#225e8f": 39346519605406, //ISLAND BLUE
        "#008175": 39352621007006, //TEAL LOOPED
        "#96d700": 39346467799198, //TECHNO GREEN
        "#72a031": 39346476974238, //TURF GREEN
        "#ffffff": 39346532483230, //WHITE LOOPED
        "#fcd900": 39346452758686, //YELLOW LOOPED
        "#dc4405": 39346494210206, ///TROPICAL ORANGE
      },
      edgesPegged: {
        "#515151": 40256052887710, //BLACK OAK
        "#9f9f9f": 39346548310174, //PEARL GREY
        "#cf102d": 39341311918238, //RACING RED
        "#b0b9bd": 39346541887646, //PEARL SILVER
        "#002e6b": 39346589728926, //ROYAL BLUE
        "#e84b93": 39346465210526, //CARNIVAL PINK
        "#6f2e89": 39346443813022, //COSMIC PURPLE
        "#7c7c7c": 39346563186846, //SLATE GREY
        "#225e8f": 39346511511710, //ISLAND BLUE
        "#dc4405": 39346500403358, //TROPICAL ORANGE
        "#008175": 39352637292702, //TEAL PEGGED
        "#96d700": 39346468454558, //TECHNO GREEN
        "#ffffff": 39346531762334, //WHITE PEGGED
        "#fcd900": 39346454888606, //YELLOW PEGGED
        "#72a031": 39346479202462, //TURF GREEN
      },
    },

    /* eslint-enable */
  },
  tilePatterns: [
    { id: 0, svg: tilePatterns[0], colors: 1 },
    { id: 1, svg: tilePatterns[1], colors: 2 },
    { id: 2, svg: tilePatterns[2], colors: 3 },
    { id: 3, svg: tilePatterns[3], colors: 2 },
    { id: 4, svg: tilePatterns[4], colors: 2 },
    { id: 5, svg: tilePatterns[5], colors: 3 },
    { id: 6, svg: tilePatterns[6], colors: 3 },
    { id: 7, svg: tilePatterns[7], colors: 2 },
    { id: 8, svg: tilePatterns[8], colors: 4 },
  ],
  patterns: [
    {
      repeat: false,
      width: 0,
      height: 0,
      primaryColorRelativeIndexes: [],
      secondaryColorRelativeIndexes: [],
      tertiaryColorRelativeIndexes: [],
      quaternaryColorRelativeIndexes: [],
    },
    {
      repeat: true,
      width: 2,
      height: 2,
      primaryColorRelativeIndexes: [[0], [relativeRowIndex2 + 1]],
      secondaryColorRelativeIndexes: [[1], [relativeRowIndex2]],
      tertiaryColorRelativeIndexes: [],
      quaternaryColorRelativeIndexes: [],
    },
    {
      repeat: true,
      width: 3,
      height: 3,
      primaryColorRelativeIndexes: [],
      secondaryColorRelativeIndexes: [
        [0, 2],
        [],
        [relativeRowIndex3, relativeRowIndex3 + 2],
      ],
      tertiaryColorRelativeIndexes: [[], [relativeRowIndex2 + 1]],
      quaternaryColorRelativeIndexes: [],
    },
    {
      repeat: false,
      width: 11,
      height: 11,
      primaryColorRelativeIndexes: [],
      secondaryColorRelativeIndexes: [
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        [
          relativeRowIndex2,
          relativeRowIndex2 + 1,
          relativeRowIndex2 + 2,
          relativeRowIndex2 + 3,
          relativeRowIndex2 + 4,
          relativeRowIndex2 + 5,
          relativeRowIndex2 + 6,
          relativeRowIndex2 + 7,
          relativeRowIndex2 + 8,
          relativeRowIndex2 + 9,
          relativeRowIndex2 + 10,
        ],
        [
          relativeRowIndex3,
          relativeRowIndex3 + 1,
          relativeRowIndex3 + 2,
          relativeRowIndex3 + 3,
          relativeRowIndex3 + 4,
          relativeRowIndex3 + 5,
          relativeRowIndex3 + 6,
          relativeRowIndex3 + 7,
          relativeRowIndex3 + 8,
          relativeRowIndex3 + 9,
          relativeRowIndex3 + 10,
        ],
        [
          relativeRowIndex4,
          relativeRowIndex4 + 1,
          relativeRowIndex4 + 2,
          relativeRowIndex4 + 3,
          relativeRowIndex4 + 4,
          relativeRowIndex4 + 5,
          relativeRowIndex4 + 6,
          relativeRowIndex4 + 7,
          relativeRowIndex4 + 8,
          relativeRowIndex4 + 9,
          relativeRowIndex4 + 10,
        ],
        [
          relativeRowIndex5,
          relativeRowIndex5 + 1,
          relativeRowIndex5 + 2,
          relativeRowIndex5 + 3,
          relativeRowIndex5 + 4,
          relativeRowIndex5 + 5,
          relativeRowIndex5 + 6,
          relativeRowIndex5 + 7,
          relativeRowIndex5 + 8,
          relativeRowIndex5 + 9,
          relativeRowIndex5 + 10,
        ],
        [
          relativeRowIndex6,
          relativeRowIndex6 + 1,
          relativeRowIndex6 + 2,
          relativeRowIndex6 + 3,
          relativeRowIndex6 + 4,
          relativeRowIndex6 + 5,
          relativeRowIndex6 + 6,
          relativeRowIndex6 + 7,
          relativeRowIndex6 + 8,
          relativeRowIndex6 + 9,
          relativeRowIndex6 + 10,
        ],
        [
          relativeRowIndex7,
          relativeRowIndex7 + 1,
          relativeRowIndex7 + 2,
          relativeRowIndex7 + 3,
          relativeRowIndex7 + 4,
          relativeRowIndex7 + 5,
          relativeRowIndex7 + 6,
          relativeRowIndex7 + 7,
          relativeRowIndex7 + 8,
          relativeRowIndex7 + 9,
          relativeRowIndex7 + 10,
        ],
        [
          relativeRowIndex8,
          relativeRowIndex8 + 1,
          relativeRowIndex8 + 2,
          relativeRowIndex8 + 3,
          relativeRowIndex8 + 4,
          relativeRowIndex8 + 5,
          relativeRowIndex8 + 6,
          relativeRowIndex8 + 7,
          relativeRowIndex8 + 8,
          relativeRowIndex8 + 9,
          relativeRowIndex8 + 10,
        ],
        [
          relativeRowIndex9,
          relativeRowIndex9 + 1,
          relativeRowIndex9 + 2,
          relativeRowIndex9 + 3,
          relativeRowIndex9 + 4,
          relativeRowIndex9 + 5,
          relativeRowIndex9 + 6,
          relativeRowIndex9 + 7,
          relativeRowIndex9 + 8,
          relativeRowIndex9 + 9,
          relativeRowIndex9 + 10,
        ],
        [
          relativeRowIndex10,
          relativeRowIndex10 + 1,
          relativeRowIndex10 + 2,
          relativeRowIndex10 + 3,
          relativeRowIndex10 + 4,
          relativeRowIndex10 + 5,
          relativeRowIndex10 + 6,
          relativeRowIndex10 + 7,
          relativeRowIndex10 + 8,
          relativeRowIndex10 + 9,
          relativeRowIndex10 + 10,
        ],
        [
          relativeRowIndex11,
          relativeRowIndex11 + 1,
          relativeRowIndex11 + 2,
          relativeRowIndex11 + 3,
          relativeRowIndex11 + 4,
          relativeRowIndex11 + 5,
          relativeRowIndex11 + 6,
          relativeRowIndex11 + 7,
          relativeRowIndex11 + 8,
          relativeRowIndex11 + 9,
          relativeRowIndex11 + 10,
        ],
      ],
      tertiaryColorRelativeIndexes: [],
      quaternaryColorRelativeIndexes: [],
    },
    {
      repeat: false,
      width: 11,
      height: 11,
      primaryColorRelativeIndexes: [],
      secondaryColorRelativeIndexes: [
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        [relativeRowIndex2, relativeRowIndex2 + 10],
        [relativeRowIndex3, relativeRowIndex3 + 10],
        [relativeRowIndex4, relativeRowIndex4 + 10],
        [relativeRowIndex5, relativeRowIndex5 + 10],
        [relativeRowIndex6, relativeRowIndex6 + 10],
        [relativeRowIndex7, relativeRowIndex7 + 10],
        [relativeRowIndex8, relativeRowIndex8 + 10],
        [relativeRowIndex9, relativeRowIndex9 + 10],
        [relativeRowIndex10, relativeRowIndex10 + 10],
        [
          relativeRowIndex11,
          relativeRowIndex11 + 1,
          relativeRowIndex11 + 2,
          relativeRowIndex11 + 3,
          relativeRowIndex11 + 4,
          relativeRowIndex11 + 5,
          relativeRowIndex11 + 6,
          relativeRowIndex11 + 7,
          relativeRowIndex11 + 8,
          relativeRowIndex11 + 9,
          relativeRowIndex11 + 10,
        ],
      ],
      tertiaryColorRelativeIndexes: [],
      quaternaryColorRelativeIndexes: [],
    },
    {
      repeat: false,
      width: 11,
      height: 11,
      primaryColorRelativeIndexes: [],
      secondaryColorRelativeIndexes: [
        [1, 2, 3, 4, 5, 6, 7, 8, 9],
        [relativeRowIndex2, relativeRowIndex2 + 10],
        [relativeRowIndex3, relativeRowIndex3 + 10],
        [relativeRowIndex4, relativeRowIndex4 + 10],
        [relativeRowIndex5, relativeRowIndex5 + 10],
        [relativeRowIndex6, relativeRowIndex6 + 10],
        [relativeRowIndex7, relativeRowIndex7 + 10],
        [relativeRowIndex8, relativeRowIndex8 + 10],
        [relativeRowIndex9, relativeRowIndex9 + 10],
        [relativeRowIndex10, relativeRowIndex10 + 10],
        [
          relativeRowIndex11 + 1,
          relativeRowIndex11 + 2,
          relativeRowIndex11 + 3,
          relativeRowIndex11 + 4,
          relativeRowIndex11 + 5,
          relativeRowIndex11 + 6,
          relativeRowIndex11 + 7,
          relativeRowIndex11 + 8,
          relativeRowIndex11 + 9,
        ],
      ],
      tertiaryColorRelativeIndexes: [
        [],
        [
          relativeRowIndex2 + 1,
          relativeRowIndex2 + 2,
          relativeRowIndex2 + 3,
          relativeRowIndex2 + 4,
          relativeRowIndex2 + 5,
          relativeRowIndex2 + 6,
          relativeRowIndex2 + 7,
          relativeRowIndex2 + 8,
          relativeRowIndex2 + 9,
        ],
        [
          relativeRowIndex3 + 1,
          relativeRowIndex3 + 2,
          relativeRowIndex3 + 3,
          relativeRowIndex3 + 4,
          relativeRowIndex3 + 5,
          relativeRowIndex3 + 6,
          relativeRowIndex3 + 7,
          relativeRowIndex3 + 8,
          relativeRowIndex3 + 9,
        ],
        [
          relativeRowIndex4 + 1,
          relativeRowIndex4 + 2,
          relativeRowIndex4 + 3,
          relativeRowIndex4 + 4,
          relativeRowIndex4 + 5,
          relativeRowIndex4 + 6,
          relativeRowIndex4 + 7,
          relativeRowIndex4 + 8,
          relativeRowIndex4 + 9,
        ],
        [
          relativeRowIndex5 + 1,
          relativeRowIndex5 + 2,
          relativeRowIndex5 + 3,
          relativeRowIndex5 + 4,
          relativeRowIndex5 + 5,
          relativeRowIndex5 + 6,
          relativeRowIndex5 + 7,
          relativeRowIndex5 + 8,
          relativeRowIndex5 + 9,
        ],
        [
          relativeRowIndex6 + 1,
          relativeRowIndex6 + 2,
          relativeRowIndex6 + 3,
          relativeRowIndex6 + 4,
          relativeRowIndex6 + 5,
          relativeRowIndex6 + 6,
          relativeRowIndex6 + 7,
          relativeRowIndex6 + 8,
          relativeRowIndex6 + 9,
        ],
        [
          relativeRowIndex7 + 1,
          relativeRowIndex7 + 2,
          relativeRowIndex7 + 3,
          relativeRowIndex7 + 4,
          relativeRowIndex7 + 5,
          relativeRowIndex7 + 6,
          relativeRowIndex7 + 7,
          relativeRowIndex7 + 8,
          relativeRowIndex7 + 9,
        ],
        [
          relativeRowIndex8 + 1,
          relativeRowIndex8 + 2,
          relativeRowIndex8 + 3,
          relativeRowIndex8 + 4,
          relativeRowIndex8 + 5,
          relativeRowIndex8 + 6,
          relativeRowIndex8 + 7,
          relativeRowIndex8 + 8,
          relativeRowIndex8 + 9,
        ],
        [
          relativeRowIndex9 + 1,
          relativeRowIndex9 + 2,
          relativeRowIndex9 + 3,
          relativeRowIndex9 + 4,
          relativeRowIndex9 + 5,
          relativeRowIndex9 + 6,
          relativeRowIndex9 + 7,
          relativeRowIndex9 + 8,
          relativeRowIndex9 + 9,
        ],
        [
          relativeRowIndex10 + 1,
          relativeRowIndex10 + 2,
          relativeRowIndex10 + 3,
          relativeRowIndex10 + 4,
          relativeRowIndex10 + 5,
          relativeRowIndex10 + 6,
          relativeRowIndex10 + 7,
          relativeRowIndex10 + 8,
          relativeRowIndex10 + 9,
        ],
      ],
      quaternaryColorRelativeIndexes: [
        [0, 10],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [relativeRowIndex11, relativeRowIndex11 + 10],
      ],
    },
    {
      repeat: false,
      width: 11,
      height: 11,
      primaryColorRelativeIndexes: [],
      secondaryColorRelativeIndexes: [
        [1, 2, 3, 7, 8, 9],
        [
          relativeRowIndex2,
          relativeRowIndex2 + 4,
          relativeRowIndex2 + 6,
          relativeRowIndex2 + 10,
        ],
        [
          relativeRowIndex3,
          relativeRowIndex3 + 4,
          relativeRowIndex3 + 6,
          relativeRowIndex3 + 10,
        ],
        [
          relativeRowIndex4,
          relativeRowIndex4 + 4,
          relativeRowIndex4 + 6,
          relativeRowIndex4 + 10,
        ],
        [
          relativeRowIndex5,
          relativeRowIndex5 + 4,
          relativeRowIndex5 + 6,
          relativeRowIndex5 + 10,
        ],
        [
          relativeRowIndex6,
          relativeRowIndex6 + 4,
          relativeRowIndex6 + 6,
          relativeRowIndex6 + 10,
        ],
        [
          relativeRowIndex7,
          relativeRowIndex7 + 4,
          relativeRowIndex7 + 6,
          relativeRowIndex7 + 10,
        ],
        [
          relativeRowIndex8,
          relativeRowIndex8 + 4,
          relativeRowIndex8 + 6,
          relativeRowIndex8 + 10,
        ],
        [
          relativeRowIndex9,
          relativeRowIndex9 + 4,
          relativeRowIndex9 + 6,
          relativeRowIndex9 + 10,
        ],
        [
          relativeRowIndex10,
          relativeRowIndex10 + 4,
          relativeRowIndex10 + 6,
          relativeRowIndex10 + 10,
        ],
        [
          relativeRowIndex11 + 1,
          relativeRowIndex11 + 2,
          relativeRowIndex11 + 3,
          relativeRowIndex11 + 7,
          relativeRowIndex11 + 8,
          relativeRowIndex11 + 9,
        ],
      ],
      tertiaryColorRelativeIndexes: [
        [],
        [
          relativeRowIndex2 + 1,
          relativeRowIndex2 + 2,
          relativeRowIndex2 + 3,
          relativeRowIndex2 + 7,
          relativeRowIndex2 + 8,
          relativeRowIndex2 + 9,
        ],
        [
          relativeRowIndex3 + 1,
          relativeRowIndex3 + 2,
          relativeRowIndex3 + 3,
          relativeRowIndex3 + 7,
          relativeRowIndex3 + 8,
          relativeRowIndex3 + 9,
        ],
        [
          relativeRowIndex4 + 1,
          relativeRowIndex4 + 2,
          relativeRowIndex4 + 3,
          relativeRowIndex4 + 7,
          relativeRowIndex4 + 8,
          relativeRowIndex4 + 9,
        ],
        [
          relativeRowIndex5 + 1,
          relativeRowIndex5 + 2,
          relativeRowIndex5 + 3,
          relativeRowIndex5 + 7,
          relativeRowIndex5 + 8,
          relativeRowIndex5 + 9,
        ],
        [
          relativeRowIndex6 + 1,
          relativeRowIndex6 + 2,
          relativeRowIndex6 + 3,
          relativeRowIndex6 + 7,
          relativeRowIndex6 + 8,
          relativeRowIndex6 + 9,
        ],
        [
          relativeRowIndex7 + 1,
          relativeRowIndex7 + 2,
          relativeRowIndex7 + 3,
          relativeRowIndex7 + 7,
          relativeRowIndex7 + 8,
          relativeRowIndex7 + 9,
        ],
        [
          relativeRowIndex8 + 1,
          relativeRowIndex8 + 2,
          relativeRowIndex8 + 3,
          relativeRowIndex8 + 7,
          relativeRowIndex8 + 8,
          relativeRowIndex8 + 9,
        ],
        [
          relativeRowIndex9 + 1,
          relativeRowIndex9 + 2,
          relativeRowIndex9 + 3,
          relativeRowIndex9 + 7,
          relativeRowIndex9 + 8,
          relativeRowIndex9 + 9,
        ],
        [
          relativeRowIndex10 + 1,
          relativeRowIndex10 + 2,
          relativeRowIndex10 + 3,
          relativeRowIndex10 + 7,
          relativeRowIndex10 + 8,
          relativeRowIndex10 + 9,
        ],
      ],
      quaternaryColorRelativeIndexes: [
        [0, 4, 6, 10],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [
          relativeRowIndex11,
          relativeRowIndex11 + 4,
          relativeRowIndex11 + 6,
          relativeRowIndex11 + 10,
        ],
      ],
    },
    {
      repeat: false,
      width: 11,
      height: 11,
      primaryColorRelativeIndexes: [],
      secondaryColorRelativeIndexes: [
        [0, 1, 2, 3, 4, 6, 7, 8, 9, 10],
        [
          relativeRowIndex2,
          relativeRowIndex2 + 1,
          relativeRowIndex2 + 2,
          relativeRowIndex2 + 3,
          relativeRowIndex2 + 4,
          relativeRowIndex2 + 6,
          relativeRowIndex2 + 7,
          relativeRowIndex2 + 8,
          relativeRowIndex2 + 9,
          relativeRowIndex2 + 10,
        ],
        [
          relativeRowIndex3,
          relativeRowIndex3 + 1,
          relativeRowIndex3 + 2,
          relativeRowIndex3 + 3,
          relativeRowIndex3 + 4,
          relativeRowIndex3 + 6,
          relativeRowIndex3 + 7,
          relativeRowIndex3 + 8,
          relativeRowIndex3 + 9,
          relativeRowIndex3 + 10,
        ],
        [
          relativeRowIndex4,
          relativeRowIndex4 + 1,
          relativeRowIndex4 + 2,
          relativeRowIndex4 + 3,
          relativeRowIndex4 + 4,
          relativeRowIndex4 + 6,
          relativeRowIndex4 + 7,
          relativeRowIndex4 + 8,
          relativeRowIndex4 + 9,
          relativeRowIndex4 + 10,
        ],
        [
          relativeRowIndex5,
          relativeRowIndex5 + 1,
          relativeRowIndex5 + 2,
          relativeRowIndex5 + 3,
          relativeRowIndex5 + 4,
          relativeRowIndex5 + 6,
          relativeRowIndex5 + 7,
          relativeRowIndex5 + 8,
          relativeRowIndex5 + 9,
          relativeRowIndex5 + 10,
        ],
        [
          relativeRowIndex6,
          relativeRowIndex6 + 1,
          relativeRowIndex6 + 2,
          relativeRowIndex6 + 3,
          relativeRowIndex6 + 4,
          relativeRowIndex6 + 6,
          relativeRowIndex6 + 7,
          relativeRowIndex6 + 8,
          relativeRowIndex6 + 9,
          relativeRowIndex6 + 10,
        ],
        [
          relativeRowIndex7,
          relativeRowIndex7 + 1,
          relativeRowIndex7 + 2,
          relativeRowIndex7 + 3,
          relativeRowIndex7 + 4,
          relativeRowIndex7 + 6,
          relativeRowIndex7 + 7,
          relativeRowIndex7 + 8,
          relativeRowIndex7 + 9,
          relativeRowIndex7 + 10,
        ],
        [
          relativeRowIndex8,
          relativeRowIndex8 + 1,
          relativeRowIndex8 + 2,
          relativeRowIndex8 + 3,
          relativeRowIndex8 + 4,
          relativeRowIndex8 + 6,
          relativeRowIndex8 + 7,
          relativeRowIndex8 + 8,
          relativeRowIndex8 + 9,
          relativeRowIndex8 + 10,
        ],
        [
          relativeRowIndex9,
          relativeRowIndex9 + 1,
          relativeRowIndex9 + 2,
          relativeRowIndex9 + 3,
          relativeRowIndex9 + 4,
          relativeRowIndex9 + 6,
          relativeRowIndex9 + 7,
          relativeRowIndex9 + 8,
          relativeRowIndex9 + 9,
          relativeRowIndex9 + 10,
        ],
        [
          relativeRowIndex10,
          relativeRowIndex10 + 1,
          relativeRowIndex10 + 2,
          relativeRowIndex10 + 3,
          relativeRowIndex10 + 4,
          relativeRowIndex10 + 6,
          relativeRowIndex10 + 7,
          relativeRowIndex10 + 8,
          relativeRowIndex10 + 9,
          relativeRowIndex10 + 10,
        ],
        [
          relativeRowIndex11,
          relativeRowIndex11 + 1,
          relativeRowIndex11 + 2,
          relativeRowIndex11 + 3,
          relativeRowIndex11 + 4,
          relativeRowIndex11 + 6,
          relativeRowIndex11 + 7,
          relativeRowIndex11 + 8,
          relativeRowIndex11 + 9,
          relativeRowIndex11 + 10,
        ],
      ],
      tertiaryColorRelativeIndexes: [],
      quaternaryColorRelativeIndexes: [],
    },
    {
      repeat: true,
      width: 15,
      height: 15,
      primaryColorRelativeIndexes: [
        [],
        [],
        [
          relativeRowIndex3 + 1,
          relativeRowIndex3 + 2,
          relativeRowIndex3 + 5,
          relativeRowIndex3 + 6,
          relativeRowIndex3 + 8,
          relativeRowIndex3 + 10,
          relativeRowIndex3 + 11,
          relativeRowIndex3 + 12,
          relativeRowIndex3 + 13,
        ],
        [
          relativeRowIndex4 + 1,
          relativeRowIndex4 + 2,
          relativeRowIndex4 + 5,
          relativeRowIndex4 + 6,
          relativeRowIndex4 + 8,
          relativeRowIndex4 + 10,
          relativeRowIndex4 + 11,
          relativeRowIndex4 + 12,
          relativeRowIndex4 + 13,
        ],
        [],
        [],
        [
          relativeRowIndex7 + 1,
          relativeRowIndex7 + 2,
          relativeRowIndex7 + 5,
          relativeRowIndex7 + 6,
          relativeRowIndex7 + 8,
          relativeRowIndex7 + 10,
          relativeRowIndex7 + 11,
          relativeRowIndex7 + 12,
          relativeRowIndex7 + 13,
        ],
        [
          relativeRowIndex8 + 1,
          relativeRowIndex8 + 2,
          relativeRowIndex8 + 5,
          relativeRowIndex8 + 6,
          relativeRowIndex8 + 8,
          relativeRowIndex8 + 10,
          relativeRowIndex8 + 11,
          relativeRowIndex8 + 12,
          relativeRowIndex8 + 13,
        ],
        [],
        [
          relativeRowIndex10 + 1,
          relativeRowIndex10 + 2,
          relativeRowIndex10 + 5,
          relativeRowIndex10 + 6,
          relativeRowIndex10 + 8,
          relativeRowIndex10 + 10,
          relativeRowIndex10 + 11,
          relativeRowIndex10 + 12,
          relativeRowIndex10 + 13,
        ],
        [
          relativeRowIndex11 + 1,
          relativeRowIndex11 + 2,
          relativeRowIndex11 + 5,
          relativeRowIndex11 + 6,
          relativeRowIndex11 + 8,
          relativeRowIndex11 + 10,
          relativeRowIndex11 + 11,
          relativeRowIndex11 + 12,
          relativeRowIndex11 + 13,
        ],
        [],
        [],
        [
          relativeRowIndex14 + 1,
          relativeRowIndex14 + 2,
          relativeRowIndex14 + 5,
          relativeRowIndex14 + 6,
          relativeRowIndex14 + 8,
          relativeRowIndex14 + 10,
          relativeRowIndex14 + 11,
          relativeRowIndex14 + 12,
          relativeRowIndex14 + 13,
        ],
        [
          relativeRowIndex15 + 1,
          relativeRowIndex15 + 2,
          relativeRowIndex15 + 5,
          relativeRowIndex15 + 6,
          relativeRowIndex15 + 8,
          relativeRowIndex15 + 10,
          relativeRowIndex15 + 11,
          relativeRowIndex15 + 12,
          relativeRowIndex15 + 13,
        ],
      ],
      secondaryColorRelativeIndexes: [
        [],
        [
          relativeRowIndex2 + 1,
          relativeRowIndex2 + 2,
          relativeRowIndex2 + 5,
          relativeRowIndex2 + 6,
          relativeRowIndex2 + 8,
          relativeRowIndex2 + 10,
          relativeRowIndex2 + 11,
          relativeRowIndex2 + 12,
          relativeRowIndex2 + 13,
        ],
        [
          relativeRowIndex3 + 3,
          relativeRowIndex3 + 4,
          relativeRowIndex3 + 7,
          relativeRowIndex3 + 9,
          relativeRowIndex3 + 14,
        ],
        [
          relativeRowIndex4 + 3,
          relativeRowIndex4 + 4,
          relativeRowIndex4 + 7,
          relativeRowIndex4 + 9,
          relativeRowIndex4 + 14,
        ],
        [
          relativeRowIndex5 + 1,
          relativeRowIndex5 + 2,
          relativeRowIndex5 + 5,
          relativeRowIndex5 + 6,
          relativeRowIndex5 + 8,
          relativeRowIndex5 + 10,
          relativeRowIndex5 + 11,
          relativeRowIndex5 + 12,
          relativeRowIndex5 + 13,
        ],
        [
          relativeRowIndex6 + 1,
          relativeRowIndex6 + 2,
          relativeRowIndex6 + 5,
          relativeRowIndex6 + 6,
          relativeRowIndex6 + 8,
          relativeRowIndex6 + 10,
          relativeRowIndex6 + 11,
          relativeRowIndex6 + 12,
          relativeRowIndex6 + 13,
        ],
        [
          relativeRowIndex7 + 3,
          relativeRowIndex7 + 4,
          relativeRowIndex7 + 7,
          relativeRowIndex7 + 9,
          relativeRowIndex7 + 14,
        ],
        [
          relativeRowIndex8 + 3,
          relativeRowIndex8 + 4,
          relativeRowIndex8 + 7,
          relativeRowIndex8 + 9,
          relativeRowIndex8 + 14,
        ],
        [
          relativeRowIndex9 + 1,
          relativeRowIndex9 + 2,
          relativeRowIndex9 + 5,
          relativeRowIndex9 + 6,
          relativeRowIndex9 + 8,
          relativeRowIndex9 + 10,
          relativeRowIndex9 + 11,
          relativeRowIndex9 + 12,
          relativeRowIndex9 + 13,
        ],
        [
          relativeRowIndex10 + 3,
          relativeRowIndex10 + 4,
          relativeRowIndex10 + 7,
          relativeRowIndex10 + 9,
          relativeRowIndex10 + 14,
        ],
        [
          relativeRowIndex11 + 3,
          relativeRowIndex11 + 4,
          relativeRowIndex11 + 7,
          relativeRowIndex11 + 9,
          relativeRowIndex11 + 14,
        ],
        [
          relativeRowIndex12 + 1,
          relativeRowIndex12 + 2,
          relativeRowIndex12 + 5,
          relativeRowIndex12 + 6,
          relativeRowIndex12 + 8,
          relativeRowIndex12 + 10,
          relativeRowIndex12 + 11,
          relativeRowIndex12 + 12,
          relativeRowIndex12 + 13,
        ],
        [
          relativeRowIndex13 + 1,
          relativeRowIndex13 + 2,
          relativeRowIndex13 + 5,
          relativeRowIndex13 + 6,
          relativeRowIndex13 + 8,
          relativeRowIndex13 + 10,
          relativeRowIndex13 + 11,
          relativeRowIndex13 + 12,
          relativeRowIndex13 + 13,
        ],
        [
          relativeRowIndex14 + 3,
          relativeRowIndex14 + 4,
          relativeRowIndex14 + 7,
          relativeRowIndex14 + 9,
          relativeRowIndex14 + 14,
        ],
        [
          relativeRowIndex15 + 3,
          relativeRowIndex15 + 4,
          relativeRowIndex15 + 7,
          relativeRowIndex15 + 9,
          relativeRowIndex15 + 14,
        ],
      ],
      tertiaryColorRelativeIndexes: [
        [],
        [
          relativeRowIndex2 + 3,
          relativeRowIndex2 + 4,
          relativeRowIndex2 + 7,
          relativeRowIndex2 + 9,
          relativeRowIndex2 + 14,
        ],
        [],
        [],
        [
          relativeRowIndex5 + 3,
          relativeRowIndex5 + 4,
          relativeRowIndex5 + 7,
          relativeRowIndex5 + 9,
          relativeRowIndex5 + 14,
        ],
        [
          relativeRowIndex6 + 3,
          relativeRowIndex6 + 4,
          relativeRowIndex6 + 7,
          relativeRowIndex6 + 9,
          relativeRowIndex6 + 14,
        ],
        [],
        [],
        [
          relativeRowIndex9 + 3,
          relativeRowIndex9 + 4,
          relativeRowIndex9 + 7,
          relativeRowIndex9 + 9,
          relativeRowIndex9 + 14,
        ],
        [],
        [],
        [
          relativeRowIndex12 + 3,
          relativeRowIndex12 + 4,
          relativeRowIndex12 + 7,
          relativeRowIndex12 + 9,
          relativeRowIndex12 + 14,
        ],
        [
          relativeRowIndex13 + 3,
          relativeRowIndex13 + 4,
          relativeRowIndex13 + 7,
          relativeRowIndex13 + 9,
          relativeRowIndex13 + 14,
        ],
      ],
      quaternaryColorRelativeIndexes: [
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
        [relativeRowIndex2],
        [relativeRowIndex3],
        [relativeRowIndex4],
        [relativeRowIndex5],
        [relativeRowIndex6],
        [relativeRowIndex7],
        [relativeRowIndex8],
        [relativeRowIndex9],
        [relativeRowIndex10],
        [relativeRowIndex11],
        [relativeRowIndex12],
        [relativeRowIndex13],
        [relativeRowIndex14],
        [relativeRowIndex15],
      ],
    },
  ],
};
