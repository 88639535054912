import { Vector3, Object3D } from "three";
import { Tile } from "./Tile";
import { config } from "../../config/config";
import { Edge } from "./Edge";
import { Corner } from "./Corner";
import { InnerCorner } from "./InnerCorner";

export class Floor {
  static isTileTypePro = true;

  constructor() {
    this.tiles = new Object3D();
    this.tiles.name = "tiles";
    this.tileSize = config.defaultTileSize;
    this.edgeSize = config.defaultEdgeSize;
    this.numOfActiveTiles = 0;
    this.numOfActivePositiveEdges = 0;
    this.numOfActiveNegativeEdges = 0;
    this.numOfCorners = 0;
    this.numOfInnerCorners = 0;
    this.shiftedX = 0;
    this.shiftedZ = 0;
    this.patternStartIndex = 1156;
    this.pattern = null;
    this.primaryColor = null;
    this.secondaryColor = null;
    this.tertiaryColor = null;
    this.quaternaryColor = null;
    this.patternIndexAndColor = [];
    this.patternHorizontalMovement = 0;
    this.patternVerticalMovement = 0;
    this.tilesAdded = false;
  }

  addTiles = (
    walls = false,
    tileSize = this.tileSize,
    edgeSize = this.edgeSize
  ) => {
    if (this.tilesAdded) return;
    this.tileSize = tileSize;
    this.edgeSize = edgeSize;
    this.tilesAdded = true;
    let condition = true;
    let tilePositionX = 0;
    let tilePositionY = 0;
    let tile;
    let tileIndex = 0;

    while (condition) {
      tile = new Tile(tileIndex, this.tileSize);
      ++tileIndex;
      tile.applyMatrix4(
        tile.matrixWorld.makeTranslation(
          tilePositionX * this.tileSize,
          0,
          tilePositionY * this.tileSize
        )
      );
      tile.visible = false;
      this.tiles.add(tile);

      ++tilePositionX;
      if (tilePositionX >= config.numOfTilesPerSide) {
        ++tilePositionY;
        tilePositionX = 0;
      }
      tilePositionY >= config.numOfTilesPerSide && (condition = false);
    }
    this.tiles.position.set(
      (-config.numOfTilesPerSide * this.tileSize) / 2,
      0,
      (-config.numOfTilesPerSide * this.tileSize) / 2
    );
    walls && walls.moveFloorToWall(walls.wallGroup.children[0]);
    walls && walls.moveFloorToWall(walls.wallGroup.children[1]);
  };

  removeTiles = () => {
    if (!this.tilesAdded) return;
    this.numOfActivePositiveEdges = 0;
    this.numOfActiveNegativeEdges = 0;
    this.numOfCorners = 0;
    this.numOfInnerCorners = 0;
    this.shiftedX = 0;
    this.shiftedZ = 0;
    this.patternIndexAndColor = [];
    this.tiles.remove(...this.tiles.children);
    this.tilesAdded = false;
    Tile.changedMaterials.clear();
    Edge.changedEdgeColors.clear();
    Corner.changedCornerColors.clear();
    InnerCorner.changedInnerCornerColors.clear();
  };

  /**
   *
   * @returns Array of objects with id and quantity
   */
  validateTiles = (wallGroup, innerPoints) => {
    this.numOfActiveTiles = 0;
    this.numOfActivePositiveEdges = 0;
    this.numOfActiveNegativeEdges = 0;
    this.numOfCorners = 0;
    this.numOfInnerCorners = 0;
    let result;
    const variantsCount = [];
    const tilesToExclude = [];
    wallGroup.children.forEach((wall) => {
      tilesToExclude.push(...wall.tilesHiddenBecauseOfEdge);
    });
    this.tiles.children.forEach((tile, index) => {
      if (tilesToExclude.includes(index)) return;
      const edgeIds = Floor.isTileTypePro
        ? config.edgeVariantIds.pro
        : config.edgeVariantIds.home;
      result = this.validateTile(tile, innerPoints);
      if (result) {
        let colorCode;
        let variantIds;
        if (tile instanceof InnerCorner) {
          colorCode = "#" + tile.children[0].material.color.getHexString();
          const edgesLoopedId = edgeIds.edgesLooped[colorCode];
          const edgesPeggedId = edgeIds.edgesPegged[colorCode];
          const loopedAmount = tile.loopedAmount;
          const peggedAmount = tile.peggedAmount;
          loopedAmount &&
            (variantsCount[edgesLoopedId] =
              (variantsCount[edgesLoopedId] || 0) + loopedAmount);
          peggedAmount &&
            (variantsCount[edgesPeggedId] =
              (variantsCount[edgesPeggedId] || 0) + peggedAmount);
        } else {
          if (tile instanceof Tile) {
            variantIds = config.tiles[tile.material.map.textureId].variantIds;
            colorCode =
              tile.material.map.vinylTextureId !== undefined
                ? tile.material.map.vinylTextureId
                : "#" + tile.material.color.getHexString();
          } else if (tile instanceof Edge) {
            colorCode = "#" + tile.children[0].material.color.getHexString();
            variantIds = tile.positiveDirection
              ? edgeIds.edgesLooped
              : edgeIds.edgesPegged;
          } else if (tile instanceof Corner) {
            colorCode = "#" + tile.children[0].material.color.getHexString();
            variantIds = edgeIds.corners;
          }

          const variantId = variantIds[colorCode];
          variantsCount[variantId] = (variantsCount[variantId] || 0) + 1;
        }
      }
      result &&
        (tile instanceof Tile
          ? ++this.numOfActiveTiles
          : tile instanceof Edge
          ? tile.positiveDirection
            ? ++this.numOfActivePositiveEdges
            : ++this.numOfActiveNegativeEdges
          : tile instanceof Corner
          ? ++this.numOfCorners
          : ++this.numOfInnerCorners);
    });

    const returnValue = [];
    for (const key in variantsCount) {
      returnValue.push({ id: key, qty: variantsCount[key] });
    }

    // console.log(
    //   "value",
    //   returnValue,
    //   "number of: active tiles",
    //   this.numOfActiveTiles,
    //   "active edges positive",
    //   this.numOfActivePositiveEdges,
    //   "active edges negative",
    //   this.numOfActiveNegativeEdges,
    //   "active corners",
    //   this.numOfCorners,
    //   "active inner corners",
    //   this.numOfInnerCorners
    // );

    return returnValue;
  };

  validateTile = (tile, wallGroup) => {
    tile.visible = false;
    const relativeTilePoints = [
      // with additional points
      new Vector3(0, 0, 0),
      new Vector3(this.tileSize / 2, 0, 0), // additional point
      new Vector3(this.tileSize / 2, 0, 0),
      new Vector3(0, 0, this.tileSize / 2), // additional point
      new Vector3(0, 0, this.tileSize / 2),
      new Vector3(-this.tileSize / 2, 0, 0), // additional point
      new Vector3(-this.tileSize / 2, 0, 0),
      new Vector3(0, 0, -this.tileSize / 2), // additional point
    ];
    //   let relativeTilePoints = [
    //     new Vector3(0, 0, 0),
    //     new Vector3(this.tileSize, 0, 0),
    //     new Vector3(0, 0, this.tileSize),
    //     new Vector3(-this.tileSize, 0, 0)
    //   ];
    const tilePosition = new Vector3();
    tile.getWorldPosition(tilePosition);

    relativeTilePoints.forEach((relativePoint) => {
      tilePosition.add(relativePoint);
      if (this.pointInWalls(tilePosition, wallGroup)) {
        tile.visible = true;
      }
    });
    return tile.visible;
  };

  pointInWalls = (point, wallGroup) => {
    let wallPoints;
    const x = point.x;
    const z = point.z;
    let xi, zi, xj, zj;
    let pointInWall = false;
    const wallWorldPosition = new Vector3();

    wallGroup.children.forEach((wall) => {
      wall.getWorldPosition(wallWorldPosition);
      wallPoints = wall.geometry.attributes.position.array;
      xi = wallPoints[0] + wallWorldPosition.x; // * 0.99;
      xj = wallPoints[6] + wallWorldPosition.x; // * 0.99;
      zi = wallPoints[2] + wallWorldPosition.z; // * 0.99;
      zj = wallPoints[8] + wallWorldPosition.z; // * 0.99;
      xi <= 0 && (xi += config.pointInWallsTolerance);
      xj <= 0 && (xj += config.pointInWallsTolerance);
      zi <= 0 && (zi += config.pointInWallsTolerance);
      zj <= 0 && (zj += config.pointInWallsTolerance);
      xi > 0 && (xi -= config.pointInWallsTolerance);
      xj > 0 && (xj -= config.pointInWallsTolerance);
      zi > 0 && (zi -= config.pointInWallsTolerance);
      zj > 0 && (zj -= config.pointInWallsTolerance);
      const intersect =
        zi > z !== zj > z && x < ((xj - xi) * (z - zi)) / (zj - zi) + xi;
      intersect && (pointInWall = !pointInWall);
    });

    return pointInWall;
  };

  translateFloor = (
    verticalAmount,
    horizontalAmount,
    verticalDirection = true
  ) => {
    !verticalDirection &&
      ([verticalAmount, horizontalAmount] = [horizontalAmount, verticalAmount]);
    // verticalAmount = Math.trunc(verticalAmount * 1000) / 1000;
    // horizontalAmount = Math.trunc(horizontalAmount * 1000) / 1000;
    this.shiftedZ += verticalAmount;
    this.shiftedX += horizontalAmount;
    // this.shiftedZ %= this.tileSize;
    // this.shiftedX %= this.tileSize;
    this.tiles.children.forEach((tile) => {
      tile.translateZ(verticalAmount);
      tile.translateX(horizontalAmount);
    });
    this.tiles.updateMatrixWorld();
  };

  checkMaxFloorMovement = () => {
    const moveXBy = Math.trunc(this.shiftedX / this.tileSize) * this.tileSize;
    const moveZBy = Math.trunc(this.shiftedZ / this.tileSize) * this.tileSize;
    this.tiles.children.forEach((tile) => {
      tile.translateZ(-moveZBy);
      tile.translateX(-moveXBy);
    });
    this.shiftedZ -= moveZBy;
    this.shiftedX -= moveXBy;
    this.tiles.updateMatrixWorld();
  };

  resetFloorPosition = () => {
    this.tiles.children.forEach((tile) => {
      tile.translateZ(-this.shiftedZ);
      tile.translateX(-this.shiftedX);
    });
    this.shiftedX = 0;
    this.shiftedZ = 0;
    this.tiles.updateMatrixWorld();
  };

  getStartEndIndexBetweenCoords = (x1, z1, x2, z2) => {
    return [
      this.getTileIndexAtCoords(x1, z1),
      this.getTileIndexAtCoords(x2, z2),
    ];
  };

  getTileIndexAtCoords = (x, z) => {
    x -= this.tiles.position.x;
    z -= this.tiles.position.z;
    const tilesInMeter = 1 / this.tileSize;
    const xRelativeIndex = Math.floor(
      ((x - 0.001 - this.shiftedX) * tilesInMeter).toFixed(4)
    );
    const zRelativeIndex =
      config.numOfTilesPerSide *
      Math.floor(((z - this.shiftedZ) * tilesInMeter).toFixed(4));
    const index = xRelativeIndex + zRelativeIndex;
    return index;
  };

  movePattern = (movement) => {
    this.pattern.repeat
      ? this.moveRepeatPattern(movement)
      : this.moveShapePattern(movement);
  };

  moveShapePattern = (movement) => {
    switch (movement) {
      case "left":
        this.patternStartIndex += -1;
        break;
      case "right":
        this.patternStartIndex += 1;
        break;
      case "up":
        this.patternStartIndex += -config.numOfTilesPerSide;
        break;
      case "down":
        this.patternStartIndex += config.numOfTilesPerSide;
        break;
      default:
        break;
    }
    this.placePattern();
  };

  moveRepeatPattern = (movement) => {
    let indexMovement;
    switch (movement) {
      case "left":
        --this.patternHorizontalMovement;
        indexMovement = -1;
        if (this.patternHorizontalMovement <= -this.pattern.width) {
          indexMovement = -this.patternHorizontalMovement - 1;
          this.patternHorizontalMovement = 0;
        }
        break;
      case "right":
        this.patternHorizontalMovement += 1 - this.pattern.width;
        indexMovement = 1 - this.pattern.width;
        if (this.patternHorizontalMovement <= -this.pattern.width) {
          indexMovement += this.pattern.width;
          this.patternHorizontalMovement += this.pattern.width;
        }
        break;
      case "up":
        --this.patternVerticalMovement;
        indexMovement = -config.numOfTilesPerSide;
        if (this.patternVerticalMovement === -this.pattern.height) {
          indexMovement =
            -config.numOfTilesPerSide * (this.patternVerticalMovement + 1);
          this.patternVerticalMovement = 0;
        }
        break;
      case "down":
        this.patternVerticalMovement += 1 - this.pattern.height;
        indexMovement = config.numOfTilesPerSide * (1 - this.pattern.height);
        if (this.patternVerticalMovement <= -this.pattern.height) {
          indexMovement += this.pattern.height * config.numOfTilesPerSide;
          this.patternVerticalMovement += this.pattern.height;
        }
        break;
      default:
        break;
    }
    this.shiftPatternIndexes(
      this.pattern.primaryColorRelativeIndexes,
      indexMovement
    );
    this.shiftPatternIndexes(
      this.pattern.secondaryColorRelativeIndexes,
      indexMovement
    );
    this.shiftPatternIndexes(
      this.pattern.tertiaryColorRelativeIndexes,
      indexMovement
    );
    this.shiftPatternIndexes(
      this.pattern.quaternaryColorRelativeIndexes,
      indexMovement
    );
    this.placePattern();
  };

  shiftPatternIndexes = (indexes, amount) => {
    indexes.forEach((row, rowIndex) => {
      row.forEach((tile, tileIndex) => {
        indexes[rowIndex][tileIndex] = tile + amount;
      });
    });
  };

  makePattern = (
    pattern,
    primaryColor,
    secondaryColor,
    tertiaryColor,
    quaternaryColor,
    vinylTextureId
  ) => {
    if (this.pattern) {
      this.shiftPatternIndexes(
        this.pattern.primaryColorRelativeIndexes,
        -this.patternHorizontalMovement -
          this.patternVerticalMovement * config.numOfTilesPerSide
      );
      this.shiftPatternIndexes(
        this.pattern.secondaryColorRelativeIndexes,
        -this.patternHorizontalMovement -
          this.patternVerticalMovement * config.numOfTilesPerSide
      );
      this.shiftPatternIndexes(
        this.pattern.tertiaryColorRelativeIndexes,
        -this.patternHorizontalMovement -
          this.patternVerticalMovement * config.numOfTilesPerSide
      );
      this.shiftPatternIndexes(
        this.pattern.quaternaryColorRelativeIndexes,
        -this.patternHorizontalMovement -
          this.patternVerticalMovement * config.numOfTilesPerSide
      );
    }
    vinylTextureId && (Tile.selectedTextureId = vinylTextureId);
    this.patternHorizontalMovement = 0;
    this.patternVerticalMovement = 0;
    this.pattern = pattern;
    this.patternStartIndex = this.pattern.repeat ? 0 : 1156;
    this.primaryColor = primaryColor;
    this.secondaryColor = secondaryColor;
    this.tertiaryColor = tertiaryColor;
    this.quaternaryColor = quaternaryColor;
    this.placePattern();
  };

  placePattern = () => {
    this.removePattern();
    Tile.changeBaseColor(this.primaryColor);
    const rowNumber = Math.floor(
      this.patternStartIndex / config.numOfTilesPerSide
    );
    if (this.pattern.repeat) {
      const horizontalIndex = this.patternStartIndex % config.numOfTilesPerSide;
      const verticalIndex = rowNumber * config.numOfTilesPerSide;
      for (
        let i = horizontalIndex, j = verticalIndex;
        i <= config.numOfTilesPerSide - this.patternHorizontalMovement;
        i += this.pattern.width
      ) {
        this.makeSubPattern(i + j, Math.floor(j / config.numOfTilesPerSide));
        if (
          i + this.pattern.width >=
          config.numOfTilesPerSide - this.patternHorizontalMovement
        ) {
          if (
            j +
              (this.pattern.height + this.patternVerticalMovement) *
                config.numOfTilesPerSide <=
            config.numOfTilesPerSide ** 2
          ) {
            i = -this.pattern.width;
            j += this.pattern.height * config.numOfTilesPerSide;
          } else {
            i = config.numOfTilesPerSide + this.pattern.width;
          }
        }
      }
    } else {
      this.makeSubPattern(this.patternStartIndex, rowNumber);
    }
  };

  makeSubPattern = (startIndex, rowNumber) => {
    this.makeSubPatternSection(
      startIndex,
      rowNumber,
      this.pattern.primaryColorRelativeIndexes,
      this.primaryColor
    );
    this.makeSubPatternSection(
      startIndex,
      rowNumber,
      this.pattern.secondaryColorRelativeIndexes,
      this.secondaryColor
    );
    this.makeSubPatternSection(
      startIndex,
      rowNumber,
      this.pattern.tertiaryColorRelativeIndexes,
      this.tertiaryColor
    );
    this.makeSubPatternSection(
      startIndex,
      rowNumber,
      this.pattern.quaternaryColorRelativeIndexes,
      this.quaternaryColor
    );
  };

  makeSubPatternSection = (
    startIndex,
    rowNumber,
    patternSectionRelativeIndexes,
    primaryColor
  ) => {
    let tileIndex;
    patternSectionRelativeIndexes.forEach((relativeRowIndexes, index) => {
      relativeRowIndexes.forEach((relativeIndex) => {
        tileIndex = startIndex + relativeIndex;
        if (
          this.tiles.children[tileIndex] &&
          tileIndex <
            (1 + rowNumber + index + this.patternVerticalMovement) *
              config.numOfTilesPerSide &&
          tileIndex >=
            (rowNumber + index + this.patternVerticalMovement) *
              config.numOfTilesPerSide &&
          tileIndex < config.numOfTilesPerSide ** 2 &&
          this.tiles.children[tileIndex] instanceof Tile
        ) {
          this.tiles.children[tileIndex].changeColor(primaryColor);
          this.patternIndexAndColor[tileIndex] = primaryColor;
        }
      });
    });
  };

  removePattern = () => {
    this.patternIndexAndColor.forEach((value, key) => {
      this.tiles.children[key].material = Tile.materialTile;
      Tile.changedMaterials.delete(this.tiles.children[key]);
    });
    this.patternIndexAndColor = [];
  };

  convertToCorner = (index, wall1, wall2) => {
    let replaceWith;
    const wall1Positive = wall1.positiveDirection;
    const wall2Positive = wall2.positiveDirection;
    let moveLeft = wall1Positive;
    let moveUp = wall2Positive;
    const wall1Row = wall1.orientationRow;
    const wall2Row = wall2.orientationRow;

    if (wall1Positive === wall2Positive) {
      moveLeft = !wall1Positive;
      moveUp = !wall2Positive;
      if (wall1Row && !wall2Row) {
        replaceWith = new InnerCorner(
          index,
          this.tileSize,
          this.edgeSize,
          moveUp,
          moveLeft
        );
      } else if (!wall1Row && wall2Row) {
        replaceWith = new Corner(
          index,
          this.edgeSize,
          this.tileSize,
          moveUp,
          moveLeft
        );
      }
    } else {
      if (wall1Row && !wall2Row) {
        replaceWith = new Corner(
          index,
          this.edgeSize,
          this.tileSize,
          moveUp,
          moveLeft
        );
      } else if (!wall1Row && wall2Row) {
        replaceWith = new InnerCorner(
          index,
          this.tileSize,
          this.edgeSize,
          moveUp,
          moveLeft
        );
      }
    }

    !this.tiles.children[index].visible && (replaceWith.visible = false);
    return replaceWith;
  };
}
