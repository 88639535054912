export const createElement = (type, id, className, options = {}) => {
  const element = document.createElement(type);
  id && (element.id = id);
  className && (element.className = className);

  for (const option in options) {
    element.setAttribute(option, options[option]);
  }
  return element;
};

export function sleep (timeout) {
  return new Promise(resolve => setTimeout(resolve, timeout));
}

export const convertToImperial = (lengthMetric) => {
  let feet = lengthMetric * 3.28084;
  let inch = Math.trunc(Math.round((feet - Math.trunc(feet)) * 12));
  if (inch === 12) {
    inch = 0;
    ++feet;
  }
  feet = Math.trunc(feet);
  return `${feet}\`${inch}\`\``;
};

export const getNextIndex = (currentIndex, maxIndex) => {
  return (currentIndex + 1) % maxIndex;
};

export const getPreviousIndex = (currentIndex, maxIndex) => {
  return (currentIndex - 1 + maxIndex) % maxIndex;
};
