import { getCookie } from '../assets/helpers/cookie';
import { setLocalStorage } from '../assets/helpers/localStorage';
import { fetchGet } from './api';

export function getScenes () {
  if (getCookie('token_check') !== 'valid') return;
  fetchGet('/scenes')
    .then(response => {
      setLocalStorage('scenes', response?.data?.scenes);
    }).catch(error => {
      console.log('An unexpected error occured during lookup of saved designs', error);
    });
}
