// import * as dat from 'dat.gui';
import * as QuickSettings from './../../assets/libs/quicksettings/quicksettings';
import { config } from '../../config/config';
import Localization from './../../assets/localization/';

/**
* Class ObjectModelControls are used as 'abstract' class for creation of all UI Controls
*/
export default class ObjectModelControls {
  constructor () {
    this.localization = new Localization();
    this.config = config;
  }

  /**
   * Create settigns control element panel
   * @param panelName {string} Panel name
   * @param positionX {int}  absolute x position of panel
   * @param positionY {int} absolute y position of panel
   * @param [parentHtmlElement] {HTMLElement} Html element that will contain created element (default 'quickSettingsHolder')
   * @returns HTMLElement {HTMLElement}
   */
  createNewPanel = (panelName, positionX, positionY, parentHtmlElement = document.getElementById('quickSettingsHolder')) => {
    return QuickSettings.create(positionX, positionY, panelName || this.localization.strings.projectName, parentHtmlElement);
  }

  /**
   * Create wrapper for control panel if do not exist on document
   * @param {*} type HTML type
   * @param {*} id HTML id for element
   * @param {*} className class
   * @param {*} parent
   */
  createWrappingElement = (type, id, className, parent = document.getElementsByTagName('body')[0]) => {
    if (!parent) return;
    const element = document.createElement(type);
    if (!element) return;
    element.id = id;
    if (className) {
      element.className = className;
    }

    if (parent) {
      parent.appendChild(element);
    }
    return element;
  }
}
