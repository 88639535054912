import ObjectModelControls from "../ObjectModelControls";

export default class ColorControlPanel extends ObjectModelControls {
  constructor(onChange, parentEl = null, wrapperIdPrefix = "") {
    super();
    this.panel = null;
    this.parentElement = parentEl;
    this.wrapperIdPrefix = wrapperIdPrefix;
    this.optionName = wrapperIdPrefix + "ColorColorControl";
    this.holderElement = this.createColorWrapper(
      this.wrapperIdPrefix,
      parentEl
    );
    this.colors = this.prepareColors();
    this.onChange = onChange;

    this.selectedColorIndex = 0;
    this.selectedColor = this.colors[this.selectedColorIndex];
  }

  prepareColors = () => {
    return [
      "#ffffff", // ARCTIC WHITE
      "#b0b9bd", // PEARL SILVER
      "#9f9f9f", // PEARL GREY
      "#7c7c7c", // SLATE GREY
      "#212121", // JET BLACK
      // "#65402f", // CHOCOLATE BROWN
      // "#aa5935", // TERRA COTTA
      "#ead7b0", // IVORY
      "#fcd900", // CITRUS YELLOW
      "#96d700", // TECHNO GREEN
      "#72a031", // TURF GREEN
      "#008175", // TEAL
      "#225e8f", // ISLAND BLUE
      "#002e6b", // ROYAL BLUE
      "#6f2e89", // COSMIC PURPLE
      "#e84b93", // CARNIVAL PINK
      "#cf102d", // RACING RED
      "#dc4405", // TROPICAL ORANGE
    ];
    // return this.config.colorPalettePro;
  };

  createColorWrapper = (wrapperIdPrefix, parent) => {
    return this.createWrappingElement(
      "div",
      wrapperIdPrefix + "ColorControl",
      "color-control-panel",
      parent
    );
  };

  create = (panelName, x = 0, y = 0) => {
    !this.panel &&
      (this.panel = this.createNewPanel(panelName, x, y, this.holderElement));
    this.createColorOption();
    this.addColorNameForPanel();
  };

  createColorOption = (value, optionName = this.optionName) => {
    this.singleTileColorOptionName = optionName;
    this.panel.addColorSelector(
      this.singleTileColorOptionName,
      this.onColorChange,
      5,
      this.colors
    );
  };

  addColorNameForPanel = () => {
    const el = this.parentElement;
    const holder = el.querySelector(".qs_main");
    const textDiv = document.createElement("div");
    textDiv.classList.add("single-tile-control-color-name-title");
    textDiv.innerHTML = "OPJHAAAAA";
    holder.prepend(textDiv);
  };

  onColorChange = (color) => {
    this.onChange(color);
  };

  getValue = (optionName) => {
    return this.panel.getValue(optionName);
  };

  setValue = (value, optionName = this.optionName) => {
    this.panel.setValue(optionName, value);
  };

  changeColorPalette = (palette, optionName = this.optionName) => {
    this.panel.changeColorPalette(optionName, palette);
  };

  /**
   * Set units for
   * @param {*} units metric|imperial
   */
  setUnits = (units) => {
    this.units = units;
  };

  hide = () => {
    this.panel.hide();
  };

  show = () => {
    this.panel.show();
  };

  remove = () => {
    this.panel.destroy();
  };
}
