import { MultiplyBlending, TextureLoader, Path, ShapeBufferGeometry, Vector2, Shape, DoubleSide, Object3D, Mesh, MeshBasicMaterial, CircleGeometry, Vector3, BufferGeometry, Float32BufferAttribute, Color, LineBasicMaterial, Line } from 'three';
import { config } from '../../config/config';
import { Tile } from '../floor/Tile';
import { Edge } from '../floor/Edge';
import { Corner } from '../floor/Corner';
import { InnerCorner } from '../floor/InnerCorner';
import { DynamicTexture } from '../../threex.dynamictexture';
import arrowTexture from '../../assets/textures/arrows.svg';
import arrowPadsTexture from '../../assets/textures/arrowsPads.svg';
import worldTexture from '../../assets/textures/e9e9e9.jpg';
import wallBaseTexture from '../../assets/textures/777777.jpg';
import wallTexture from '../../assets/textures/wall.jpg';
import { convertToImperial, getNextIndex, getPreviousIndex } from '../../assets/helpers/common.js';
import { EdgePiece } from '../floor/EdgePiece';

// middle point and dimensions should be a part of wall meshes
export class Walls extends Object3D {
  static materialTransparent = new MeshBasicMaterial({ opacity: 0, transparent: true });
  static minOpacity = 0.15;
  static maxOpacity = 0.3;
  static textureArrow = new TextureLoader().load(arrowTexture);
  static textureArrowPads = new TextureLoader().load(arrowPadsTexture);
  static materialCircle = new MeshBasicMaterial({ map: Walls.textureArrow });
  static materialMask = new MeshBasicMaterial({ map: new TextureLoader().load(worldTexture) });
  static materialWall = new MeshBasicMaterial({ map: new TextureLoader().load(wallBaseTexture) });
  static materialEdgeHandleAdd = new MeshBasicMaterial({ color: '#00ff00', transparent: true });
  static materialEdgeHandleRemove = new MeshBasicMaterial({ color: '#ff0000', transparent: true });
  static materialEdgeHandleTransparent = new MeshBasicMaterial({ opacity: 0, transparent: true });
  static materialWallInner = new MeshBasicMaterial({ color: '#dddddd', map: new TextureLoader().load(wallTexture) });
  static materialConcrete = new MeshBasicMaterial({ color: config.concreteColor, side: DoubleSide });
  static circleSize = 0.4;
  static middlePointSize = 0.3;
  static geometryCircleVisual = new CircleGeometry(0.15, 32);
  static geomSquare = new BufferGeometry().setAttribute(
    'position',
    new Float32BufferAttribute(
      [
        -Walls.middlePointSize / 2, 0, -Walls.middlePointSize / 2,
        Walls.middlePointSize / 2, 0, Walls.middlePointSize / 2,
        Walls.middlePointSize / 2, 0, -Walls.middlePointSize / 2,
        -Walls.middlePointSize / 2, 0, -Walls.middlePointSize / 2,
        -Walls.middlePointSize / 2, 0, Walls.middlePointSize / 2,
        Walls.middlePointSize / 2, 0, Walls.middlePointSize / 2
      ],
      3
    )
  );

  static geomSquareVisual = new BufferGeometry().setAttribute(
    'position',
    new Float32BufferAttribute(
      [
        -0.3 / 2, 0, -0.3 / 2,
        0.3 / 2, 0, 0.3 / 2,
        0.3 / 2, 0, -0.3 / 2,
        -0.3 / 2, 0, -0.3 / 2,
        -0.3 / 2, 0, 0.3 / 2,
        0.3 / 2, 0, 0.3 / 2
      ],
      3
    )
  ).setAttribute(
    'uv',
    new Float32BufferAttribute(
      [
        0, 1,
        1, 0,
        1, 1,
        0, 1,
        0, 0,
        1, 0
      ],
      2
    )
  );

  static worldDimensions = config.footToMeter * config.numOfTilesPerSide;
  static worldOutlinePoints = [
    new Vector3(-Walls.worldDimensions / 2 + 0.5, 0.01, -Walls.worldDimensions / 2 + 0.5),
    new Vector3(Walls.worldDimensions / 2 - 0.5, 0.01, -Walls.worldDimensions / 2 + 0.5),
    new Vector3(Walls.worldDimensions / 2 - 0.5, 0.01, Walls.worldDimensions / 2 - 0.5),
    new Vector3(-Walls.worldDimensions / 2 + 0.5, 0.01, Walls.worldDimensions / 2 - 0.5),
    new Vector3(-Walls.worldDimensions / 2 + 0.5, 0.01, -Walls.worldDimensions / 2 + 0.5)
  ];

  static outerMaskPoints = [
    new Vector2(-Walls.worldDimensions / 2 - 1, -Walls.worldDimensions / 2 - 1),
    new Vector2(Walls.worldDimensions / 2 + 1, -Walls.worldDimensions / 2 - 1),
    new Vector2(Walls.worldDimensions / 2 + 1, Walls.worldDimensions / 2 + 1),
    new Vector2(-Walls.worldDimensions / 2 - 1, Walls.worldDimensions / 2 + 1)
  ];

  constructor (wallPoints, floor, camera, renderer, addedOtherObjects, forcePositions = false) {
    super();
    this.renderer = renderer;
    this.floor = floor;
    this.wallGroup = new Object3D();
    this.edgeHandles = new Object3D();
    this.innerWalls = new Object3D();
    this.wall3DComponentGroup = new Object3D();
    this.wallCornerPoints = new Object3D();
    this.wallMiddlePoints = new Object3D();
    this.dimensionComponents = new Object3D();
    this.mask = new Object3D();
    this.concrete = new Object3D();
    this.wallObjects = new Object3D();
    this.addedOtherObjects = addedOtherObjects;
    this.maskHolePoints = [];
    this.wallNormals = [];
    this.holesShape = new Shape();
    this.maxXCoord = 0;
    this.maxZCoord = 0;
    this.wallPoints = wallPoints;
    this.wallOuterPoints = [];
    this.wallInnerPoints = [];
    this.wallFarInnerPoints = [];
    this.metric = false;
    this.pads = false;
    this.addedEdges = new Set();
    this.edgeHandlesState = 0;
    this.worldOutline = new Line(
      new BufferGeometry().setFromPoints(Walls.worldOutlinePoints),
      new LineBasicMaterial({ color: 0xff8a40 })
    );

    wallPoints.forEach(wallPoint => {
      wallPoint[0] > this.maxXCoord && (this.maxXCoord = wallPoint[0]);
      wallPoint[2] > this.maxZCoord && (this.maxZCoord = wallPoint[2]);
    });

    this.makePoints(forcePositions);

    wallPoints.forEach((wallPoint, index) => {
      this.maskHolePoints.push(new Vector2());
      this.makeWallOuterPoints(index);
    });

    this.makeWalls();
    this.makeMask();
    this.makeDimensionComponents();

    this.wallGroup.name = 'wallGroup';
    this.innerWalls.name = 'innerWalls';
    this.edgeHandles.name = 'edgeHandles';
    this.wall3DComponentGroup.name = 'wall3DComponentGroup';
    this.wallCornerPoints.name = 'wallCornerPoints';
    this.wallMiddlePoints.name = 'wallMiddlePoints';
    this.dimensionComponents.name = 'dimensionComponents';
    this.mask.name = 'mask';
    this.concrete.name = 'concrete';
    this.wallObjects.name = 'wallObjects';

    this.add(this.wallGroup);
    this.add(this.innerWalls);
    this.add(this.edgeHandles);
    this.add(this.wall3DComponentGroup);
    this.add(this.wallCornerPoints);
    this.add(this.wallMiddlePoints);
    this.add(this.dimensionComponents);
    this.add(this.mask);
    this.add(this.concrete);
    this.add(this.wallObjects);
    this.add(this.worldOutline);
  }

  getWallLineEquationParams = (index) => {
    let moveAmountAngledWall = config.wallThickness;
    let edgeHandleThickness = this.floor.edgeSize - 0.001;
    const startPos = this.wallCornerPoints.children[index].position;
    const endPos = this.wallCornerPoints.children[getNextIndex(index, this.wallCornerPoints.children.length)].position;
    startPos.x = +(startPos.x).toFixed(6);
    startPos.z = +(startPos.z).toFixed(6);
    endPos.x = +(endPos.x).toFixed(6);
    endPos.z = +(endPos.z).toFixed(6);
    let ratio = Math.abs(endPos.x - startPos.x) / Math.abs(endPos.z - startPos.z);

    if (!isFinite(ratio)) {
      ratio = 1;
    } else {
      moveAmountAngledWall = Math.sqrt(moveAmountAngledWall ** 2 / (1 + ratio ** 2));
      edgeHandleThickness = Math.sqrt(edgeHandleThickness ** 2 / (1 + ratio ** 2));
    }

    const zDirection = endPos.x > startPos.x ? -1 : 1;
    const xDirection = endPos.z > startPos.z ? 1 : -1;
    const x1 = startPos.x + moveAmountAngledWall * xDirection;
    const z1 = startPos.z + moveAmountAngledWall * ratio * zDirection;
    const x2 = endPos.x + moveAmountAngledWall * xDirection;
    const z2 = endPos.z + moveAmountAngledWall * ratio * zDirection;
    const innerX1 = startPos.x + (edgeHandleThickness * -xDirection);
    const innerZ1 = startPos.z + (edgeHandleThickness * ratio * -zDirection);
    const innerX2 = endPos.x + (edgeHandleThickness * -xDirection);
    const innerZ2 = endPos.z + (edgeHandleThickness * ratio * -zDirection);
    const farInnerX1 = startPos.x + 3 * moveAmountAngledWall * -xDirection;
    const farInnerZ1 = startPos.z + 3 * moveAmountAngledWall * ratio * -zDirection;
    const farInnerX2 = endPos.x + 3 * moveAmountAngledWall * -xDirection;
    const farInnerZ2 = endPos.z + 3 * moveAmountAngledWall * ratio * -zDirection;
    const k = (z2 - z1) / (x2 - x1);

    return {
      k,
      x1,
      z1,
      x2,
      z2,
      innerX1,
      innerZ1,
      innerX2,
      innerZ2,
      farInnerX1,
      farInnerZ1,
      farInnerX2,
      farInnerZ2
    };
  }

  makeWallOuterPoints = (index) => {
    let secretSauceX;
    let secretSauceZ;
    let innerSecretSauceX;
    let innerSecretSauceZ;
    let farInnerSecretSauceX;
    let farInnerSecretSauceZ;
    const wallEquationParams = this.getWallLineEquationParams(index);
    const prevWallEquationParams = this.getWallLineEquationParams(getPreviousIndex(index, this.wallCornerPoints.children.length));

    if (!isFinite(wallEquationParams.k) && !isFinite(prevWallEquationParams.k)) {
      secretSauceX = wallEquationParams.x1;
      secretSauceZ = wallEquationParams.z1;
      innerSecretSauceX = wallEquationParams.innerX1;
      innerSecretSauceZ = wallEquationParams.innerZ1;
      farInnerSecretSauceX = wallEquationParams.farInnerX1;
      farInnerSecretSauceZ = wallEquationParams.farInnerZ1;
    } else if (wallEquationParams.k === 0 && prevWallEquationParams.k === 0) {
      secretSauceX = wallEquationParams.x1;
      secretSauceZ = wallEquationParams.z1;
      innerSecretSauceX = wallEquationParams.innerX1;
      innerSecretSauceZ = wallEquationParams.innerZ1;
      farInnerSecretSauceX = wallEquationParams.farInnerX1;
      farInnerSecretSauceZ = wallEquationParams.farInnerZ1;
    } else if (!isFinite(wallEquationParams.k)) {
      secretSauceX = wallEquationParams.x1;
      secretSauceZ = prevWallEquationParams.k * (secretSauceX - prevWallEquationParams.x1) + prevWallEquationParams.z1;
      innerSecretSauceX = wallEquationParams.innerX1;
      innerSecretSauceZ = prevWallEquationParams.k * (innerSecretSauceX - prevWallEquationParams.innerX1) + prevWallEquationParams.innerZ1;
      farInnerSecretSauceX = wallEquationParams.farInnerX1;
      farInnerSecretSauceZ = prevWallEquationParams.k * (farInnerSecretSauceX - prevWallEquationParams.farInnerX1) + prevWallEquationParams.farInnerZ1;
    } else if (!isFinite(prevWallEquationParams.k)) {
      secretSauceX = prevWallEquationParams.x2;
      secretSauceZ = wallEquationParams.k * (secretSauceX - wallEquationParams.x1) + wallEquationParams.z1;
      innerSecretSauceX = prevWallEquationParams.innerX2;
      innerSecretSauceZ = wallEquationParams.k * (innerSecretSauceX - wallEquationParams.innerX1) + wallEquationParams.innerZ1;
      farInnerSecretSauceX = prevWallEquationParams.farInnerX2;
      farInnerSecretSauceZ = wallEquationParams.k * (farInnerSecretSauceX - wallEquationParams.farInnerX1) + wallEquationParams.farInnerZ1;
    } else {
      secretSauceX = (-prevWallEquationParams.k * prevWallEquationParams.x1 + prevWallEquationParams.z1 + wallEquationParams.k * wallEquationParams.x1 - wallEquationParams.z1) / (wallEquationParams.k - prevWallEquationParams.k);
      secretSauceZ = wallEquationParams.k * (secretSauceX - wallEquationParams.x2) + wallEquationParams.z2;
      innerSecretSauceX = (-prevWallEquationParams.k * prevWallEquationParams.innerX1 + prevWallEquationParams.innerZ1 + wallEquationParams.k * wallEquationParams.innerX1 - wallEquationParams.innerZ1) / (wallEquationParams.k - prevWallEquationParams.k);
      innerSecretSauceZ = wallEquationParams.k * (innerSecretSauceX - wallEquationParams.innerX2) + wallEquationParams.innerZ2;
      farInnerSecretSauceX = (-prevWallEquationParams.k * prevWallEquationParams.farInnerX1 + prevWallEquationParams.farInnerZ1 + wallEquationParams.k * wallEquationParams.farInnerX1 - wallEquationParams.farInnerZ1) / (wallEquationParams.k - prevWallEquationParams.k);
      farInnerSecretSauceZ = wallEquationParams.k * (farInnerSecretSauceX - wallEquationParams.farInnerX2) + wallEquationParams.farInnerZ2;
    }

    this.wallOuterPoints[index] = {
      position: {
        x: secretSauceX,
        y: this.wallCornerPoints.children[index].position.y,
        z: secretSauceZ
      }
    };
    this.wallInnerPoints[index] = {
      position: {
        x: innerSecretSauceX,
        y: this.wallCornerPoints.children[index].position.y,
        z: innerSecretSauceZ,
        movementX: 0,
        movementZ: 0
      }
    };
    this.wallFarInnerPoints[index] = {
      position: {
        x: farInnerSecretSauceX,
        y: this.wallCornerPoints.children[index].position.y,
        z: farInnerSecretSauceZ
      }
    };

    this.maskHolePoints[index].y = secretSauceX;
    this.maskHolePoints[index].x = secretSauceZ;
    const newNormal = new Vector3(wallEquationParams.z2 - wallEquationParams.z1, 0, wallEquationParams.x1 - wallEquationParams.x2).normalize();
    this.wallNormals[index] = newNormal;
    this.wallObjects.children.forEach(object => {
      if (object.index === index) {
        object.normal = newNormal;
      }
    });
  }

  makePoints = (forcePositions) => {
    let cornerHandle, cornerHandleVisual, wallHandle, wallHandleVisual, nextWall;
    this.wallPoints.forEach((wallPoint, index) => {
      nextWall = this.wallPoints[getNextIndex(index, this.wallPoints.length)];
      cornerHandle = new Mesh(Walls.geomSquare, Walls.materialTransparent);
      if (forcePositions) {
        cornerHandle.position.set(wallPoint[0], wallPoint[1], wallPoint[2]);
      } else {
        cornerHandle.position.set(wallPoint[0] - this.maxXCoord / 2, wallPoint[1] + 0.02, wallPoint[2] - this.maxZCoord / 2);
      }
      cornerHandle.index = index;

      cornerHandleVisual = new Mesh(Walls.geometryCircleVisual, Walls.materialCircle);
      cornerHandleVisual.index = index;
      // cornerHandleVisual.rotation.x = -Math.PI / 2;
      cornerHandle.add(cornerHandleVisual);
      this.wallCornerPoints.add(cornerHandle);

      wallHandle = new Mesh(Walls.geomSquare, Walls.materialTransparent);
      if (forcePositions) {
        wallHandle.position.set(
          (wallPoint[0] + nextWall[0]) / 2,
          wallPoint[1],
          (wallPoint[2] + nextWall[2]) / 2
        );
      } else {
        wallHandle.position.set(
          (wallPoint[0] + nextWall[0] - this.maxXCoord) / 2,
          wallPoint[1] + 0.02,
          (wallPoint[2] + nextWall[2] - this.maxZCoord) / 2
        );
      }
      wallHandle.index = index;

      wallHandleVisual = new Mesh(Walls.geomSquareVisual, Walls.materialCircle);
      wallHandleVisual.position.setZ(-config.wallThickness / 2);
      wallHandleVisual.index = index;
      wallHandle.add(wallHandleVisual);
      this.wallMiddlePoints.add(wallHandle);
    });
  };

  centerPoints = (wall) => {
    let testAngle = 0;
    const coords = wall.geometry.attributes.position.array;
    const x1 = coords[0];
    const z1 = coords[2];
    const x2 = coords[3];
    const z2 = coords[5];
    const k = (z2 - z1) / (x2 - x1);
    if (x2 < x1) {
      testAngle = Math.PI;
    } else {
      testAngle = 0;
    }
    const differenceX = (coords[15] - coords[0]) / 2;
    const differenceZ = (coords[17] - coords[2]) / 2;
    this.wallCornerPoints.children[wall.index].children[0].position.setX(differenceX);
    this.wallCornerPoints.children[wall.index].children[0].position.setZ(differenceZ);
    this.wallCornerPoints.children[wall.index].children[0].setRotationFromAxisAngle(new Vector3(0, 0, 1), -Math.atan(k) + testAngle);
    this.wallCornerPoints.children[wall.index].children[0].setRotationFromAxisAngle(new Vector3(1, 0, 0), -Math.PI / 2);
    this.wallMiddlePoints.children[wall.index].setRotationFromAxisAngle(new Vector3(0, 1, 0), -Math.atan(k) + testAngle);
  }

  setWallHandleVisible = (visible) => {
    !this.pads && (this.wallCornerPoints.visible = visible);
    this.wallMiddlePoints.visible = visible;
    this.setDimensionComponentVisible(visible);
  }

  setDimensionComponentVisible = (visible) => {
    this.dimensionComponents.visible = visible;
  }

  setEdgeHandlesState = (value) => {
    this.edgeHandlesState = value;
    this.edgeHandles.children.forEach((edgeHandle, index) => {
      switch (value) {
        case 0:
          edgeHandle.visible = (!edgeHandle.hasEdges && this.wallGroup.children[index].orientationRow !== undefined);
          break;
        case 1:
          edgeHandle.visible = false;
          break;
        case 2:
          edgeHandle.visible = edgeHandle.hasEdges;
          break;
        default:
          break;
      }
    });
  }

  pulsateEdgeHandles = (now) => {
    if (this.edgeHandles.visible) {
      const opacity = Walls.minOpacity + Math.abs(Math.sin(now * 0.0025) * (Walls.maxOpacity - Walls.minOpacity));
      Walls.materialEdgeHandleAdd.opacity = opacity;
      Walls.materialEdgeHandleRemove.opacity = opacity;
    }
  }

  makeWalls = () => {
    let nextPoint;
    let wall;
    let innerWall;
    let edgeHandleVisible;
    let edgeHandle;
    let outerWall;

    this.wallCornerPoints.children.forEach((wallPoint, index) => {
      nextPoint = this.wallCornerPoints.children[getNextIndex(index, this.wallCornerPoints.children.length)];
      wall = new Mesh(new BufferGeometry(), Walls.materialWall);
      wall.index = index;
      wall.hasEdges = false;
      wall.userPlacedEdges = false;
      wall.tileEdgeIndexes = new Set();
      wall.tilesHiddenBecauseOfEdge = [];
      edgeHandle = new Mesh(new BufferGeometry(), Walls.materialEdgeHandleTransparent);
      edgeHandle.index = index;
      edgeHandle.hasEdges = false;
      edgeHandleVisible = new Mesh(new BufferGeometry(), Walls.materialEdgeHandleAdd);
      edgeHandleVisible.index = index;
      edgeHandleVisible.hasEdges = false;

      edgeHandle.add(edgeHandleVisible);
      this.edgeHandles.add(edgeHandle);
      wall.orientationRow = this.isWallOrientationRow(wallPoint, nextPoint);
      innerWall = new Mesh(
        new BufferGeometry().setAttribute(
          'uv',
          new Float32BufferAttribute(
            [
              0, 1,
              1, 0,
              1, 1,
              0, 1,
              0, 0,
              1, 0
            ],
            2
          )
        ),
        Walls.materialWallInner
      );
      innerWall.index = index;
      this.innerWalls.add(innerWall);
      outerWall = new Mesh(new BufferGeometry(), Walls.materialWall);
      outerWall.geometry.wallIndex = index;
      this.wall3DComponentGroup.add(outerWall);
      this.drawWall(wall);

      const onBeforeRender = (renderer, scene, camera, geometry, material, group) => {
        const v = new Vector3();
        const positionArray = geometry.attributes.position.array;
        const startX = positionArray[0];
        const endX = positionArray[3];
        const startZ = positionArray[2];
        const endZ = positionArray[5];
        const xCoord = (startX - (startX - endX) / 2).toFixed(2);
        const zCoord = (startZ - (startZ - endZ) / 2).toFixed(2);
        if (v.subVectors(camera.position, new Vector3(xCoord, 0, zCoord)).dot(this.wallNormals[geometry.wallIndex]) > 0) {
          geometry.setDrawRange(0, 0);
        }
      };
      const onAfterRender = (renderer, scene, camera, geometry, material, group) => {
        geometry.setDrawRange(0, Infinity);
      };

      outerWall.onBeforeRender = onBeforeRender;
      outerWall.onAfterRender = onAfterRender;

      this.wallGroup.add(wall);
    });

    this.wallGroup.children.forEach(wall => {
      wall.positiveDirection = this.isWallPositiveDirection(wall);
    });
  };

  // refactor this part
  makeMask = () => {
    this.holesShape.moveTo(0, 0).setFromPoints(Walls.outerMaskPoints);
    const concreteMesh = new Mesh(new ShapeBufferGeometry(this.holesShape), Walls.materialConcrete);
    concreteMesh.rotation.x = -Math.PI / 2;
    concreteMesh.position.setY(-0.01);

    this.holesShape.holes.push(new Path().moveTo(0, 0).setFromPoints(this.maskHolePoints));
    const maskMesh = new Mesh(new ShapeBufferGeometry(this.holesShape), Walls.materialMask);
    maskMesh.rotation.x = -Math.PI / 2;
    maskMesh.rotation.z = -Math.PI / 2;
    maskMesh.position.setY(0.01);
    this.mask.add(maskMesh);
    this.concrete.add(concreteMesh);
  };

  drawMask = () => {
    // this.mask.children[0].geometry.setFromPoints(this.maskHolePoints);
    // this.holesShape.holes[0] = new Path().moveTo( 0, 0 ).setFromPoints(this.maskHolePoints);
    this.mask.children[0].geometry.parameters.shapes.holes[0] = new Path().moveTo(0, 0).setFromPoints(this.maskHolePoints);

    const maskShape = new Shape().moveTo(0, 0).setFromPoints(Walls.outerMaskPoints);
    maskShape.holes.push(new Path().moveTo(0, 0).setFromPoints(this.maskHolePoints));
    const maskMesh = new Mesh(new ShapeBufferGeometry(this.holesShape), Walls.materialMask);
    maskMesh.rotation.x = -Math.PI / 2;
    maskMesh.rotation.z = -Math.PI / 2;
    maskMesh.position.setY(0.01);
    // let testmaskMesh = new Mesh(new ShapeBufferGeometry(maskShape), Walls.materialCircle);
    // testmaskMesh.rotation.x = Math.PI / 2;
    // testmaskMesh.position.setY(0.01);
    this.mask.children[0] = maskMesh;
  };
  // refactor this part

  makeDimensionComponents = () => {
    this.wallGroup.children.forEach((wall, index) => {
      this.makeDimensionComponent(wall, index);
    });
  };

  makeDimensionComponent = (wall, index) => {
    const componentWidthHalf = 0.5;
    const componentHeightHalf = componentWidthHalf / 4;
    let testZ = -0.5;

    if (wall.orientationRow && !wall.positiveDirection) {
      testZ = 0.5;
    }

    const geom = new BufferGeometry().setAttribute(
      'position',
      new Float32BufferAttribute(
        [
          -componentWidthHalf, 0.01, -componentHeightHalf + testZ,
          componentWidthHalf, 0.01, componentHeightHalf + testZ,
          componentWidthHalf, 0.01, -componentHeightHalf + testZ,
          -componentWidthHalf, 0.01, -componentHeightHalf + testZ,
          -componentWidthHalf, 0.01, componentHeightHalf + testZ,
          componentWidthHalf, 0.01, componentHeightHalf + testZ
        ],
        3
      )
    );

    geom.setAttribute(
      'uv',
      new Float32BufferAttribute(
        [
          0, 1,
          1, 0,
          1, 1,
          0, 1,
          0, 0,
          1, 0
        ],
        2
      )
    );
    const dimensionWidth = 512;
    const tempDynamicTexture = new DynamicTexture(dimensionWidth, dimensionWidth / 4);
    tempDynamicTexture.context.font = '120px Verdana';
    tempDynamicTexture.texture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
    const dimensionMesh = new MeshBasicMaterial({ blending: MultiplyBlending });
    // dimensionMesh.depthTest = false;
    // dimensionMesh.depthWrite = false;
    // dimensionMesh.frustumculled = false;
    // dimensionMesh.renderOrder = 999;
    // dimensionMesh.onBeforeRender = function (renderer) { renderer.clearDepth(); };
    const dimensions = new Mesh(geom, dimensionMesh);
    dimensions.dynamicTexture = tempDynamicTexture;
    this.dimensionComponents.add(dimensions);
    this.updateDimension(index);
  };

  updateDimension = (index) => {
    const startWallPoint = this.wallCornerPoints.children[index];
    const nextPoint = this.wallCornerPoints.children[getNextIndex(index, this.wallCornerPoints.children.length)];
    const dimensionAngle = -Math.atan((nextPoint.position.z - startWallPoint.position.z) / (nextPoint.position.x - startWallPoint.position.x));
    const lengthMetric = Math.sqrt((nextPoint.position.z - startWallPoint.position.z) ** 2 + (nextPoint.position.x - startWallPoint.position.x) ** 2);
    const dimensionText = this.metric ? `${lengthMetric.toFixed(2)}m` : convertToImperial(lengthMetric);

    this.dimensionComponents.children[index].position.set(
      (startWallPoint.position.x + nextPoint.position.x) / 2,
      startWallPoint.position.y,
      (startWallPoint.position.z + nextPoint.position.z) / 2
    );
    this.dimensionComponents.children[index].setRotationFromAxisAngle(new Vector3(0, 1, 0), dimensionAngle);
    this.dimensionComponents.children[index].dynamicTexture.clear('#ffffff').drawText(dimensionText, undefined, 104, '#555555');
    this.dimensionComponents.children[index].material.map = this.dimensionComponents.children[index].dynamicTexture.texture;
  };

  setMetricCallBack = (e) => {
    this.setMetric(e.target.checked);
  };

  setMetric = (value) => {
    this.metric = value;
    this.wallGroup.children.forEach((wall, index) => {
      this.updateDimension(index);
    });

    this.wallObjects.children.forEach(wallObject => {
      this.updateDistanceToWall(wallObject);
    });
  }

  updateWall = (wall) => {
    wall.orientationRow = this.isWallOrientationRow(
      this.wallCornerPoints.children[wall.index],
      this.wallCornerPoints.children[getNextIndex(wall.index, this.wallCornerPoints.children.length)]
    );
    wall.positiveDirection = this.isWallPositiveDirection(wall);
  };

  drawWalls = () => {
    this.wallGroup.children.forEach(wall => {
      this.drawWall(wall);
    });
  }

  drawWall = (wall) => {
    const currentIndex = wall.index;
    const nextIndex = getNextIndex(currentIndex, this.wallCornerPoints.children.length);
    const startPoint = this.wallCornerPoints.children[currentIndex].position;
    const endPoint = this.wallCornerPoints.children[nextIndex].position;
    const startOuterPoint = this.wallOuterPoints[currentIndex].position;
    const endOuterPoint = this.wallOuterPoints[nextIndex].position;
    const startInnerPoint = this.wallInnerPoints[currentIndex].position;
    const endInnerPoint = this.wallInnerPoints[nextIndex].position;
    const startFarInnerPoint = this.wallFarInnerPoints[currentIndex].position;
    const endFarInnerPoint = this.wallFarInnerPoints[nextIndex].position;
    const yCoord = startPoint.y - 0.01;
    const wallHeight = yCoord + 2.5;

    this.moveWallObjectsOnWallResize(this.wallObjects, wall, startPoint, endPoint);
    this.moveWallObjectsOnWallResize(this.addedOtherObjects, wall, startPoint, endPoint);

    wall.geometry.setAttribute(
      'position',
      new Float32BufferAttribute(
        [
          startPoint.x, yCoord, startPoint.z,
          endPoint.x, yCoord, endPoint.z,
          endOuterPoint.x, yCoord, endOuterPoint.z,

          startPoint.x, yCoord, startPoint.z,
          endOuterPoint.x, yCoord, endOuterPoint.z,
          startOuterPoint.x, yCoord, startOuterPoint.z
        ], 3
      )
    );
    // wall.positiveDirection = this.isWallPositiveDirection(wall);

    this.edgeHandles.children[currentIndex].geometry.setAttribute(
      'position',
      new Float32BufferAttribute(
        [
          startInnerPoint.x, yCoord, startInnerPoint.z,
          endFarInnerPoint.x, yCoord, endFarInnerPoint.z,
          endInnerPoint.x, yCoord, endInnerPoint.z,

          startInnerPoint.x, yCoord, startInnerPoint.z,
          startFarInnerPoint.x, yCoord, startFarInnerPoint.z,
          endFarInnerPoint.x, yCoord, endFarInnerPoint.z
        ], 3
      )
    );

    this.edgeHandles.children[currentIndex].children[0].geometry.setAttribute(
      'position',
      new Float32BufferAttribute(
        [
          startPoint.x, yCoord, startPoint.z,
          endInnerPoint.x, yCoord, endInnerPoint.z,
          endPoint.x, yCoord, endPoint.z,

          startPoint.x, yCoord, startPoint.z,
          startInnerPoint.x, yCoord, startInnerPoint.z,
          endInnerPoint.x, yCoord, endInnerPoint.z
        ], 3
      )
    );

    this.innerWalls.children[currentIndex].geometry.setAttribute(
      'position',
      new Float32BufferAttribute(
        [
          startPoint.x, wallHeight, startPoint.z,
          endPoint.x, yCoord, endPoint.z,
          endPoint.x, wallHeight, endPoint.z,

          startPoint.x, wallHeight, startPoint.z,
          startPoint.x, yCoord, startPoint.z,
          endPoint.x, yCoord, endPoint.z
        ], 3
      )
    );

    this.wall3DComponentGroup.children[currentIndex].geometry.setAttribute(
      'position',
      new Float32BufferAttribute(
        [
          startOuterPoint.x, yCoord, startOuterPoint.z,
          endOuterPoint.x, yCoord, endOuterPoint.z,
          endOuterPoint.x, wallHeight, endOuterPoint.z,

          startOuterPoint.x, yCoord, startOuterPoint.z,
          endOuterPoint.x, wallHeight, endOuterPoint.z,
          startOuterPoint.x, wallHeight, startOuterPoint.z,

          // corner connection
          startOuterPoint.x, yCoord, startOuterPoint.z,
          startPoint.x, yCoord, startPoint.z,
          startPoint.x, wallHeight, startPoint.z,
          startOuterPoint.x, yCoord, startOuterPoint.z,
          startPoint.x, wallHeight, startPoint.z,
          startOuterPoint.x, wallHeight, startOuterPoint.z,

          endPoint.x, yCoord, endPoint.z,
          endOuterPoint.x, yCoord, endOuterPoint.z,
          endOuterPoint.x, wallHeight, endOuterPoint.z,
          endPoint.x, yCoord, endPoint.z,
          endOuterPoint.x, wallHeight, endOuterPoint.z,
          endPoint.x, wallHeight, endPoint.z,
          // corner connection

          // top piece
          startPoint.x, wallHeight, startPoint.z,
          endPoint.x, wallHeight, endPoint.z,
          endOuterPoint.x, wallHeight, endOuterPoint.z,
          startPoint.x, wallHeight, startPoint.z,
          endOuterPoint.x, wallHeight, endOuterPoint.z,
          startOuterPoint.x, wallHeight, startOuterPoint.z
          // top piece
        ], 3
      )
    );

    wall.geometry.computeBoundingSphere();
    this.innerWalls.children[currentIndex].geometry.computeBoundingSphere();
    this.edgeHandles.children[currentIndex].geometry.computeBoundingSphere();
    this.edgeHandles.children[currentIndex].children[0].geometry.computeBoundingSphere();
    this.wall3DComponentGroup.children[currentIndex].geometry.computeBoundingSphere();
    this.centerPoints(wall);
  }

  setInnerWallColor = (color) => {
    Walls.materialWallInner.color = new Color(color);
  };

  setWall3DElementsVisible = (visible) => {
    if (!this.pads) {
      this.wall3DComponentGroup.visible = visible;
      this.innerWalls.visible = visible;
    }
  };

  setPads = (value) => {
    this.setWall3DElementsVisible(!value);
    this.wallCornerPoints.visible = !value;
    // this.mask.visible = !value;
    this.pads = value;
    Walls.materialCircle.map = value ? Walls.textureArrowPads : Walls.textureArrow;
  }

  getWallElementPosition = () => { // model) => {
    const startWall = 0;
    const firstWallGeometryPoints = this.wallGroup.children[startWall].geometry.attributes.position.array;
    const x1 = firstWallGeometryPoints[0];
    const z1 = firstWallGeometryPoints[2];
    const x2 = firstWallGeometryPoints[3];
    const z2 = firstWallGeometryPoints[5];
    const k = (z2 - z1) / (x2 - x1);
    let testAngle = 0;
    if (x2 < x1) {
      testAngle = Math.PI;
    } else {
      testAngle = 0;
    }

    const position = {
      x: (x1 + x2) / 2,
      z: (z1 + z2) / 2,
      rotationAxis: new Vector3(0, 1, 0),
      angle: -Math.atan(k) + testAngle,
      wallIndex: startWall
    };

    return position;
  }

  setWallElementsVisible = (visible) => {
    this.wallObjects.visible = visible;
  }

  moveWall = (wall, cornerFrom, cornerTo) => {
    const prevIndex = getPreviousIndex(wall.index, this.wallGroup.children.length);
    const nextIndex = getNextIndex(wall.index, this.wallCornerPoints.children.length);
    const nextNextIndex = getNextIndex(nextIndex, this.wallCornerPoints.children.length);

    const testMe = this.wallCornerPoints.children[nextNextIndex];
    const testMe2 = this.wallCornerPoints.children[prevIndex];
    const minWallLength = this.floor.tileSize - 0.001;
    if (Math.sqrt((cornerTo.position.x - cornerFrom.position.x) ** 2 + (cornerTo.position.z - cornerFrom.position.z) ** 2) < minWallLength) return;
    if (Math.sqrt((testMe.position.x - cornerTo.position.x) ** 2 + (testMe.position.z - cornerTo.position.z) ** 2) < minWallLength) return;
    if (Math.sqrt((cornerFrom.position.x - testMe2.position.x) ** 2 + (cornerFrom.position.z - testMe2.position.z) ** 2) < minWallLength) return;

    this.moveCornerPoint(cornerFrom, wall.index);
    this.moveCornerPoint(cornerTo, nextIndex);

    this.makeWallOuterPoints(prevIndex);
    this.makeWallOuterPoints(wall.index);
    this.makeWallOuterPoints(nextIndex);
    this.makeWallOuterPoints(nextNextIndex);

    this.moveWallMiddlePoint(prevIndex);
    this.moveWallMiddlePoint(wall.index);
    this.moveWallMiddlePoint(nextIndex);

    this.updateDimension(prevIndex);
    this.updateDimension(wall.index);
    this.updateDimension(nextIndex);
    this.drawWalls();
    this.drawMask();
  };

  moveCornerPoint = (newPoint, index) => {
    this.wallCornerPoints.children[index].position.set(
      newPoint.position.x,
      newPoint.position.y,
      newPoint.position.z
    );
  };

  moveWallMiddlePoint = (index) => {
    const nextIndex = getNextIndex(index, this.wallCornerPoints.children.length);
    const startPointPosition = this.wallCornerPoints.children[index].position;
    const endPointPosition = this.wallCornerPoints.children[nextIndex].position;
    this.wallMiddlePoints.children[index].position.set(
      (startPointPosition.x + endPointPosition.x) / 2,
      this.wallMiddlePoints.children[index].position.y,
      (startPointPosition.z + endPointPosition.z) / 2
    );
  };

  moveWallObjectsOnWallResize = (wallObjects, wall, startPoint, endPoint) => {
    const newObjectPosition = new Vector3();
    let collision = 0;
    wallObjects.children.forEach(wallObject => {
      if (wallObject.index !== wall.index) return;
      const objectPosition = new Vector3();
      wallObject.getWorldPosition(objectPosition);
      const coords = wall.geometry.attributes.position.array;
      const x1 = coords[0];
      const z1 = coords[2];
      const x2 = coords[3];
      const z2 = coords[5];
      const k = (z2 - z1) / (x2 - x1);

      let ratio;
      if (x1 !== x2) {
        const dX = Math.abs(objectPosition.x - x1);
        ratio = dX / Math.abs(x2 - x1);
      } else {
        const dZ = Math.abs(objectPosition.z - z1);
        ratio = dZ / Math.abs(z2 - z1);
      }
      newObjectPosition.x = startPoint.x + (endPoint.x - startPoint.x) * ratio;
      newObjectPosition.z = startPoint.z + (endPoint.z - startPoint.z) * ratio;
      collision = this.objectCollision(wallObject, newObjectPosition);
      if (collision) {
        if (x1 === x2) {
          newObjectPosition.z += collision;
        } else if (z1 === z2) {
          newObjectPosition.x += collision;
        } else {
          newObjectPosition.x += collision / k;
          newObjectPosition.z += collision * k;
        }
      }
      this.moveWallObject(wallObject, wall, newObjectPosition, wallObjects, true);
    });
  }

  moveWallObject = (object, wall, point, objectGroup, remove = false) => {
    const oldWallIndex = object.index;
    object.index = wall.index;
    point.x = +point.x.toFixed(2);
    point.z = +point.z.toFixed(2);
    const colisionTest = this.objectCollision(object, point);
    if (colisionTest) {
      object.index = oldWallIndex;
      return;
    }

    const [x1, z1, x2, z2] = this.getWallCornersWorldCoords(wall);
    const k = (z2 - z1) / (x2 - x1);
    const a1 = point.x - x1;
    const b1 = point.z - z1;
    const c1 = Math.sqrt(a1 ** 2 + b1 ** 2) - object.size.x / 2;
    const a2 = point.x - x2;
    const b2 = point.z - z2;
    const c2 = Math.sqrt(a2 ** 2 + b2 ** 2) - object.size.x / 2;
    let testAngle = 0;
    if (c1 < 0 || c2 < 0) {
      object.index = oldWallIndex;
      remove && objectGroup.remove(object);
      return;
    }

    if (x2 < x1) {
      testAngle = Math.PI;
    } else {
      testAngle = 0;
    }

    const rotationAxis = new Vector3(0, 1, 0);
    const angle = -Math.atan(k) + testAngle;

    this.updateDistanceToWall(object);

    object.positionParam.wallIndex = wall.index;
    object.positionParam.x = point.x;
    object.positionParam.z = point.z;
    object.positionParam.rotationAxis = rotationAxis;
    object.positionParam.angle = angle;

    object.normal = new Vector3(z2 - z1, 0, x1 - x2).normalize();
    object.position.setX(point.x);
    object.position.setZ(point.z);
    object.setRotationFromAxisAngle(rotationAxis, angle);
  }

  updateDistanceToWall = (object) => {
    const wallIndex = object.index;
    const [x1, z1, x2, z2] = this.getWallCornersWorldCoords(this.wallGroup.children[wallIndex]);
    const a1 = object.position.x - x1;
    const b1 = object.position.z - z1;
    const c1 = Math.sqrt(a1 ** 2 + b1 ** 2) - object.size.x / 2;
    const a2 = object.position.x - x2;
    const b2 = object.position.z - z2;
    const c2 = Math.sqrt(a2 ** 2 + b2 ** 2) - object.size.x / 2;

    object.traverse((child) => {
      if (child.name === 'leftDistance') {
        const points = child.children[0].geometry.attributes.position.array;
        points[0] = -c1 - object.size.x / 2;
        points[9] = -c1 - object.size.x / 2;
        points[15] = -c1 - object.size.x / 2;
        child.children[0].geometry.setAttribute(
          'position',
          new Float32BufferAttribute(points, 3)
        );

        const dimensionText = this.metric ? `${c1.toFixed(2)}m` : convertToImperial(c1);
        child.dynamicTexture.clear('#ffffff').drawText(dimensionText, undefined, 104, '#555555');
        child.material.map = child.dynamicTexture.texture;
      } else if (child.name === 'rightDistance') {
        const points = child.children[0].geometry.attributes.position.array;
        points[3] = c2 + object.size.x / 2;
        points[6] = c2 + object.size.x / 2;
        points[12] = c2 + object.size.x / 2;
        child.children[0].geometry.setAttribute(
          'position',
          new Float32BufferAttribute(points, 3)
        );

        const dimensionText = this.metric ? `${c2.toFixed(2)}m` : convertToImperial(c2);
        child.dynamicTexture.clear('#ffffff').drawText(dimensionText, undefined, 104, '#555555');
        child.material.map = child.dynamicTexture.texture;
      }
    });
  }

  objectCollision = (object, newPosition) => {
    const collisionWallElements = this.wallObjectCollision(object, newPosition);
    const collisionOtherWallElements = this.wallObjectCollision(object, newPosition, this.addedOtherObjects);

    return Math.max(collisionWallElements, collisionOtherWallElements);
  }

  wallObjectCollision = (object, newPosition, objects = this.wallObjects) => {
    let collision = 0;
    const wallObjectPosition = new Vector3();
    objects.children.forEach(wallObject => {
      if (
        wallObject !== object &&
        wallObject.index === object.index &&
        (
          (wallObject.bottomColision === true && object.bottomColision === true) ||
          (wallObject.topColision === true && object.topColision === true)
        )
      ) {
        wallObject.getWorldPosition(wallObjectPosition);
        const a = wallObjectPosition.x - newPosition.x;
        const b = wallObjectPosition.z - newPosition.z;
        const c = Math.sqrt(a ** 2 + b ** 2);
        if (c < object.size.x / 2 + wallObject.size.x / 2) {
          collision = object.size.x / 2 + wallObject.size.x / 2 - c;
        }
      }
    });
    return collision;
  }

  edgesPresent = () => {
    let edgesPresent = false;
    this.wallGroup.children.forEach(wall => {
      wall.hasEdges && (edgesPresent = true);
    });
    return edgesPresent;
  }

  addEdgesToAllWalls = () => {
    this.wallGroup.children.forEach(wall => {
      this.addEdgesToWall(wall);
    });
  }

  addEdgesToWall = (wall, replaceEdges = true) => {
    if (wall.orientationRow === undefined || wall.hasEdges) return;
    const nextIndex = getNextIndex(wall.index, this.wallGroup.children.length);
    const previousIndex = getPreviousIndex(wall.index, this.wallGroup.children.length);
    const prevWall = this.wallGroup.children[previousIndex];
    const nextWall = this.wallGroup.children[nextIndex];

    let wallsWithEdges = 0;
    let wallWithEdgeIndex = wall.index;
    this.wallGroup.children.forEach(wallLoop => {
      if (wallLoop.hasEdges) {
        ++wallsWithEdges;
        wallWithEdgeIndex = wallLoop.index;
      }
    });

    if (wallsWithEdges === 0) {
      this.moveFloorToWall(wall, true);
      const wallProps = this.getWallProps(wall);
      let alignWithRow = false;
      if (nextWall.orientationRow === undefined) {
        alignWithRow = prevWall;
      } else if (prevWall.orientationRow === undefined) {
        alignWithRow = nextWall;
      }
      if (nextWall.orientationRow !== undefined && prevWall.orientationRow !== undefined) {
        if (wall.orientationRow === (wallProps.startIndex < wallProps.endIndex)) {
          alignWithRow = nextWall;
        } else {
          alignWithRow = prevWall;
        }
      }
      alignWithRow && this.moveFloorToWall(alignWithRow);
    } else if (wallsWithEdges === 1) {
      (wallWithEdgeIndex === previousIndex || wallWithEdgeIndex === nextIndex) && this.moveFloorToWall(wall, true);
    }

    const wallProps = this.getWallProps(wall);
    const tiles = this.floor.tiles;
    const edgeSize = this.floor.edgeSize;

    let distanceToWall = this.getDistanceToWall(wall, tiles.children[wallProps.startIndex + wallProps.innerTileRelativeIndex + 2 * wallProps.nextTileRelativeIndex]).toFixed(2);
    // distanceToWall = +(+distanceToWall).toFixed(4);
    distanceToWall = Math.abs(distanceToWall);

    if (distanceToWall < edgeSize && distanceToWall > -edgeSize) {
      for (let i = wallProps.startIndex; i !== wallProps.endIndex + wallProps.nextTileRelativeIndex; i += wallProps.nextTileRelativeIndex) {
        if (!tiles.children[i + wallProps.innerTileRelativeIndex].visible || !tiles.children[i - wallProps.innerTileRelativeIndex].visible) {
          tiles.children[i].visible = false;
          wall.tilesHiddenBecauseOfEdge.push(i);
        }
      }
      wallProps.startIndex += wallProps.innerTileRelativeIndex;
      wallProps.endIndex += wallProps.innerTileRelativeIndex;
      distanceToWall += this.floor.tileSize;
    }

    let moveWallHandleAmount = distanceToWall - edgeSize - 0.01;
    !wall.positiveDirection && (moveWallHandleAmount *= -1);

    if (wall.orientationRow) {
      this.wallInnerPoints[wall.index].position.z += moveWallHandleAmount;
      this.wallInnerPoints[wall.index].position.movementZ += moveWallHandleAmount;
      this.wallInnerPoints[nextIndex].position.z += moveWallHandleAmount;
      this.wallInnerPoints[nextIndex].position.movementZ += moveWallHandleAmount;
    } else {
      this.wallInnerPoints[wall.index].position.x += moveWallHandleAmount;
      this.wallInnerPoints[wall.index].position.movementX += moveWallHandleAmount;
      this.wallInnerPoints[nextIndex].position.x += moveWallHandleAmount;
      this.wallInnerPoints[nextIndex].position.movementX += moveWallHandleAmount;
    }
    this.drawWall(prevWall);
    this.drawWall(wall);
    this.drawWall(nextWall);

    let nextSideTile, prevSideTile, replaceWith;

    for (let index = wallProps.startIndex; index !== wallProps.endIndex + wallProps.nextTileRelativeIndex; index += wallProps.nextTileRelativeIndex) {
      nextSideTile = tiles.children[index + wallProps.innerTileRelativeIndex];
      prevSideTile = tiles.children[index - wallProps.innerTileRelativeIndex];
      wall.tileEdgeIndexes.add(index);
      if ((!(nextSideTile instanceof Tile) && nextSideTile.visible) ||
        (!(prevSideTile instanceof Tile) && prevSideTile.visible)) {
        Tile.changedMaterials.delete(tiles.children[index]);
        if ((index === wallProps.startIndex || index === wallProps.startIndex + wallProps.nextTileRelativeIndex) &&
          index !== wallProps.endIndex) {
          replaceWith = this.floor.convertToCorner(index, prevWall, wall);
          prevWall.tileEdgeIndexes.add(index);
        } else {
          replaceWith = this.floor.convertToCorner(index, wall, nextWall);
          nextWall.tileEdgeIndexes.add(index);
        }
      } else if (tiles.children[index] instanceof Tile) {
        Tile.changedMaterials.delete(tiles.children[index]);
        replaceWith = new Edge(index, this.floor.tileSize, edgeSize, wallProps.orientationRow, wallProps.positiveDirection);
        !tiles.children[index].visible && (replaceWith.visible = false);
      } else {
        console.log('menjam u ivicu, nije bio tile, nema ivice susedno, verovatno je ok nastavljam');
        continue;
      }
      replaceWith.position.setFromMatrixPosition(tiles.children[index].matrixWorld);
      replaceWith.updateMatrixWorld();
      tiles.children[index] = replaceWith;
    }

    this.checkTileOutsideWall(wall, wallProps);

    wall.hasEdges = true;
    wall.userPlacedEdges = true;
    this.edgeHandles.children[wall.index].hasEdges = true;
    this.edgeHandles.children[wall.index].children[0].hasEdges = true;
    this.edgeHandles.children[wall.index].children[0].material = Walls.materialEdgeHandleRemove;
    this.setEdgeHandlesState(this.edgeHandlesState);

    if ((wallWithEdgeIndex === previousIndex || wallWithEdgeIndex === nextIndex) && wallsWithEdges === 1 && replaceEdges) {
      const wallWithEdge = this.wallGroup.children[wallWithEdgeIndex];
      this.removeEdgesfromWall(wallWithEdge, false, false);
      this.addEdgesToWall(wallWithEdge, false);
    }
  };

  removeEdgesfromWall = (wall, validateTilesBool = false, replaceEdges = true) => {
    let replaceWith;
    let newWallIndex;
    const wallIndex = wall.index;
    let belongsToWalls = 0;
    const nextWallIndex = getNextIndex(wallIndex, this.wallCornerPoints.children.length);
    const prevWallIndex = getPreviousIndex(wallIndex, this.wallCornerPoints.children.length);
    const tileEdgeIndexesArray = Array.from(wall.tileEdgeIndexes).sort((a, b) => a - b);
    const middleTileIndex = tileEdgeIndexesArray[Math.ceil((tileEdgeIndexesArray.length - 1) / 2)];

    const tilesToExclude = [];
    this.wallGroup.children.forEach(wallLoop => {
      wall.index !== wallLoop.index && tilesToExclude.push(...wallLoop.tilesHiddenBecauseOfEdge);
    });

    let newColor;
    let tile;
    wall.tileEdgeIndexes.forEach(index => {
      tile = this.floor.tiles.children[index];
      belongsToWalls = 0;
      newColor = this.floor.patternIndexAndColor[index];
      if (tile instanceof Corner || tile instanceof InnerCorner) {
        tile instanceof Corner
          ? Corner.changedCornerColors.delete(tile)
          : InnerCorner.changedInnerCornerColors.delete(tile);
        this.wallGroup.children.forEach(wall => {
          wall.tileEdgeIndexes.has(index) && (++belongsToWalls);
        });

        if (belongsToWalls < 2) {
          replaceWith = new Tile(index, this.floor.tileSize);
          newColor && replaceWith.changeColor(newColor);
        } else {
          if (wall.orientationRow === wall.positiveDirection) {
            newWallIndex = index < middleTileIndex ? prevWallIndex : nextWallIndex;
            tileEdgeIndexesArray[0] === index && (newWallIndex = prevWallIndex);
            tileEdgeIndexesArray[tileEdgeIndexesArray.length - 1] === index && (newWallIndex = nextWallIndex);
          } else {
            newWallIndex = !(index < middleTileIndex) ? prevWallIndex : nextWallIndex;
            tileEdgeIndexesArray[0] === index && (newWallIndex = nextWallIndex);
            tileEdgeIndexesArray[tileEdgeIndexesArray.length - 1] === index && (newWallIndex = prevWallIndex);
          }

          const newWall = this.wallGroup.children[newWallIndex];
          const newWallProps = this.getWallProps(newWall);
          newWallProps.startIndex > newWallProps.endIndex && ([newWallProps.startIndex, newWallProps.endIndex] = [newWallProps.endIndex, newWallProps.startIndex]);

          if (
            (index >= newWallProps.startIndex && index <= newWallProps.endIndex) ||
            (index >= newWallProps.startIndex + newWallProps.innerTileRelativeIndex && index <= newWallProps.endIndex + newWallProps.innerTileRelativeIndex)
          ) {
            replaceWith = new Edge(
              index,
              this.floor.tileSize,
              this.floor.edgeSize,
              newWall.orientationRow,
              newWall.positiveDirection
            );
          } else {
            replaceWith = new Tile(index, this.floor.tileSize);
            newColor && replaceWith.changeColor(newColor);
          }
        }
      } else {
        Edge.changedEdgeColors.delete(tile);
        replaceWith = new Tile(index, this.floor.tileSize);
        newColor && replaceWith.changeColor(newColor);
      }
      replaceWith.position.setFromMatrixPosition(tile.matrixWorld);
      tilesToExclude.includes(index) && (replaceWith.visible = false);
      this.floor.tiles.children[index] = replaceWith;
    });

    wall.tileEdgeIndexes.clear();
    wall.tilesHiddenBecauseOfEdge = [];
    wall.hasEdges = false;
    this.edgeHandles.children[wall.index].hasEdges = false;
    this.edgeHandles.children[wall.index].children[0].hasEdges = false;
    this.edgeHandles.children[wall.index].children[0].material = Walls.materialEdgeHandleAdd;

    let wallsWithEdges = 0;
    let moveToWall = false;
    this.wallGroup.children.forEach(wall => {
      if (wall.hasEdges) {
        ++wallsWithEdges;
        moveToWall = wall;
      }
    });

    validateTilesBool && this.floor.validateTiles(this.wallGroup, this.edgeHandles);

    const wallInnerPoint = this.wallInnerPoints[wallIndex];
    const nextWallInnerPoint = this.wallInnerPoints[nextWallIndex];

    if (wall.orientationRow) {
      wallInnerPoint.position.z -= wallInnerPoint.position.movementZ;
      wallInnerPoint.position.movementZ = 0;
      nextWallInnerPoint.position.z -= nextWallInnerPoint.position.movementZ;
      nextWallInnerPoint.position.movementZ = 0;
    } else {
      wallInnerPoint.position.x -= wallInnerPoint.position.movementX;
      wallInnerPoint.position.movementX = 0;
      nextWallInnerPoint.position.x -= nextWallInnerPoint.position.movementX;
      nextWallInnerPoint.position.movementX = 0;
    }

    this.drawWall(this.wallGroup.children[prevWallIndex]);
    this.drawWall(wall);
    this.drawWall(this.wallGroup.children[nextWallIndex]);
    if (wallsWithEdges === 1 && replaceEdges) {
      this.removeEdgesfromWall(moveToWall, false, false);
      this.addEdgesToWall(moveToWall, false);
    } else if (wallsWithEdges === 0 && replaceEdges && this.floor.tilesAdded) {
      this.moveFloorToWall(this.wallGroup.children[0]);
      this.moveFloorToWall(this.wallGroup.children[1]);
    }
  };

  checkTileOutsideWall = (wall, wallProps = undefined) => {
    wallProps === undefined && (wallProps = this.getWallProps(wall));
    let replaceWith;
    const tiles = this.floor.tiles;
    let tileIndexToChange = wallProps.startIndex - wallProps.nextTileRelativeIndex;
    const nextWall = this.wallGroup.children[getNextIndex(wall.index, this.wallGroup.children.length)];
    const prevWall = this.wallGroup.children[getPreviousIndex(wall.index, this.wallGroup.children.length)];

    if (tiles.children[wallProps.startIndex] instanceof Edge) {
      if (tiles.children[tileIndexToChange + wallProps.innerTileRelativeIndex] instanceof EdgePiece ||
          tiles.children[tileIndexToChange - wallProps.innerTileRelativeIndex] instanceof EdgePiece
      ) {
        replaceWith = this.floor.convertToCorner(tileIndexToChange, prevWall, wall);
        replaceWith.position.setFromMatrixPosition(tiles.children[tileIndexToChange].matrixWorld);
        tiles.children[tileIndexToChange] = replaceWith;
        wall.tileEdgeIndexes.add(tileIndexToChange);
        prevWall.tileEdgeIndexes.add(tileIndexToChange);
      }
    }

    tileIndexToChange = wallProps.endIndex + wallProps.nextTileRelativeIndex;
    if (tiles.children[wallProps.endIndex] instanceof Edge) {
      if (tiles.children[tileIndexToChange + wallProps.innerTileRelativeIndex] instanceof EdgePiece ||
          tiles.children[tileIndexToChange - wallProps.innerTileRelativeIndex] instanceof EdgePiece
      ) {
        replaceWith = this.floor.convertToCorner(tileIndexToChange, wall, nextWall);
        replaceWith.position.setFromMatrixPosition(tiles.children[tileIndexToChange].matrixWorld);
        tiles.children[tileIndexToChange] = replaceWith;
        wall.tileEdgeIndexes.add(tileIndexToChange);
        nextWall.tileEdgeIndexes.add(tileIndexToChange);
      }
    }
  };

  isWallPositiveDirection = (wall) => {
    const coords = wall.geometry.attributes.position.array;
    const x1 = coords[0];
    const x2 = coords[3];
    const z1 = coords[2];
    const z2 = coords[5];
    const ratio = (x2 - x1) / (z2 - z1);
    const xMiddlePoint = (x1 + x2) / 2;
    const zMiddlePoint = (z1 + z2) / 2;
    const moveAmount = 0.2;
    const moveAmountAngledWall = Math.sqrt(moveAmount ** 2 / (1 + ratio ** 2));

    // if (isFinite(ratio) && ratio !== 0) return undefined;
    const zDirection = x2 > x1 ? -1 : 1;
    const xDirection = z2 > z1 ? 1 : -1;

    let pointToCheck;
    isFinite(ratio) // if infinite it is a horizontal wall
      ? ratio === 0 // if 0 it is a vertical wall
          ? pointToCheck = { x: xMiddlePoint + moveAmount, z: zMiddlePoint }
          : pointToCheck = { x: xMiddlePoint + moveAmountAngledWall * xDirection, z: zMiddlePoint + moveAmountAngledWall * ratio * zDirection } // angled wall
      : pointToCheck = { x: xMiddlePoint, z: zMiddlePoint + moveAmount };

    return this.floor.pointInWalls(pointToCheck, this.edgeHandles);
  };

  isWallOrientationRow = (cornerFrom, cornerTo) => {
    let orientationRow;

    Math.abs(cornerFrom.position.x - cornerTo.position.x) < 0.001
      ? orientationRow = false
      : Math.abs(cornerFrom.position.z - cornerTo.position.z) < 0.001
        ? orientationRow = true
        : orientationRow = undefined;

    this.edgeHandles.children[cornerFrom.index].visible = orientationRow !== undefined;
    return orientationRow;
  };

  getDistanceToWall = (wall, tile, parallelToWall = true) => {
    const [x1, z1] = this.getWallCornersWorldCoords(wall);
    const nextInnerRowDistance = new Vector3();
    tile.getWorldPosition(nextInnerRowDistance);

    let returnValue = (wall.orientationRow === parallelToWall) ? z1 - nextInnerRowDistance.z : x1 - nextInnerRowDistance.x;
    !wall.positiveDirection && (returnValue -= this.floor.tileSize);

    return returnValue;
    // return returnValue.toFixed(6);
  };

  getWallCornersWorldCoords = (wall) => {
    const points = wall.geometry.attributes.position.array;
    const wallWorldPosition = new Vector3();
    wall.getWorldPosition(wallWorldPosition);

    return [
      points[0] + wallWorldPosition.x,
      points[2] + wallWorldPosition.z,
      points[3] + wallWorldPosition.x,
      points[5] + wallWorldPosition.z
    ];
  };

  moveFloorToWall = (wall, makeRoomForEdge = false) => {
    if (wall.orientationRow === undefined) return;
    const wallProps = this.getWallProps(wall);
    let distanceToWall = this.getDistanceToWall(wall, this.floor.tiles.children[wallProps.startIndex + 2 * wallProps.nextTileRelativeIndex]);

    if (makeRoomForEdge) {
      if (wallProps.positiveDirection) {
        // distanceToWall = +distanceToWall - (this.floor.tileSize - this.floor.edgeSize);
        distanceToWall -= this.floor.tileSize - this.floor.edgeSize;
      } else {
        // distanceToWall = +distanceToWall + (this.floor.tileSize - this.floor.edgeSize);
        distanceToWall += this.floor.tileSize - this.floor.edgeSize;
      }
    }

    if (distanceToWall > this.floor.tileSize / 2) {
      distanceToWall = distanceToWall - this.floor.tileSize;
    } else if (distanceToWall < -this.floor.tileSize / 2) {
      distanceToWall = this.floor.tileSize + distanceToWall;
    }

    // if (Math.abs(distanceToWall) === this.floor.tileSize) {
    if (Math.abs(distanceToWall.toFixed(4)) === this.floor.tileSize) {
      distanceToWall = 0;
    }

    this.floor.translateFloor(distanceToWall, 0, wallProps.orientationRow);
    this.floor.validateTiles(this.wallGroup, this.edgeHandles);
  };

  getWallProps = (wall) => {
    const orientationRow = wall.orientationRow;
    const positiveDirection = wall.positiveDirection;
    const [x1, z1, x2, z2] = this.getWallCornersWorldCoords(wall);
    const [startIndex, endIndex] = this.floor.getStartEndIndexBetweenCoords(x1, z1, x2, z2);
    let nextTileRelativeIndex = 1;
    let innerTileRelativeIndex = config.numOfTilesPerSide;

    !orientationRow && ([innerTileRelativeIndex, nextTileRelativeIndex] = [nextTileRelativeIndex, innerTileRelativeIndex]);
    !positiveDirection && (innerTileRelativeIndex *= -1);
    positiveDirection !== orientationRow && (nextTileRelativeIndex *= -1);

    return {
      orientationRow,
      positiveDirection,
      x1,
      z1,
      x2,
      z2,
      startIndex,
      endIndex,
      nextTileRelativeIndex,
      innerTileRelativeIndex
    };
  };
}
