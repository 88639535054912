import tileDetail1 from '../images/icons/tiles/ribtraxPro.png';
import tileDetail2 from '../images/icons/tiles/ribtraxProSmooth.png';
import tileDetail3 from '../images/icons/tiles/vinyltraxPro.png';
import tileDetail4 from '../images/icons/tiles/diamondtraxHome.png';
import tileDetail5 from '../images/icons/tiles/diamondtraxHomeFlex.png';
import tileDetail6 from '../images/icons/tiles/ribtraxHomeSmooth.png';
import tileDetail7 from '../images/icons/tiles/vinyltraxHome.png';
import tileDetail8 from '../images/icons/tiles/Truftrax-HighRes.png';

export default {
  projectName: 'SwissTrax',
  pattern: 'Pattern',
  singleTile: 'Single Tile',
  movePattern: 'Move Pattern',

  // nav titles
  edge: 'Edges',
  cart: 'Cart',
  doorsWindows: 'Doors & Windows',
  colors: 'Tiles & Colors',
  furniture: 'Cabinets & Cars',
  shapes: 'Shapes',
  measurments: 'Measurements',
  selectStartingShape: 'Select starting shape',
  walls: 'Walls',
  pads: 'Pads',
  meter: 'Meter',
  feet: 'Feet',
  selectObject: 'Select object',
  cars: 'Cars',
  cabinets: 'Cabinets',
  other: 'Other',
  doors: 'Doors',
  windows: 'Windows',
  load: 'My Saved</br>Designs',
  save: 'Save My</br>Design',
  print: 'Print',
  priceRequest: 'Price Request',
  checkout: 'Checkout',
  edgePopUpColors: 'In order to change edge colors please add some edges first.',
  edgePopUpRemove: 'In order to remove edges please add some edges first.',
  ok: 'OK',
  move: 'Move',
  color: 'Fill',
  single: 'Single',
  wall: 'Wall',
  personalDetailsTitle: 'Please fill out your personal information',
  personalDetailsFName: 'First name',
  personalDetailsLName: 'Last name',
  personalDetailsEmail: 'Email address',
  personalDetailsPhone: 'Phone',
  saveFormTitle: 'Enter the name of your design',
  saveFormInputName: 'Enter the name of your design',
  loadFormTitle: 'Select design to load',
  loadFormDefaultOption: 'None',
  discountFormTitle: 'Select discount',
  discountFormDefaultOption: 'None',
  USStateSelect: 'State',

  changeTilyTypeNotice: 'The tile dimensions have canged, please be sure to check the dimensions of your pad again!',

  succesNoticeResetPass:
  `
    <p>SUCCESS</p>
    An email with further instructions has been sent to the supplied email address.
`,

  succesNoticeSendMail:
  `
    <p>SUCCESS</p>
    Your email has been sent<br />
    A Swisstrax flooring expert will reach out to you within 24-48 hours.<br /><br />
    <span>phone: 1-866-748-7940</span>
  `,

  successNoticePdf:
  `
    <p>SUCCESS</p>
    Your pdf file is ready.
  `,

  errorNotice:
  `
    <p>ERROR</p>
    An unexpected error has occurred. Please try again later.
  `,

  saveNotice:
  `
    <p>SUCCESS</p>
    You have successfully saved your design.
  `,

  // tiles text
  tile_1_title: 'RIBTRAX® PRO',
  tile_1_desc:
    `<p>
      <b>Product Description</b></br>
      Ribtrax® PRO is a versatile and durable garage tile for any application, a revolutionary tile design that combines premium protection with unmatched functionality. The revolutionary channel system allows debris to drain underneath each tile making it easy-to-maintain.
    </p>
    <img src="${tileDetail1}" style="max-width: 100%"/>
    <p>
      <b>Get the most popular, versatile and technical advanced garage tile available</b></br>
      Swisstrax garage tiles mix precision of Swiss engineering and the quality manufacturing of North America. Their garage flooring tiles are the only interlocking floor tile made with a four-point injection mold to add extra thickness for more protection and added connectors for even more strength.
    </p>
    <p>
      <b>Complete Customization</b></br>
      Ribtrax® interlocking flooring tiles come in ten different colors so you can mix and match to create a variety of patterns and designs. They can also be mixed with any Swisstrax interlocking tile style to take your custom floor to the next level.
    </p>
    <p>
      Customize any space with 19 different colors and several different tile styles to elevate your garage flooring, event flooring, outdoor flooring, patio flooring, trade show flooring, hangar flooring, gym flooring and sport flooring. Our interlocking floor tiles remain the top choice for consumers because of their versatility and durability. Coupled with our unparalleled customer service, we ensure a great experience every step of the way.
    </p>
    <p>
      <b>Ribtrax specification overview</b></br>
      <b>Tile Size:</b> 15.75 in (40 cm) x 15.75 in (40 cm)</br>
      <b>Height/Thickness:</b> 0.75 in (1.9 cm)</br>
      <b>Perforation Width:</b> 0.13 in (0.32 cm)</br>
      <b>Weight:</b> 23.5 oz. (1.47 lb)</br>
      <b>Material:</b> 100% UV stabilized virgin polypropylene</br>
      <b>Warranty:</b> Limited Lifetime Warranty</br>
      <b>Chemical Resistance:</b> Resistance To Excellent - Oil, gas & other auto fluids; Good - Acids & solvents including Skydrol, and Alkalis</br>
      <b>Temperature Tolerances:</b> -22°F (-30°C) to 248°F (120°C)</br>
      <b>Compression Strength:</b> 3,120 psi</br>
      <b>Fire Rating HB:</b> Horizontal Burn</br>
      <b>Versatile:</b> Can be combined with any other Swisstrax 15.75" tile including Ribtrax PRO, Carpetrax PRO and Vinyltrax PRO</br>
    </p>`,

  tile_2_title: 'RIBTRAX SMOOTH® PRO',
  tile_2_desc:
    `<p>
      <b>Product Description</b></br>
      Ribtrax® Smooth PRO is a durable and smooth tile for any application, a revolutionary tile design that combines premium protection with unmatched functionality. The revolutionary channel system allows debris to drain underneath each tile making it easy-to-maintain.
    </p>
    <img src="${tileDetail2}" style="max-width: 100%"/>
    <p>
      <b>An innovative new addition to our most popular tile style and features the appeal of a smooth and even surface</b></br>
      Swisstrax garage tiles mix precision of Swiss engineering and the quality manufacturing of North America. Their garage flooring tiles are the only interlocking floor tile made with a four-point injection mold to add extra thickness for more protection and added connectors for even more strength.
    </p>
    <p>
      <b>Complete Customization</b></br>
      Ribtrax® Smooth interlocking flooring tiles come in ten different colors so you can mix and match to create a variety of patterns and designs. They can also be mixed with any Swisstrax interlocking tile style to take your custom floor to the next level.
      </p>
    <p>
      Customize any space with amazing colors and several different tile styles to elevate your garage flooring, event flooring, outdoor flooring, patio flooring, trade show flooring, hangar flooring, gym flooring and sport flooring. Our interlocking floor tiles remain the top choice for consumers because of their versatility and durability. Coupled with our unparalleled customer service, we ensure a great experience every step of the way.
    </p>
    <p>
      <b>Ribtrax smooth specification overview</b></br>
      <b>Tile Size:</b> 15.75 in (40 cm) x 15.75 in (40 cm)</br>
      <b>Height/Thickness:</b> 0.75 in (1.9 cm)</br>
      <b>Perforation Width:</b> 0.13 in (0.32 cm)</br>
      <b>Weight:</b> 23.5 oz. (1.47 lb)</br>
      <b>Material:</b> 100% UV stabilized virgin polypropylene</br>
      <b>Warranty:</b> Limited Lifetime Warranty</br>
      <b>Chemical Resistance:</b> Oil, gas & other auto fluids; Good - Acids & solvents including Skydrol, and Alkalis</br>
      <b>Temperature Tolerances:</b> -22°F (-30°C) to 248°F (120°C)</br>
      <b>Compression Strength:</b> 2,500 psi</br>
      <b>Fire Rating HB:</b> Horizontal Burn</br>
      <b>Versatile:</b> Can be combined with any other Swisstrax 15.75" tile including Ribtrax PRO, Carpetrax PRO and Vinyltrax PRO</br>
    </p>`,

  tile_3_title: 'VINYLTRAX® PRO',
  tile_3_desc:
    `<p>
      <b>Product Description</b></br>
      Vinyltrax® PRO is a solid industrial tile that allows you to make your floor look sleek, bold and durable. A revolutionary solid industrial tile that offers realistic hardwood grains and patterns for transforming your showroom, garage, trade show booth or event floor with premium commercial grade vinyl flooring solution.
    </p>
    <img src="${tileDetail3}" style="max-width: 100%"/>
    <p>
      <b>Install a custom diamond plate tile floor with no special tools, experience or adhesives</b></br>
      Swisstrax garage tiles mi precision of Swiss engineering and the quality manufacturing of North America. Their garage flooring tiles are the only interlocking floor tile made with a four-point injection mold to add extra thickness for more protection and added connectors for even more strength.
    </p>
    <p>
      <b>Complete Customization</b></br>
      Vinyltrax® PRO interlocking flooring tiles come in 12 different colors so you can mix and match to create a variety of patterns and designs. They can also be mixed with any Swisstrax interlocking tile style to take your custom floor to the next level.
    </p>
    <p>
      Customize any space with 1 different colors and several different tile styles to elevate your garage flooring, event flooring, outdoor flooring, patio flooring, trade show flooring, gym flooring and sport flooring. Our interlocking floor tiles remain the top choice for consumers because of their versatility and durability. Coupled with our unparalleled customer service, we ensure a great experience every step of the way.
    </p>
    <p>
      <b>Vinyltrax PRO specification overview</b></br>
      <b>Tile Size:</b> 15.75 in (40 cm) x 15.75 in (40 cm)</br>
      <b>Height/Thickness:</b> 0.75 in (1.9 cm)</br>
      <b>Weight:</b> 41.6 oz. (2.6 lb)</br>
      <b>Material:</b> Commercial grade vinyl inlay set in a 100% UV stabilized virgin polypropylene</br>
      <b>Warranty:</b> 15 Years</br>
      <b>Chemical Resistance:</b> Resistance To Excellent - Oil, gas & other auto fluids; Good - Acids & solvents including Skydrol, and Alkalis</br>
      <b>Temperature Tolerances:</b> -22°F (-30°C) to 248°F (120°C)</br>
      <b>Compression Strength:</b> 1,250 psi</br>
      <b>Fire Rating HB:</b> Horizontal Burn</br>
      <b>Versatile:</b> Can be combined with any other Swisstrax 15.75" tile including Ribtrax PRO, Carpetrax PRO and Vinyltrax PRO</br>
    </p>`,

  tile_4_title: 'DIAMONDTRAX® HOME',
  tile_4_desc:
    `<p>
      <b>Product Description</b></br>
      Diamondtrax® Home is a solid diamond plate tile, it’s eye-catching, easy-to-clean, and designed to use in home applications. Offered in ten different colors, mix-and-match Diamondtrax® HOME tiles to create your own unique look.
    </p>
    <img src="${tileDetail4}" style="max-width: 100%"/>
    <p>
      <b>Install a custom diamond plate tile floor with no special tools, experience or adhesives.</b></br>
      Swisstrax modular floor tiles mix precision of Swiss engineering and the quality manufacturing in the USA. Diamondtrax® HOME surface is made of miniatures embossed diamond offering a unique scuff resistant technology to maintain a great finish.
    </p>
    <p>
      <b>Complete Customization</b></br>
      Customize any space with up to ten different colors  to elevate your garage flooring, outdoor flooring, patio flooring. Our interlocking floor tiles remain the top choice for consumers because of their versatility and durability. Coupled with our unparalleled customer service, we ensure a great experience every step of the way.
    </p>
    <p>
      <b>Diamondtrax® Home specification overview</b></br>
      <b>Tile Size:</b> 12 in (30.48 cm) x 12 in (30.48 cm)</br>
      <b>Height/Thickness:</b> 0.50 in (1.27 cm)</br>
      <b>Weight:</b> 12.32 oz. (0.77 lbs)</br>
      <b>Material:</b> 100% UV stabilized polypropylene</br>
      <b>Warranty:</b> 20 Years</br>
      <b>Chemical Resistance:</b> Resistance to Excellent - Oil, gas & other auto fluids; Good - Acids & solvents including Skydrol, and Alkalis</br>
    </p>`,

  tile_5_title: 'DIAMONDTRAX® FLEX',
  tile_5_desc:
    `<p>
      <b>Product Description</b></br>
      Diamondtrax® Flex tile is constructed of a blend of polypropylene copolymer and thermoplastic elastomer making it a durable, while comfortable floor.
    </p>
    <img src="${tileDetail5}" style="max-width: 100%"/>
    <p>
      Do-It-Yourself</b>
      Mix & Match for custom design</b>
      No adhesives or underlay required</b>
      Ideal for gym/workout applications</b>
      Low maintenance & easy to clean</b>
      Can be placed over existing surface (concrete, wood floor, and more)</b>
      Made in the USA
    </p>
    <p>
      <b>Gym flooring tile and anti-fatigue mats for home or commercial</b></br>
      Swisstrax Diamontrax Flex mixes precision of Swiss engineering and the quality manufacturing of America. The gym flooring tiles are interlocking floor tile made with a single injection mold to add extra thickness for more protection.
    </p>
    <p>
      These tiles feature a simple connection system. Use Diamondtrax® Flex tiles for indoor installations for industrial and workout flooring applications as well as gym flooring.
    </p>
    <p>
      Home Gym Flooring, Aerobic Floors, Industrial Comfort Floors with complete cushion.
    </p>
    <p>
      <b>Complete Customization</b></br>
      Diamondtrax® Flex interlocking flooring tiles connect to Diamondtrax® Home tiles to take your custom floor to the next level.
    </p>
    <p>
      <b>Diamondtrax FLEX specification overview</b></br>
      <b>Tile Size:</b> 12 in (30.48 cm) x 12 in (30.48 cm)</br>
      <b>Height/Thickness:</b> 0.50 in (1.27 cm)</br>
      <b>Material:</b> Polypropylene Copolymer & Thermoplastic Elastomer</br>
      <b>Warranty:</b> 7 Years</br>
      <b>Versatile:</b> Can be combined with other Swisstrax 12" Home tiles</br>
    </p>`,

  tile_6_title: 'RIBTRAX® SMOOTH HOME',
  tile_6_desc:
    `<p>
      <b>Product Description</b></br>
      With Ribtrax® Smooth Home, you can transform your floor with a self-draining system that delivers in durability. This easy to install modular system assembles with no special tools or adhesives and the flat, perforated surface makes it ideal for a wide variety of indoor and outdoor applications. May be combined with other tiles in the Swisstrax “Home” collection as well to further expand your flooring possibilities.
    </p>
    <img src="${tileDetail6}" style="max-width: 100%"/>
    <p>
      <b>Ribtrax® Smooth Home specification overview</b></br>
      <b>Tile Size:</b> 12 in (30.48 cm) x 12 in (30.48 cm)</br>
      <b>Height/Thickness:</b> 0.50 in (1.27 cm)</br>
      <b>Weight:</b> 12.32 oz. (0.77 lbs)</br>
      <b>Material:</b> 100% UV stabilized polypropylene</br>
      <b>Warranty:</b> 20 Years</br>
      <b>Chemical Resistance:</b> Resistance to Excellent - Oil, gas & other auto fluids; Good - Acids & solvents including Skydrol, and Alkalis</br>
    </p>`,

  tile_7_title: 'VINYLTRAX® HOME',
  tile_7_desc:
    `<p>
      <b>Product Description</b></br>
      Vinyltrax® HOME offers realistic hardwood grains and patterns with legendary Swisstrax performance. Designed for various applications, it’s never been easier to transform your space with a premium, vinyl flooring solution.
    </p>
    <img src="${tileDetail7}" style="max-width: 100%"/>
    <p>
      <b>Install a custom diamond plate tile floor with no special tools, experience or adhesives</b></br>
      Swisstrax garage tiles mi precision of Swiss engineering and the quality manufacturing of North America. Their garage flooring tiles are the only interlocking floor tile made with a four-point injection mold to add extra thickness for more protection and added connectors for even more strength.
    </p>
    <p>
      <b>Complete Customization</b></br>
      Vinyltrax® HOME interlocking flooring tiles come in 3 different style options, so you can mix and match to create a variety of patterns and designs. They can be combined with any Swisstrax HOME interlocking tile style to take your custom floor to the next level.
    </p>
    <p>
      Customize any space with one color and several tile styles to elevate your garage flooring, event flooring, outdoor flooring, patio flooring, gym flooring and sport flooring. Our interlocking floor tiles remain the top choice for consumers because of their versatility and durability. Coupled with our unparalleled customer service, we ensure a great experience every step of the way.
    </p>
    <p>
      <b>Vinyltrax HOME specification overview</b></br>
      <b>Tile Size:</b> 12 in (30.48 cm) x 12 in (30.48 cm)</br>
      <b>Height/Thickness:</b> 0.5 in (1.27 cm)</br>
      <b>Weight:</b> 41.6 oz. (2.6 lbs)</br>
      <b>Material:</b> Commercial grade vinyl inlay set in a 100% UV stabilized virgin polypropylene</br>
      <b>Warranty:</b> 15 Years</br>
      <b>Chemical Resistance:</b> Resistance To Excellent - Oil, gas & other auto fluids; Good - Acids & solvents including Skydrol, and Alkalis</br>
      <b>Temperature Tolerances:</b> -22°F (-30°C) to 248°F (120°C)</br>
      <b>Compression Strength:</b> 1,250 psi</br>
      <b>Fire Rating HB:</b> Horizontal Burn</br>
      <b>Versatile:</b> Can be combined with any other Swisstrax 12" tile including Diamondtrax HOME, Ribtrax Smooth HOME and Diamondtrax FLEX tiles</br>
    </p>`,



    tile_8_title: 'Turftrax',
  tile_8_desc:
    `<p>
      <b>Product Description</b></br>
      The Turftrax floor tile incorporates premium artificial turf with a compact and comfortable 6mm thickness. The grass is installed on a ventilated tile made of polypropylene, so the air continues to flow under your floor.
    </p>
    <img src="${tileDetail8}" style="max-width: 100%"/>
    <p>
      <b>A turf floor installed easily and quickly</b></br>
      The tiles are installed by simple clipping between them, no bonding on the ground or joint to achieve.
      The tiles are delivered with the lawn siding already installed, saving time and ease of installation.
      Need to adjust your tiles in relation to your surface? Do not worry, the SWISSTRAX tiles are simply cut with a jigsaw … remove the turf and cut your tile according to your needs, and glue your turf surface with the self-adhesive surface. No need to be an ace in DIY, this imitation grass turf tile TURFTRAX installs by yourself and without any specific tool.    </p>
    <p>
      <b>A floor tile with a unique concept</b></br>
      The concept of this floor tile type TURFTRAX is innovative, you can use different flooring on your tiles SWISSTRAX:
       You change only the flooring that is embedded in your tile. Remove the turf cover and install an imitation parquet flooring for example …
       You always keep your original floor made of SWISSTRAX floor tile and you will decorate the surface with the coating of your choice.
        In case of domestic incident for example (spill of liquid, tearing, dirt …) you change only the part to change and not all your floor,
         just remove the lawn and replace with another plate …    </p>

    <p>
      <b>Turftrax specification overview</b></br>
      <b>Tile Size:</b> 15.75 in (40 cm) x 15.75 in(40 cm)</br>
      <b>Height/Thickness:</b> 0.39 in (1.8 cm)</br>
    </p>`,

  tile_dimensions_ft: '15.75" x 15.75" x 0.75"',
  tile_dimensions_m: '40cm x 40cm x 1.9cm',
  tile_dimensions_small_ft: '12" x 12" x 0.5"',
  tile_dimensions_small_m: '30.48cm x 30.48cm x 1.27cm'

};
