import en from './en';
import es from './es';

export default class Localization {
  constructor () {
    this.defaultLang = 'en';
    this.loadedLanguages = this.loadLocalizations();
    this.setLanguage(this.defaultLang);
  }

  getAvailableLanguages = () => {
    return Object.keys(this.loadedLanguages());
  }

  loadLocalizations = () => {
    return {
      en: { ...en },
      es: { ...es }
    };
  }

  setLanguage = (lang) => {
    this.strings = this.loadedLanguages[lang] || this.loadedLanguages[this.defaultLang];
  }
}
