import ObjectModelControls from '../ObjectModelControls';
import shapes from './../../../assets/images/icons/menu_icons/shapes.svg';
import cart from './../../../assets/images/icons/menu_icons/cart.svg';
import edge from './../../../assets/images/icons/menu_icons/edge.svg';
import furniture from './../../../assets/images/icons/menu_icons/furniture.svg';
import measurments from './../../../assets/images/icons/menu_icons/measurments.svg';
import colors from './../../../assets/images/icons/menu_icons/colors.svg';
import walls from './../../../assets/images/icons/menu_icons/walls.svg';

export default class NavControlPanel extends ObjectModelControls {
  constructor (onNavChange, navType = 'pad', selectedDeafult = 1) {
    super();
    this.navType = navType;
    this.panel = null;
    // this.parentElement = document.getElementById('navControl');
    this.parentElement = this.createNavWrapper();
    this.onNavChangeFunction = onNavChange;
    this.navElements = this.prepareElements();
  }

  prepareElements = (type) => {
    return [
      { id: 0, title: this.localization.strings.shapes, img_src: shapes },
      { id: 1, title: this.localization.strings.measurments, img_src: measurments },
      { id: 2, title: this.localization.strings.doorsWindows, img_src: walls },
      { id: 3, title: this.localization.strings.colors, img_src: colors },
      { id: 4, title: this.localization.strings.edge, img_src: edge },
      { id: 5, title: this.localization.strings.furniture, img_src: furniture },
      { id: 6, title: this.localization.strings.cart, img_src: cart }
    ];
  }

  createNavWrapper = () => {
    return this.createWrappingElement('div', 'navControl');
  }

  create = (panelName, x = 0, y = 0) => {
    !this.panel && (this.panel = this.createNewPanel(panelName, x, y, this.parentElement));
    this.createNavOptions();
  }

  createNavOptions = (value, optionName = 'navigation') => {
    this.optionName = optionName;
    this.panel.menuNavigation(this.optionName, value, this.onValueChange, this.navElements);
  }

  onValueChange = (value) => {
    this.onNavChangeFunction(value);
    return value;
  }

  getValue = (optionName = 'navigation') => {
    return this.panel.getValue(optionName);
  }

  setValue = (value) => {
    this.panel.setValue(this.optionName, value);
  }

  setNavElVisible = (visible) => {
    const el = { id: 2, title: this.localization.strings.doorsWindows, img_src: walls };
    visible ? this.panel.showMenuElements(this.optionName, el) : this.panel.hideMenuElements(this.optionName, el);
  }

  hide = () => {
    this.panel.hide();
  }

  show = () => {
    this.panel.show();
  }

  remove = () => {
    this.panel.destroy();
  }
}
