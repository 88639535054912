
import { Panels } from '../Panels';
import closeSvg from '../../../assets/images/icons/close.svg';
import { createElement } from '../../../assets/helpers/common';
import ColorControlPanel from './ColorControlPanel';
import { config } from '../../../config/config';

export class EdgeStagePanel extends Panels {
  constructor (setEdgeHandlesState, onEdgeColorChange, onAllEdgeColorChange, edgesPresent, edgeRemoveSwitcher) {
    super();
    this.setEdgeHandlesState = setEdgeHandlesState;
    this.onEdgeColorChange = onEdgeColorChange;
    this.onAllEdgeColorChange = onAllEdgeColorChange;
    this.edgesPresent = edgesPresent;
    this.edgeRemoveSwitcher = edgeRemoveSwitcher;
    this.popUpContainer = this.createPopup();
    this.container = createElement('div', 'edgeStagePanelContainer', 'edgeStagePanelContainer');
    document.body.appendChild(this.container);
    this.container.appendChild(this.popUpContainer);
    this.createRadioButton();
    this.createColorPickers();
  }

  createRadioButton = () => {
    const radioButtonContainer = createElement('div', 'edgeStagePanelRadioButtonContainer', 'edgeStagePanelRadioButtonWrapperContainer');
    this.radioButtonWrapper = createElement('div', 'edgeStagePanelRadioButtonWrapper', 'edgeStagePanelRadioButtonWrapper');
    for (let i = 0; i < 3; i++) {
      const radioButtonInput = createElement('input', `edgeStateRadioBtn${i}`, 'edgeStateInput');
      radioButtonInput.classList.add(`edgeStateInput${i}`);
      radioButtonInput.setAttribute('type', 'radio');
      radioButtonInput.setAttribute('name', 'edgeStateRadioBtn');
      radioButtonInput.setAttribute('value', i);
      radioButtonInput.onclick = this.radioBtnValueChange;
      const radioButtonLabel = createElement('label', `edgeStateRadioBtnLabel${i}`, 'edgeStateLabel');
      radioButtonLabel.setAttribute('for', `edgeStateRadioBtn${i}`);
      this.radioButtonWrapper.appendChild(radioButtonInput);
      this.radioButtonWrapper.appendChild(radioButtonLabel);
    }
    this.container.appendChild(radioButtonContainer);
    radioButtonContainer.appendChild(this.radioButtonWrapper);
    document.getElementById('edgeStateRadioBtnLabel0').innerHTML = 'Add';
    this.radioButtonWrapper.appendChild(createElement('div', 'edgeRadioBtnSelector', 'edgeRadioBtnSelector'));
    document.getElementById('edgeStateRadioBtnLabel1').innerHTML = 'Color';
    document.getElementById('edgeStateRadioBtnLabel2').innerHTML = 'Remove';
    document.getElementById('edgeStateRadioBtn0').setAttribute('checked', true);
  }

  createColorPickers = () => {
    this.edgeSingleColorPicker = createElement('div', 'edgeSingleColorPicker', 'edgeSingleColorPicker');
    this.edgeSingleColorPicker.innerHTML = '<p class="edgeColorPickerText">Single edge</p>';
    this.singleColorPicker = new ColorControlPanel(this.onEdgeColorChange, this.edgeSingleColorPicker, 'edgeSingle');
    this.singleColorPicker.create('edgeSingleColorPicker');
    this.singleColorPicker.changeColorPalette(config.edgePiecesColorPalette.pro);
    this.singleColorPicker.setValue(config.defaultEdgeChangeColor);
    this.container.appendChild(this.edgeSingleColorPicker);

    this.edgeAllColorPicker = createElement('div', 'edgeAllColorPicker', 'edgeAllColorPicker');
    this.edgeAllColorPicker.innerHTML = '<p class="edgeColorPickerText">All edges</p>';
    this.allColorPicker = new ColorControlPanel(this.onAllEdgeColorChange, this.edgeAllColorPicker, 'edgeAll');
    this.allColorPicker.create('edgeAllColorPicker');
    this.allColorPicker.changeColorPalette(config.edgePiecesColorPalette.pro);
    this.allColorPicker.setValue(config.defaultEdgeColor);
    this.container.appendChild(this.edgeAllColorPicker);
  }

  createPopup = () => {
    const popUpContainer = createElement('div', 'edgePopUpContainer', 'edgePopUpContainer');
    popUpContainer.onclick = () => { this.setPopupVisible(false); };
    const popUp = createElement('div', 'edgePopUp', 'edgePopUp');
    const popUpClose = createElement('div', 'edgePopUpClose', 'edgePopUpClose');
    const closeImg = createElement('img');
    closeImg.src = closeSvg;
    this.popUpText = createElement('div', 'edgepopUpText', 'edgepopUpText');
    const popUpOk = createElement('div', 'edgePopUpOk', 'edgePopUpOk');
    popUpOk.innerHTML = this.localization.strings.ok;

    popUpContainer.appendChild(popUp);
    popUp.appendChild(popUpClose);
    popUp.appendChild(this.popUpText);
    popUp.appendChild(popUpOk);
    popUpClose.appendChild(closeImg);
    return popUpContainer;
  }

  setEdgeColorPalette = (type) => {
    const colorPalette = type === 'pro' ? config.edgePiecesColorPalette.pro : config.edgePiecesColorPalette.home;
    this.singleColorPicker.changeColorPalette(colorPalette);
    this.allColorPicker.changeColorPalette(colorPalette);
  }

  setPopupVisible = (value) => {
    this.popUpContainer.style.display = value ? 'flex' : 'none';
  }

  resetRadioBtnValue = () => {
    document.getElementById('edgeStateRadioBtn0').checked = true;
    this.radioBtnValueChange({ currentTarget: { value: 0 } });
  }

  radioBtnValueChange = (value) => {
    if (+value.currentTarget.value !== 0 && !this.edgesPresent()) {
      value.preventDefault();
      this.popUpText.innerHTML = +value.currentTarget.value === 1
        ? this.localization.strings.edgePopUpColors
        : this.localization.strings.edgePopUpRemove;
      this.setPopupVisible(true);
    } else {
      this.setColorPickerVisible(+value.currentTarget.value === 1);
      this.setEdgeHandlesState(+value.currentTarget.value);
      this.setEdgeRemoverSwitcherVisible();
    }
  }

  setEdgeRemoverSwitcherVisible = () => {
    document.getElementById('edgeStateRadioBtn2').checked === true ? this.edgeRemoveSwitcher.show() : this.edgeRemoveSwitcher.hide();
  }

  setColorPickerVisible = (visible) => {
    this.edgeSingleColorPicker.style.display = visible ? 'flex' : 'none';
    this.edgeAllColorPicker.style.display = visible ? 'flex' : 'none';
  }

  setEdgeHandlesStateCallback = (callback) => {
    this.setEdgeHandlesState = callback;
  }

  setEdgesPresent = (callback) => {
    this.edgesPresent = callback;
  }

  disableEdgeManipulation = (value) => {
    value
      ? document.getElementById('edgeStateRadioBtn1').checked = true
      : document.getElementById('edgeStateRadioBtn0').checked = true;
    this.setColorPickerVisible(value);
    this.setEdgeHandlesState(value ? 1 : 0);
    this.radioButtonWrapper.style.display = value ? 'none' : 'flex';
  }
}
